import "./styles.scss";
import React, { useState } from "react";
import { formatDate, formatNumber } from "../../../utils/Utils";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ContractItem from "./ContractItem";

const Table = ({ data }) => {
  const [t, i18n] = useTranslation("global");
  const [openRows, setOpenRows] = useState([]);
  const navigate = useNavigate();
  const handleArrowClick = (index) => {
    setOpenRows((prevOpenRows) => {
      if (prevOpenRows.includes(index)) {
        return prevOpenRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevOpenRows, index];
      }
    });
  };

  const isRowOpen = (index) => {
    return openRows.includes(index);
  };

  const renderTableHead = (item, index) => {
    const isOpen = isRowOpen(index);
    return (
      <tr key={item._id}>
        <th colSpan="2" className="product-info">
          <span>
            <span className="title"># {t("withdrawals.contract")}</span>
            {item.contract_number}
          </span>
          <div>
            <span className="yellow">{item.status}</span> 
          </div>
          <div
            className={`arrow ${isOpen ? "expanded" : ""}`}
            onClick={() => handleArrowClick(index)}
          ></div>
        </th>
      </tr>
    );
  };

  const getTotal = () => {
    let total = 0
    data.map((item) => {
      total += item.investment[0]?.amount - item.fee_admin
    })
    return formatNumber(total)
  }

  const renderTableBody = (item, index) => {
    const isOpen = isRowOpen(index);
    return (
      <React.Fragment key={item._id}>
        {isOpen && (
          <tr>
            <td>
              <div className="details">
                <div className="detail-row">
                  <span className="title">{t("contracts.contract_type")}</span>
                  <span className="title">
                    {t("my_investment.date_purchase")}
                  </span>
                  <span className="title">{t("contracts.investment")}</span>
                  <span className="title">{t("home.utilities")}</span>
                  <span className="title"></span>
                </div>
                <div className="detail-row">
                  <span>{item.descriptionContract.type}</span>
                  <span>{formatDate(item.createdAt)}</span>
                  <span>
                    {formatNumber(item.investment[0]?.amount - item.fee_admin)}
                    {" "}
                    <span className="dolar">USD</span>
                  </span>
                  <span>
                    ${formatNumber(item.utility_amount)}
                    {" "}
                    <span className="dolar">USD</span>
                  </span>
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/Portafolio/Detail", { state: item });
                    }}
                  >
                    <a href="" style={{ color: "#7FC5ED" }}>
                      {t("contracts.see_more")}
                    </a>
                  </span>
                </div>
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="table-wrapper">
      <table className="responsive-table">
        {data.map((item, index) => {
          return (
            <>
              <thead>{renderTableHead(item, index)}</thead>
              <tbody>{renderTableBody(item, index)}</tbody>
            </>
          );
        })}
      </table>
      <table className="normal-table">
        <thead>
          <tr>
            <th># {t("withdrawals.contract")}</th>
            <th>{t("contracts.contract_type")}</th>
            <th>{t("my_investment.date_purchase")}</th>
            <th>{t("home.utilities")}</th>
            <th>{t("movements.table.status")}</th>
            <th>{t("contracts.document")}</th>
            <th>{t("contracts.investment")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <ContractItem
              key={item._id}
              item={item}
              formatDate={formatDate}
              formatNumber={formatNumber}
              t={t}
              navigate={navigate}
            />
          ))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <span className="bold">
                {t("contracts.total")}
              </span>
            </td>
            <td>
              <span className="bold">$
                {getTotal()}
              </span>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;

import { useContext, useEffect, useState } from "react";
import { GetSettlementsUser } from "../services";
import GeneralContext from "../../../context/GeneralContext";

export const useUtilities = () => {
  const { user } = useContext(GeneralContext);
  const [isLoading, setIsLoading] = useState(false);

  const getSettlements = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetSettlementsUser(user.token);
      setIsLoading(false);
      return data && data.settlement.length > 0 ? data.settlement : [];
    } catch (error) {
      setIsLoading(false);
      return { data: [] };
    }
  };

  return {
    getSettlements,
    isLoading,
  };
};

import { useContext } from "react";
import { GetMovements } from "../services";
import GeneralContext from "../../../context/GeneralContext";

export const useMovements = () => {
  const { user } = useContext(GeneralContext);
  const getMovements = async () => {
    const { data } = await GetMovements(user.token);
    const { movements } = data;
    return movements;
  };

  const downloadBillById = async (path_invoice) => {
    // const success = await downloadBill(user.token, billId);
    window.open(path_invoice);
    return { success: true };
  };
  return { getMovements, downloadBillById };
};

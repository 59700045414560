import axios from "axios";
import { URL_API } from "../../config/config";

const API_URL = `${URL_API}/api`;

export const requestWithdrawal = async (token, withdrawal) => {
  try {
    const body = {
      ...withdrawal,
      amount: withdrawal.amount
    }
    const { data } = await axios({
      url: `${API_URL}/withdrawal`,
      method: "POST",
      data: body,
      headers: { Authorization: `Bearer ${token}` },
    });
    return data.success;
  } catch (error) {
    console.error(error);
    return false;
  }

};

export const GetAccountsUser = (token) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.get(`${API_URL}/investor_wallet/user`);
  } catch (error) {
    console.log("🚀 ~ file: services.js:24 ~ GetAccountsUser ~ error:", error);
    return { success: false };
  }
};

export const GetWithdrawalsUser = async (token) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return await axios.get(`${API_URL}/withdrawal`);
  } catch (error) {
    console.log(
      "🚀 ~ file: services.js:34 ~ GetWithdrawalsUser ~ error:",
      error
    );
    return { success: false };
  }
};

export const DownloadBill = async (token, bill_id) => {
  try {
    const { data, headers } = await axios({
      url: `${API_URL}/withdrawal/bill/${bill_id}`,
      method: "GET",
      responseType: "arraybuffer",
      headers: { Authorization: `Bearer ${token}` },
    });
    downloadBlob(data, headers);
    return { success: true };
  } catch (error) {
    return { success: false };
  }
};

export const getChargeAccount = async (token, contractNumber, date) => {
  try {
    const { data, headers } = await axios({
      url: `${API_URL}/charge_account/${contractNumber}/${date}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    return { success: data.success, data };
  } catch (error) {
    console.log("🚀 ~ file: services.js:68 ~ getChargeAccount ~ error:", error)
    return { success: false };
  }
};

const downloadBlob = (data, headers) => {
  const contentDescription = headers.get("x-file-description");
  const description = JSON.parse(contentDescription);
  const nombreArchivo = description.fileName ?? "bill.pdf";

  // Crear una URL del Blob
  const url = window.URL.createObjectURL(
    new Blob([data], { type: "application/pdf" })
  );
  // Crear un enlace temporal y hacer clic en él para descargar el archivo
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", nombreArchivo);

  document.body.appendChild(link);
  link.click();

  // Limpiar la URL del objeto Blob
  window.URL.revokeObjectURL(url);
};

export const getBalanceUser = (token) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.get(`${API_URL}/wallet`);
  } catch (error) {
    console.log("🚀 ~ file: services.js:24 ~ GetAccountsUser ~ error:", error);
    return { success: false };
  }
}

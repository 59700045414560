import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { TipoPort } from "../../utils/constants";
import InputSelect from "../../pages/SignUp/InputSelect";
import { useUtilities } from "./hooks/useUtilities";
import Loader from "../loader/loader";
import Table from "./Table";
import { useTranslation } from "react-i18next";

import arrowBack from "../../assets/img/png/flecha-atras.png";
import searchIcon from "../../assets/img/svg/searchIcon.svg";
import filtroIcon from "../../assets/img/svg/filtroIcon.svg";

import "../../pages/Inversions/styles.scss";
import "./styles.scss";

const Utilidades = () => {
  const [t, i18n] = useTranslation("global");
  const { getSettlements, isLoading } = useUtilities();
  const [utilities, setUtilities] = useState([]);
  const [utilitiesOld, setUtilitiesOld] = useState([]);
  const [formulario, setFormulario] = useState({
    // State para informacion de usuario
    producto: "",
    transaccion: "",
    estado: "",
  });
  const navigate = useNavigate();
  const [panelF, setpanelF] = useState(false);

  useEffect(() => {
    processGetUtilities();
  }, []);

  const processGetUtilities = async () => {
    const data = await getSettlements();
    if (data.length > 0) {
      setUtilities(data.reverse());
      setUtilitiesOld(data);
    }
  };

  const keysToFilter = () => {
    if (!utilitiesOld || utilitiesOld?.length <= 0) return [];
    const list = utilitiesOld.map((e) => {
      return {
        option: e.portfolio_type?.toLowerCase(),
        value: e.portfolio_type,
      };
    });
    const normalizeList = [
      ...Array.from(new Map(list.map((item) => [item.value, item])).values()),
    ];
    normalizeList.unshift({
      option: "Select...",
      value: "Select...",
    });
    return normalizeList;
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="ContainerCont retiro">
          <div onClick={() => navigate("/Home")} className="back">
            <img src={arrowBack} alt="" /> <span>{t("general.back")}</span>
          </div>
          <div className="header">
            <h1>{t("utilities.title")}</h1>
          </div>
          <div className="contratos">
            <div className="search">
              <div className="inputSearch">
                <img src={searchIcon} alt="" />
                <input placeholder="Buscar" type="text" />
              </div>
              <div
                onClick={() => {
                  setpanelF(!panelF);
                  setUtilities(utilitiesOld);
                }}
                className="btnFiltro"
              >
                <img src={filtroIcon} alt="" />
                <span>{t("general.filter")}</span>
              </div>
            </div>
            {panelF ? (
              <div className="filtros">
                <div className="espacio-input">
                  <InputSelect
                    key={1}
                    form={formulario}
                    elements={utilitiesOld.length > 0 ? keysToFilter() : []}
                    name="producto"
                    onChange={(e) => {
                      if (e?.target?.value === "Select...") {
                        setUtilities(utilitiesOld);
                        return;
                      }
                      setFormulario({
                        ...formulario,
                        [e.target.name]: e.target.value,
                      });

                      const filter = utilitiesOld.filter(
                        (x) =>
                          x.portfolio_type.toLowerCase() ===
                          e.target.value.toLowerCase()
                      );
                      setUtilities(filter);
                    }}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {utilities && <Table data={utilities} />}
          </div>
        </div>
      )}
    </>
  );
};

export default Utilidades;

import { 
    MODIFICAR_USER
} from '../types'

const generalReducerApp = (state, action) => {
    switch(action.type) {
        case MODIFICAR_USER:
            return {
                ...state,
                user: action.payload
            }
        default:  
            return state;
    }
}
export default generalReducerApp;
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import {
  DownloadChargeAccount,
  GetContracts,
  GetUtilities,
  UploadBillingStatement,
  GetBillings,
  DownloadContract,
  DownloadCertificate,
  requestLiquidationBackOffice,
} from "../../Contratos/services.js";
import GeneralContext from "../../../context/GeneralContext";

export const useContracts = () => {
  const { user } = useContext(GeneralContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const getContracts = async () => {
    setIsLoading(true);
    const { data } = await GetContracts(user.token);
    const { contracts } = data;
    setIsLoading(false);
    return contracts;
  };

  const getUtilities = async (contractNumber) => {
    const { data } = await GetUtilities(user.token, contractNumber);
    const { total_profits } = data;
    return total_profits;
  };

  const downloadChargeAccount = async (contractNumber) => {
    try {
      const { status, idDocumentSignNow } = await DownloadChargeAccount(
        user.token,
        contractNumber
      );
      return { success: status == "success", idDocumentSignNow };
    } catch (error) {
      return {
        success: false,
      };
    }
  };

  const uploadDocumentSigned = async (idDocumentSignNow) => {
    const response = await UploadBillingStatement(
      user.token,
      idDocumentSignNow
    );
    return response;
  };

  const getBillings = async (contractId) => {
    const { data } = await GetBillings(user.token, contractId);
    const { billing } = data;
    return billing;
  };

  const downloadContract = async (documentId) => {
    const { success } = await DownloadContract(user.token, documentId);
    return success;
  };

  const sendAndDownloadCertificate = async (data) => {
    const { success } = await DownloadCertificate(user.token, data);
    return success;
  };

  const sendLiquidationBackOffice = async (contractNumber) => {
    try {
      const { data } = await requestLiquidationBackOffice(
        user.token,
        contractNumber
      );
      return { success: data.success };
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return {
    getContracts,
    getUtilities,
    downloadChargeAccount,
    uploadDocumentSigned,
    getBillings,
    downloadContract,
    sendAndDownloadCertificate,
    sendLiquidationBackOffice,
    isLoading,
  };
};

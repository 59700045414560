const InputSelect = ({ name, form, elements, onChange, disabled = false }) => {
  return (
    <select
      name={name}
      value={form[name]}
      onChange={onChange}
      style={{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
      className="input selectInput"
      disabled={disabled}
    >
      {elements.length > 0 &&
        elements.map((e) => (
          <option key={e.option} value={e.option}>
            {e.value}
          </option>
        ))}
    </select>
  );
};
export default InputSelect;

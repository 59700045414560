import React, { useEffect, useState } from "react";
import { convertDecimal, TipoAccount } from "../../utils/constants";
import InputSelect from "../SignUp/InputSelect";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/Utils";
//TODO INTERNACIONALIZACIÓN
const FormBank = ({ user, cambiarPaso, form, setForm }) => {
  const [t, _] = useTranslation("global");
  const [formulario, setFormulario] = useState({
    // State para informacion de usuario
    bank_name: "",
    routing_number: "",
    account_type: "",
    account_number: "",
    source_of_funds_and_wealth: "",
    estimated_annual_gross_income: "",
    estimated_net_worth: "",
    reason_for_use_cab: "",
    anticipated_deposit_amount: "",
  });
  //const [boton, setBoton] = useState("boton-continuar-disabled") // State para claseName de boton "Continuar"

  //const submit = () => {
  //    const objetoUnido = Object.assign({}, form, formulario);
  //    setForm(objetoUnido);
  //    cambiarPaso();
  //}
  const handleSelectChange = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value,
    });
  };

  //TODO Establecer comprobacion general
  //const comprobarFormulario = React.useCallback(() => {
  //    return (formulario.bank_name !== '' &&
  //    formulario.routing_number !== '' &&
  //    formulario.account_type !== '' &&
  //    formulario.account_number !== '' &&
  //    formulario.source_of_funds_and_wealth !== '' &&
  //    formulario.estimated_annual_gross_income !== ''
  //    )
  //formulario]);

  useEffect(() => {
    setFormulario({
      reason_for_use_cab: user?.reason_for_user_CAB,
      anticipated_deposit_amount: user?.anticipated_deposit_amount,
      estimated_annual_gross_income: user?.estimated_annual_gross_income,
      estimated_net_worth: user?.estimated_net_worth,
      source_of_funds_and_wealth: user?.source_of_funds_and_wealth,
      account_number: user?.account_number,
      bank_name: user?.bank_name,
      account_type: user?.account_type,
      routing_number: user?.routing_number,
    });
    //if(comprobarFormulario()){
    //    setBoton("boton-continuar-enabled")
    //}else {
    //    setBoton("boton-continuar-disabled")
    //}
  }, []);
  return (
    <div className="formulario-registro">
      <div className="container-campos">
        <div className="part-space">
          <div className="espacio-input">
            <p className="titulo-2">{t("profile.financial_info.bank_name")}</p>
            <input
              type="text"
              className="input only-read"
              placeholder={t("signup.write_here")}
              value={formulario.bank_name}
              readOnly
            ></input>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">
              {t("profile.financial_info.account_type")}
            </p>
            <input
              type="text"
              className="input only-read"
              value={formulario.account_type}
              readOnly
            ></input>
          </div>
        </div>
        <div className="part-space">
          <div className="espacio-input">
            <p className="titulo-2">
              {t("profile.financial_info.account_number")}
            </p>
            <input
              type="text"
              className="input only-read"
              placeholder={t("signup.write_here")}
              value={formulario.account_number}
              readOnly
            ></input>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">
              {t("profile.financial_info.routing_number")}
            </p>
            <input
              type="text"
              className="input only-read"
              placeholder={t("signup.write_here")}
              value={formulario.routing_number}
              readOnly
            ></input>
          </div>
        </div>
        <div className="part-space">
          <div className="espacio-input">
            <p className="titulo-2">{t("signup.why")}</p>
            <input
              type="text"
              className="input only-read"
              placeholder={t("signup.write_here")}
              value={formulario.reason_for_use_cab}
              readOnly
            ></input>
          </div>
          <div
            className="espacio-input"
          >
            <p className="titulo-2">{t("signup.how_much")}</p>
            <span className="dolar">USD</span>
            <input
              type="text"
              value={formatNumber(formulario.anticipated_deposit_amount)}
              className="input input-dolar only-read"
              placeholder="$ 0000000"
              readOnly
            ></input>
          </div>
        </div>
        <div className="part-space">
          <div className="espacio-input">
            <p className="titulo-2">{t("signup.what")}</p>
            <span className="dolar">USD</span>
            <input
              type="text"
              value={formatNumber(formulario.estimated_net_worth)}
              className="input input-dolar only-read"
              placeholder="$ 0000000"
              readOnly
            ></input>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">{t("signup.source")}</p>
            <input
              type="text"
              className="input only-read"
              placeholder={t("signup.write_here")}
              value={formulario.source_of_funds_and_wealth}
              readOnly
            ></input>
          </div>
        </div>
        <div className="part-space">
          <div className="espacio-input">
            <p className="titulo-2">{t("signup.income")}</p>
            <span className="dolar">USD</span>
            <input
              style={{ maxWidth: "355px" }}
              type="text"
              value={formatNumber(formulario.estimated_annual_gross_income)}
              className="input input-dolar only-read"
              placeholder="$ 0000000"
              readOnly
            ></input>
          </div>
        </div>
        {/* <button className = {boton} onClick = {() => submit()}>Continuar</button> */}
      </div>
    </div>
  );
};

export default FormBank;

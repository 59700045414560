const API_URL = "https://cabcapital-backend-develop.imagineapps.co/api";
//const API_URL = "http://localhost:8081/api";

const CURRENCY_LOCATION = "en-US";
const CURRENCY = "USD";

const TipoDoc = [
  { option: "CC", value: "CC" },
  { option: "PASSPORT", value: "PASSPORT" },
  { option: "T.I", value: "T.I" },
  { option: "NIT", value: "NIT" },
];
const TipoPort = [
  { option: "Renta fija", value: "Renta fija" },
  { option: "Renta fija premium", value: "Renta fija premium" },
  { option: "Renta variable", value: "Renta variable" },
  { option: "Renta Cripto", value: "Renta Cripto" },
  { option: "OTC", value: "OTC" },
];
const Estado = [
  { option: "Active", value: "Activo" },
  { option: "In review", value: "En revisión" },
  { option: "Liquidated", value: "Liquidado" },
];

const Countries = [
  { option: "0", value: "..." },
  { option: "Afganistán", value: "Afganistán" },
  { option: "Albania", value: "Albania" },
  { option: "Alemania", value: "Alemania" },
  { option: "Andorra", value: "Andorra" },
  { option: "Angola", value: "Angola" },
  { option: "Antigua y Barbuda", value: "Antigua y Barbuda" },
  { option: "Arabia Saudita", value: "Arabia Saudita" },
  { option: "Argelia", value: "Argelia" },
  { option: "Argentina", value: "Argentina" },
  { option: "Armenia", value: "Armenia" },
  { option: "Australia", value: "Australia" },
  { option: "Austria", value: "Austria" },
  { option: "Azerbaiyán", value: "Azerbaiyán" },
  { option: "Bahamas", value: "Bahamas" },
  { option: "Bangladés", value: "Bangladés" },
  { option: "Barbados", value: "Barbados" },
  { option: "Baréin", value: "Baréin" },
  { option: "Bélgica", value: "Bélgica" },
  { option: "Belice", value: "Belice" },
  { option: "Benín", value: "Benín" },
  { option: "Bielorrusia", value: "Bielorrusia" },
  { option: "Birmania", value: "Birmania" },
  { option: "Bolivia", value: "Bolivia" },
  { option: "Bosnia y Herzegovina", value: "Bosnia y Herzegovina" },
  { option: "Botsuana", value: "Botsuana" },
  { option: "Brasil", value: "Brasil" },
  { option: "Brunéi", value: "Brunéi" },
  { option: "Bulgaria", value: "Bulgaria" },
  { option: "Burkina Faso", value: "Burkina Faso" },
  { option: "Burundi", value: "Burundi" },
  { option: "Bután", value: "Bután" },
  { option: "Cabo Verde", value: "Cabo Verde" },
  { option: "Camboya", value: "Camboya" },
  { option: "Camerún", value: "Camerún" },
  { option: "Canadá", value: "Canadá" },
  { option: "Catar", value: "Catar" },
  { option: "Chad", value: "Chad" },
  { option: "Chile", value: "Chile" },
  { option: "China", value: "China" },
  { option: "Chipre", value: "Chipre" },
  { option: "Ciudad del Vaticano", value: "Ciudad del Vaticano" },
  { option: "Colombia", value: "Colombia" },
  { option: "Comoras", value: "Comoras" },
  { option: "Corea del Norte", value: "Corea del Norte" },
  { option: "Corea del Sur", value: "Corea del Sur" },
  { option: "Costa de Marfil", value: "Costa de Marfil" },
  { option: "Costa Rica", value: "Costa Rica" },
  { option: "Croacia", value: "Croacia" },
  { option: "Cuba", value: "Cuba" },
  { option: "Dinamarca", value: "Dinamarca" },
  { option: "Dominica", value: "Dominica" },
  { option: "Ecuador", value: "Ecuador" },
  { option: "Egipto", value: "Egipto" },
  { option: "El Salvador", value: "El Salvador" },
  { option: "Emiratos Árabes Unidos", value: "Emiratos Árabes Unidos" },
  { option: "Eritrea", value: "Eritrea" },
  { option: "Eslovaquia", value: "Eslovaquia" },
  { option: "Eslovenia", value: "Eslovenia" },
  { option: "España", value: "España" },
  { option: "Estados Unidos", value: "Estados Unidos" },
  { option: "Estonia", value: "Estonia" },
  { option: "Etiopía", value: "Etiopía" },
  { option: "Filipinas", value: "Filipinas" },
  { option: "Finlandia", value: "Finlandia" },
  { option: "Fiyi", value: "Fiyi" },
  { option: "Francia", value: "Francia" },
  { option: "Gabón", value: "Gabón" },
  { option: "Gambia", value: "Gambia" },
  { option: "Georgia", value: "Georgia" },
  { option: "Ghana", value: "Ghana" },
  { option: "Granada", value: "Granada" },
  { option: "Grecia", value: "Grecia" },
  { option: "Guatemala", value: "Guatemala" },
  { option: "Guyana", value: "Guyana" },
  { option: "Guinea", value: "Guinea" },
  { option: "Guinea ecuatorial", value: "Guinea ecuatorial" },
  { option: "Guinea-Bisáu", value: "Guinea-Bisáu" },
  { option: "Haití", value: "Haití" },
  { option: "Honduras", value: "Honduras" },
  { option: "Hungría", value: "Hungría" },
  { option: "India", value: "India" },
  { option: "Indonesia", value: "Indonesia" },
  { option: "Irak", value: "Irak" },
  { option: "Irán", value: "Irán" },
  { option: "Irlanda", value: "Irlanda" },
  { option: "Islandia", value: "Islandia" },
  { option: "Islas", value: "Islas" },
  { option: "Marshall", value: "Marshall" },
  { option: "Islas", value: "Islas" },
  { option: "Salomón", value: "Salomón" },
  { option: "Israel", value: "Israel" },
  { option: "Italia", value: "Italia" },
  { option: "Jamaica", value: "Jamaica" },
  { option: "Japón", value: "Japón" },
  { option: "Jordania", value: "Jordania" },
  { option: "Kazajistán", value: "Kazajistán" },
  { option: "Kenia", value: "Kenia" },
  { option: "Kirguistán", value: "Kirguistán" },
  { option: "Kiribati", value: "Kiribati" },
  { option: "Kuwait", value: "Kuwait" },
  { option: "Laos", value: "Laos" },
  { option: "Lesoto", value: "Lesoto" },
  { option: "Letonia", value: "Letonia" },
  { option: "Líbano", value: "Líbano" },
  { option: "Liberia", value: "Liberia" },
  { option: "Libia", value: "Libia" },
  { option: "Liechtenstein", value: "Liechtenstein" },
  { option: "Lituania", value: "Lituania" },
  { option: "Luxemburgo", value: "Luxemburgo" },
  { option: "Macedonia del Norte", value: "Macedonia del Norte" },
  { option: "Madagascar", value: "Madagascar" },
  { option: "Malasia Malaui", value: "Malasia Malaui" },
  { option: "Maldivas", value: "Maldivas" },
  { option: "Malí", value: "Malí" },
  { option: "Malta", value: "Malta" },
  { option: "Marruecos", value: "Marruecos" },
  { option: "Mauricio", value: "Mauricio" },
  { option: "Mauritania", value: "Mauritania" },
  { option: "México", value: "México" },
  { option: "Micronesia", value: "Micronesia" },
  { option: "Moldavia", value: "Moldavia" },
  { option: "Mónaco", value: "Mónaco" },
  { option: "Mongolia", value: "Mongolia" },
  { option: "Montenegro", value: "Montenegro" },
  { option: "Mozambique", value: "Mozambique" },
  { option: "Namibia", value: "Namibia" },
  { option: "Nauru", value: "Nauru" },
  { option: "Nepal", value: "Nepal" },
  { option: "Nicaragua", value: "Nicaragua" },
  { option: "Níger", value: "Níger" },
  { option: "Nigeria", value: "Nigeria" },
  { option: "Noruega", value: "Noruega" },
  { option: "Nueva", value: "Nueva" },
  { option: "Zelanda", value: "Zelanda" },
  { option: "Omán", value: "Omán" },
  { option: "Países Bajos", value: "Países Bajos" },
  { option: "Pakistán", value: "Pakistán" },
  { option: "Palaos", value: "Palaos" },
  { option: "Panamá", value: "Panamá" },
  { option: "Papúa Nueva", value: "Papúa Nueva" },
  { option: "Guinea", value: "Guinea" },
  { option: "Paraguay", value: "Paraguay" },
  {
    option: "Perú",
    value: "Perú",
  },
  { option: "Polonia", value: "Polonia" },
  { option: "Portugal", value: "Portugal" },
  { option: "Reino Unido", value: "Reino Unido" },
  { option: "República Centroafricana", value: "República Centroafricana" },
  { option: "República Checa", value: "República Checa" },
  { option: "República del Congo", value: "República del Congo" },
  {
    option: "República Democrática del Congo",
    value: "República Democrática del Congo",
  },
  { option: "República Dominicana", value: "República Dominicana" },
  { option: "Ruanda", value: "Ruanda" },
  { option: "Rumanía", value: "Rumanía" },
  { option: "Rusia", value: "Rusia" },
  { option: "Samoa", value: "Samoa" },
  { option: "San Cristóbal y Nieves", value: "San Cristóbal y Nieves" },
  { option: "San Marino", value: "San Marino" },
  {
    option: "San Vicente y las Granadinas",
    value: "San Vicente y las Granadinas",
  },
  { option: "Santa Lucía", value: "Santa Lucía" },
  { option: "Santo Tomé y Príncipe", value: "Santo Tomé y Príncipe" },
  { option: "Senegal", value: "Senegal" },
  { option: "Serbia", value: "Serbia" },
  { option: "Seychelles", value: "Seychelles" },
  { option: "Sierra Leona", value: "Sierra Leona" },
  { option: "Singapur", value: "Singapur" },
  { option: "Siria", value: "Siria" },
  { option: "Somalia", value: "Somalia" },
  { option: "Sri Lanka", value: "Sri Lanka" },
  { option: "Suazilandia", value: "Suazilandia" },
  { option: "Sudáfrica", value: "Sudáfrica" },
  { option: "Sudán", value: "Sudán" },
  { option: "Sudán del Sur", value: "Sudán del Sur" },
  { option: "Suecia", value: "Suecia" },
  { option: "Suiza", value: "Suiza" },
  { option: "Surinam", value: "Surinam" },
  { option: "Tailandia", value: "Tailandia" },
  { option: "Tanzania", value: "Tanzania" },
  { option: "Tayikistán", value: "Tayikistán" },
  { option: "Timor Oriental", value: "Timor Oriental" },
  { option: "Togo", value: "Togo" },
  { option: "Tonga", value: "Tonga" },
  { option: "Trinidad y Tobago", value: "Trinidad y Tobago" },
  { option: "Túnez", value: "Túnez" },
  { option: "Turkmenistán", value: "Turkmenistán" },
  { option: "Turquía", value: "Turquía" },
  { option: "Tuvalu", value: "Tuvalu" },
  { option: "Ucrania", value: "Ucrania" },
  { option: "Uganda", value: "Uganda" },
  { option: "Uruguay", value: "Uruguay" },
  { option: "Uzbekistán", value: "Uzbekistán" },
  { option: "Vanuatu", value: "Vanuatu" },
  { option: "Venezuela", value: "Venezuela" },
  { option: "Vietnam", value: "Vietnam" },
  { option: "Yemen", value: "Yemen" },
  { option: "Yibuti", value: "Yibuti" },
  { option: "Zambia", value: "Zambia" },
  { option: "Zimbabue", value: "Zimbabue" },
];

const CountriesTraslation = [
  { spanish: "Afganistán", english: "Afghanistan"},
  { spanish: "Albania", english: "Albania"},
  { spanish: "Alemania", english: "Germany"},
  { spanish: "Andorra", english: "Andorra"},
  { spanish: "Angola", english: "Angola"},
  { spanish: "Antigua y Barbuda", english: "Antigua and Barbuda"},
  { spanish: "Arabia Saudita", english: "Saudi Arabia"},
  { spanish: "Argelia", english: "Algeria"},
  { spanish: "Argentina", english: "Argentina"},
  { spanish: "Armenia", english: "Armenia"},
  { spanish: "Australia", english: "Australia"},
  { spanish: "Austria", english: "Austria"},
  { spanish: "Azerbaiyán", english: "Azerbaijan"},
  { spanish: "Bahamas", english: "Bahamas"},
  { spanish: "Bangladés", english: "Bangladesh"},
  { spanish: "Barbados", english: "Barbados"},
  { spanish: "Baréin", english: "Bahrain"},
  { spanish: "Bélgica", english: "Belgium"},
  { spanish: "Belice", english: "Belize"},
  { spanish: "Benín", english: "Benin"},
  { spanish: "Bielorrusia", english: "Belarus"},
  { spanish: "Birmania", english: "Birmania"},
  { spanish: "Bolivia", english: "Bolivia"},
  { spanish: "Bosnia y Herzegovina", english: "Bosnia and Herzegovina"},
  { spanish: "Botsuana", english: "Botswana"},
  { spanish: "Brasil", english: "Brazil"},
  { spanish: "Brunéi", english: "Brunei"},
  { spanish: "Bulgaria", english: "Bulgaria"},
  { spanish: "Burkina Faso", english: "Burkina Faso"},
  { spanish: "Burundi", english: "Burundi"},
  { spanish: "Bután", english: "Bhutan"},
  { spanish: "Cabo Verde", english: "Cape Verde"},
  { spanish: "Camboya", english: "Cambodia"},
  { spanish: "Camerún", english: "Cameroon"},
  { spanish: "Canadá", english: "Canada"},
  { spanish: "Catar", english: "Qatar"},
  { spanish: "Chad", english: "Chad"},
  { spanish: "Chile", english: "Chile"},
  { spanish: "China", english: "China"},
  { spanish: "Chipre", english: "Cyprus"},
  { spanish: "Ciudad del Vaticano", english: "Vatican City"},
  { spanish: "Colombia", english: "Colombia"},
  { spanish: "Comoras", english: "Comoros"},
  { spanish: "Corea del Norte", english: "North Korea"},
  { spanish: "Corea del Sur", english: "South Korea"},
  { spanish: "Costa de Marfil ", english: "Ivory Coast"},
  { spanish: "Costa Rica", english: "Costa Rica"},
  { spanish: "Croacia", english: "Croatia"},
  { spanish: "Cuba", english: "Cuba"},
  { spanish: "Dinamarca", english: "Denmark"},
  { spanish: "Dominica", english: "Dominica"},
  { spanish: "Ecuador", english: "Ecuador"},
  { spanish: "Egipto", english: "Egypt"},
  { spanish: "El Salvador", english: "El Salvador"},
  { spanish: "Emiratos Árabes Unidos", english: "United Arab Emirates"},
  { spanish: "Eritrea", english: "Eritrea"},
  { spanish: "Eslovaquia", english: "Slovakia"},
  { spanish: "Eslovenia", english: "Slovenia"},
  { spanish: "España", english: "Spain"},
  { spanish: "Estados Unidos", english: "United States"},
  { spanish: "Estonia", english: "Estonia"},
  { spanish: "Etiopía", english: "Ethiopia"},
  { spanish: "Filipinas", english: "Philippines"},
  { spanish: "Finlandia", english: "Finland"},
  { spanish: "Fiyi", english: "Fiji"},
  { spanish: "Francia", english: "France"},
  { spanish: "Gabón", english: "Gabon"},
  { spanish: "Gambia", english: "Gambia"},
  { spanish: "Georgia", english: "Georgia"},
  { spanish: "Ghana", english: "Ghana"},
  { spanish: "Granada", english: "Grenada"},
  { spanish: "Grecia", english: "Greece"},
  { spanish: "Guatemala", english: "Guatemala"},
  { spanish: "Guyana", english: "Guyana"},
  { spanish: "Guinea", english: "Guinea"},
  { spanish: "Guinea Ecuatorial", english: "Equatorial Guinea"},
  { spanish: "Guinea-Bisáu", english: "Guinea-Bissau"},
  { spanish: "Haití", english: "Haiti"},
  { spanish: "Honduras", english: "Honduras"},
  { spanish: "Hungría", english: "Hungary"},
  { spanish: "India", english: "India"},
  { spanish: "Indonesia", english: "Indonesia"},
  { spanish: "Irak", english: "Iraq"},
  { spanish: "Irán", english: "Iran"},
  { spanish: "Irlanda", english: "Ireland"},
  { spanish: "Islandia", english: "Iceland"},
  { spanish: "Islas Marshall", english: "Marshall Islands"},
  { spanish: "Islas Salomón", english: "Solomon Islands"},
  { spanish: "Israel", english: "Israel"},
  { spanish: "Italia", english: "Italy"},
  { spanish: "Jamaica", english: "Jamaica"},
  { spanish: "Japón", english: "Japan"},
  { spanish: "Jordania", english: "Jordan"},
  { spanish: "Kazajistán", english: "Kazakhstan"},
  { spanish: "Kenia", english: "Kenya"},
  { spanish: "Kirguistán", english: "Kyrgyzstan"},
  { spanish: "Kiribati", english: "Kiribati"},
  { spanish: "Kuwait", english: "Kuwait"},
  { spanish: "Laos", english: "Laos"},
  { spanish: "Lesoto", english: "Lesotho"},
  { spanish: "Letonia", english: "Latvia"},
  { spanish: "Líbano", english: "Lebanon"},
  { spanish: "Liberia", english: "Liberia"},
  { spanish: "Libia", english: "Libya"},
  { spanish: "Liechtenstein", english: "Liechtenstein"},
  { spanish: "Lituania", english: "Lithuania"},
  { spanish: "Luxemburgo", english: "Luxembourg"},
  { spanish: "Macedonia del Norte", english: "North Macedonia"},
  { spanish: "Madagascar", english: "Madagascar"},
  { spanish: "Malasia", english: "Malaysia"},
  { spanish: "Malaui", english: "Malawi"},
  { spanish: "Maldivas", english: "Maldives"},
  { spanish: "Malí", english: "Mali"},
  { spanish: "Malta", english: "Malta"},
  { spanish: "Marruecos", english: "Morocco"},
  { spanish: "Mauricio", english: "Mauritius"},
  { spanish: "Mauritania", english: "Mauritania"},
  { spanish: "México", english: "Mexico"},
  { spanish: "Micronesia", english: "Micronesia"},
  { spanish: "Moldavia", english: "Moldova"},
  { spanish: "Mónaco", english: "Monaco"},
  { spanish: "Mongolia", english: "Mongolia"},
  { spanish: "Montenegro", english: "Montenegro"},
  { spanish: "Mozambique", english: "Mozambique"},
  { spanish: "Namibia", english: "Namibia"},
  { spanish: "Nauru", english: "Nauru"},
  { spanish: "Nepal", english: "Nepal"},
  { spanish: "Nicaragua", english: "Nicaragua"},
  { spanish: "Níger", english: "Niger"},
  { spanish: "Nigeria", english: "Nigeria"},
  { spanish: "Noruega", english: "Norway"},
  { spanish: "Nueva Zelanda", english: "New Zealand"},
  { spanish: "Omán", english: "Oman"},
  { spanish: "Países Bajos", english: "Netherlands"},
  { spanish: "Pakistán", english: "Pakistan"},
  { spanish: "Palaos", english: "Palau"},
  { spanish: "Panamá", english: "Panama"},
  { spanish: "Papúa Guinea", english: "Papua New Guinea"},
  { spanish: "Paraguay", english: "Paraguay"},
  { spanish: "Perú", english: "Peru"},
  { spanish: "Polonia", english: "Poland"},
  { spanish: "Portugal", english: "Portugal"},
  { spanish: "Reino Unido", english: "United Kingdom"},
  { spanish: "República Centroafricana", english: "Central African Republic"},
  { spanish: "República Checa", english: "Czech Republic"},
  { spanish: "República del Congo", english: "Republic of the Congo"},
  { spanish: "República Democrática del Congo", english: "Democratic Republic of the Congo"},
  { spanish: "República Dominicana", english: "Dominican Republic"},
  { spanish: "Ruanda", english: "Rwanda"},
  { spanish: "Rumanía", english: "Romania"},
  { spanish: "Rusia", english: "Russia"},
  { spanish: "Samoa", english: "Samoa"},
  { spanish: "San Cristóbal y Nieves", english: "Saint Kitts and Nevis"},
  { spanish: "San Marino", english: "San Marino"},
  { spanish: "San Vicente y las Granadinas", english: "Saint Vincent and the Grenadines"},
  { spanish: "Santa Lucía", english: "Saint Lucia"},
  { spanish: "Santo Tomé y Príncipe", english: "Sao Tome and Principe"},
  { spanish: "Senegal", english: "Senegal"},
  { spanish: "Serbia", english: "Serbia"},
  { spanish: "Seychelles", english: "Seychelles"},
  { spanish: "Sierra Leona", english: "Sierra Leone"},
  { spanish: "Singapur", english: "Singapore"},
  { spanish: "Siria", english: "Syria"},
  { spanish: "Somalia", english: "Somalia"},
  { spanish: "Sri Lanka", english: "Sri Lanka"},
  { spanish: "Suazilandia", english: "Eswatini"},
  { spanish: "Sudáfrica", english: "South Africa"},
  { spanish: "Sudán", english: "Sudan"},
  { spanish: "Sudán del Sur", english: "South Sudan"},
  { spanish: "Suecia", english: "Sweden"},
  { spanish: "Suiza", english: "Switzerland"},
  { spanish: "Surinam", english: "Suriname"},
  { spanish: "Tailandia", english: "Thailand"},
  { spanish: "Tanzania", english: "Tanzania"},
  { spanish: "Tayikistán", english: "Tajikistan"},
  { spanish: "Timor Oriental", english: "East Timor"},
  { spanish: "Togo", english: "Togo"},
  { spanish: "Tonga", english: "Tonga"},
  { spanish: "Trinidad y Tobago", english: "Trinidad and Tobago"},
  { spanish: "Túnez", english: "Tunisia"},
  { spanish: "Turkmenistán", english: "Turkmenistan"},
  { spanish: "Turquía", english: "Turkey"},
  { spanish: "Tuvalu", english: "Tuvalu"},
  { spanish: "Ucrania", english: "Ukraine"},
  { spanish: "Uganda", english: "Uganda"},
  { spanish: "Uruguay", english: "Uruguay"},
  { spanish: "Uzbekistán", english: "Uzbekistan"},
  { spanish: "Vanuatu", english: "Vanuatu"},
  { spanish: "Venezuela", english: "Venezuela"},
  { spanish: "Vietnam", english: "Vietnam"},
  { spanish: "Yemen", english: "Yemen"},
  { spanish: "Yibuti", english: "Djibouti"},
  { spanish: "Zambia", english: "Zambia"},
  { spanish: "Zimbabue", english: "Zimbabwe"},
]

const TipoPersona = [
  { option: "PERSON", value: "Natural" },
  { option: "COMPANY", value: "Jurídica" },
  { option: "EXTERNAL", value: "Externa" },
];

const Nationalitys = [
  { option: "Afgano", value: "Afgano" },
  { option: "Alemnán", value: "Alemnán" },
  { option: "Andorrano", value: "Andorrano" },
  { option: "Angoleño", value: "Angoleño" },
  { option: "Antiguano, Barbudano", value: "Antiguano, Barbudano" },
  { option: "Saudita", value: "Saudita" },
  { option: "Argelino", value: "Argelino" },
  { option: "Argentino", value: "Argentino" },
  { option: "Armenio", value: "Armenio" },
  { option: "Australiano", value: "Australiano" },
  { option: "Austriaco", value: "Austriaco" },
  { option: "Azerbaiyano", value: "Azerbaiyano" },
  { option: "Bahameño", value: "Bahameño" },
  { option: "Bangladesí", value: "Bangladesí" },
  { option: "Barbadeño", value: "Barbadeño" },
  { option: "Bareiní", value: "Bareiní" },
  { option: "Belga", value: "Belga" },
  { option: "Beliceño", value: "Beliceño" },
  { option: "Beninés", value: "Beninés" },
  { option: "Bielorruso", value: "Bielorruso" },
  { option: "Birmano", value: "Birmano" },
  { option: "Boliviano", value: "Boliviano" },
  { option: "Bosnio, Herzegovino", value: "Bosnio, Herzegovino" },
  { option: "Botsuano", value: "Botsuano" },
  { option: "Brasileño", value: "Brasileño" },
  { option: "Bruneano", value: "Bruneano" },
  { option: "Búlgaro", value: "Búlgaro" },
  { option: "Burkinés", value: "Burkinés" },
  { option: "Burundés", value: "Burundés" },
  { option: "Butanés", value: "Butanés" },
  { option: "Caboverdiano", value: "Caboverdiano" },
  { option: "Camboyano", value: "Camboyano" },
  { option: "Camerunés", value: "Camerunés" },
  { option: "Canadiense", value: "Canadiense" },
  { option: "Catarí", value: "Catarí" },
  { option: "Chadiano", value: "Chadiano" },
  { option: "Chileno", value: "Chileno" },
  { option: "Chino", value: "Chino" },
  { option: "Chipriota", value: "Chipriota" },
  { option: "Ciudadano del Vaticano", value: "Ciudadano del Vaticano" },
  { option: "Colombiano", value: "Colombiano" },
  { option: "Comorense", value: "Comorense" },
  { option: "Norcoreano", value: "Norcoreano" },
  { option: "Surcoreano", value: "Surcoreano" },
  { option: "Marfileño", value: "Marfileño" },
  { option: "Costarricense", value: "Costarricense" },
  { option: "Croata", value: "Croata" },
  { option: "Cubano", value: "Cubano" },
  { option: "Danés", value: "Danés" },
  { option: "Dominicano", value: "Dominicano" },
  { option: "Ecuatoriano", value: "Ecuatoriano" },
  { option: "Egipcio", value: "Egipcio" },
  { option: "Salvadoreño", value: "Salvadoreño" },
  { option: "Emiratí", value: "Emiratí" },
  { option: "Eritreo", value: "Eritreo" },
  { option: "Eslovaco", value: "Eslovaco" },
  { option: "Esloveno", value: "Esloveno" },
  { option: "Español", value: "Español" },
  { option: "Estadounidense", value: "Estadounidense" },
  { option: "Estonio", value: "Estonio" },
  { option: "Etíope", value: "Etíope" },
  { option: "Filipino", value: "Filipino" },
  { option: "Finlandés", value: "Finlandés" },
  { option: "Fiyiano", value: "Fiyiano" },
  { option: "Francés", value: "Francés" },
  { option: "Gabonés", value: "Gabonés" },
  { option: "Gambiano", value: "Gambiano" },
  { option: "Georgiano", value: "Georgiano" },
  { option: "Ghanés", value: "Ghanés" },
  { option: "Granadino", value: "Granadino" },
  { option: "Griego", value: "Griego" },
  { option: "Guatemalteco", value: "Guatemalteco" },
  { option: "Guyanés", value: "Guyanés" },
  { option: "Guineano", value: "Guineano" },
  { option: "Ecuatoguineano", value: "Ecuatoguineano" },
  { option: "Bisauguineano", value: "Bisauguineano" },
  { option: "Haitiano", value: "Haitiano" },
  { option: "Hondureño", value: "Hondureño" },
  { option: "Húngaro", value: "Húngaro" },
  { option: "Indio", value: "Indio" },
  { option: "Indonesio", value: "Indonesio" },
  { option: "Iraquí", value: "Iraquí" },
  { option: "Iraní", value: "Iraní" },
  { option: "Irlandés", value: "Irlandés" },
  { option: "Islandés", value: "Islandés" },
  { option: "Marshalés", value: "Marshalés" },
  { option: "Salomonense", value: "Salomonense" },
  { option: "Israelí", value: "Israelí" },
  { option: "Italiano", value: "Italiano" },
  { option: "Jamaicano", value: "Jamaicano" },
  { option: "Japonés", value: "Japonés" },
  { option: "Jordano", value: "Jordano" },
  { option: "Kazajo", value: "Kazajo" },
  { option: "Keniano", value: "Keniano" },
  { option: "Kirguís", value: "Kirguís" },
  { option: "Kiribatiano", value: "Kiribatiano" },
  { option: "Kuwaití", value: "Kuwaití" },
  { option: "Laotiano", value: "Laotiano" },
  { option: "Lesotense", value: "Lesotense" },
  { option: "Letón", value: "Letón" },
  { option: "Libanés", value: "Libanés" },
  { option: "Liberiano", value: "Liberiano" },
  { option: "Libio", value: "Libio" },
  { option: "Liechtensteiniano", value: "Liechtensteiniano" },
  { option: "Lituano", value: "Lituano" },
  { option: "Luxemburgués", value: "Luxemburgués" },
  { option: "Macedonio", value: "Macedonio" },
  { option: "Malasio y Malauí", value: "Malasio y Malauí" },
  { option: "Maldivo", value: "Maldivo" },
  { option: "Maliense", value: "Maliense" },
  { option: "Maltés", value: "Maltés" },
  { option: "Marroquí", value: "Marroquí" },
  { option: "Mauriciano", value: "Mauriciano" },
  { option: "Mauritano", value: "Mauritano" },
  { option: "Mexicano", value: "Mexicano" },
  { option: "Micronesio", value: "Micronesio" },
  { option: "Moldavo", value: "Moldavo" },
  { option: "Monegasco", value: "Monegasco" },
  { option: "Mongol", value: "Mongol" },
  { option: "Montenegrino", value: "Montenegrino" },
  { option: "Mozambiqueño", value: "Mozambiqueño" },
  { option: "Namibio", value: "Namibio" },
  { option: "Nauruano", value: "Nauruano" },
  { option: "Nepalí", value: "Nepalí" },
  { option: "Nicaragüense", value: "Nicaragüense" },
  { option: "Nigerino", value: "Nigerino" },
  { option: "Nigeriano", value: "Nigeriano" },
  { option: "Noruego", value: "Noruego" },
  { option: "Neozelandés", value: "Neozelandés" },
  { option: "Omaní", value: "Omaní" },
  { option: "Neerlandés", value: "Neerlandés" },
  { option: "Pakistaní", value: "Pakistaní" },
  { option: "Palauano", value: "Palauano" },
  { option: "Panameño", value: "Panameño" },
  { option: "Papua Nueva Guineano", value: "Papua Nueva Guineano" },
  { option: "Paraguayo", value: "Paraguayo" },
  { option: "Peruano", value: "Peruano" },
  { option: "Polaco", value: "Polaco" },
  { option: "Portugués", value: "Portugués" },
  { option: "Británico", value: "Británico" },
  { option: "Centroafricano", value: "Centroafricano" },
  { option: "Checo", value: "Checo" },
  { option: "Congoleño", value: "Congoleño" },
  { option: "Dominicano", value: "Dominicano" },
  { option: "Ruandés", value: "Ruandés" },
  { option: "Rumano", value: "Rumano" },
  { option: "Ruso", value: "Ruso" },
  { option: "Samoano", value: "Samoano" },
  { option: "Kittitiano y Nevisiano", value: "Kittitiano y Nevisiano" },
  { option: "Sanmarinense", value: "Sanmarinense" },
  { option: "Sanvicentino y Granadino", value: "Sanvicentino y Granadino" },
  { option: "Santaluciano", value: "Santaluciano" },
  { option: "Santotomense", value: "Santotomense" },
  { option: "Senegalés", value: "Senegalés" },
  { option: "Serbio", value: "Serbio" },
  { option: "Seychellense", value: "Seychellense" },
  { option: "Sierra Leonés", value: "Sierra Leonés" },
  { option: "Singapurense", value: "Singapurense" },
  { option: "Sirio", value: "Sirio" },
  { option: "Somalí", value: "Somalí" },
  { option: "Ceilandés", value: "Ceilandés" },
  { option: "Suazi", value: "Suazi" },
  { option: "Sudafricano", value: "Sudafricano" },
  { option: "Sudanés", value: "Sudanés" },
  { option: "Sursudanés", value: "Sursudanés" },
  { option: "Sueco", value: "Sueco" },
  { option: "Suizo", value: "Suizo" },
  { option: "Surinamés", value: "Surinamés" },
  { option: "Tailandés", value: "Tailandés" },
  { option: "Tanzano", value: "Tanzano" },
  { option: "Tayiko", value: "Tayiko" },
  { option: "Timorense", value: "Timorense" },
  { option: "Togolés", value: "Togolés" },
  { option: "Tongano", value: "Tongano" },
  { option: "Trinidadense y Tobagonense", value: "Trinidadense y Tobagonense" },
  { option: "Tunecino", value: "Tunecino" },
  { option: "Turcomano", value: "Turcomano" },
  { option: "Turco", value: "Turco" },
  { option: "Tuvaluano", value: "Tuvaluano" },
  { option: "Ucraniano", value: "Ucraniano" },
  { option: "Ugandés", value: "Ugandés" },
  { option: "Uruguayo", value: "Uruguayo" },
  { option: "Uzbeko", value: "Uzbeko" },
  { option: "Vanuatuense", value: "Vanuatuense" },
  { option: "Venezolano", value: "Venezolano" },
  { option: "Vietnamita", value: "Vietnamita" },
  { option: "Yemení", value: "Yemení" },
  { option: "Yibutiano", value: "Yibutiano" },
  { option: "Zambiano", value: "Zambiano" },
  { option: "Zimbabuense", value: "Zimbabuense" },
];

const TipoAccount = [
  { option: "Cuenta de ahorros", value: "Cuenta de ahorros" },
  { option: "Cuenta corriente", value: "Cuenta corriente" },
];

const Balance = [
  { option: "0", value: "..." },
  { option: "ANTICIPATED_WITHDRAWAL", value: "Balance" },
  { option: "PROFITS_WITHDRAWAL", value: "Utilidades" },
  { option: "CONTRACT_ENDING_WITHDRAWAL", value: "Retiro de inversión" },
];
const TipoPersonal = [
  { option: "0", value: "..." },
  { option: "Señor", value: "Señor" },
  { option: "Señora", value: "Señora" },
  { option: "Señores", value: "Señores" },
];
const TipoPersonalEnglish = [
  { spanish: "Señor", english: "Mr" },
  { spanish: "Señora", english: "Mrs" },
  { spanish: "Señores", english: "Gentlemen" },
]
const Metodo = [
  { option: "0", value: "..." },
  { option: "Cuenta bancaria", value: "Cuenta bancaria" },
];
const Idiomas = [
  { option: "0", value: "..." },
  { option: "Español", value: "Español" },
  { option: "Inglés", value: "Inglés" },
];
const Movimientos = [
  { option: "0", value: "..." },
  { option: "ALL", value: "Todos los movimientos" },
  { option: "INVESTMENT", value: "Solo inversión" },
  { option: "BALANCE", value: "Solo utilidades" },
  { option: "WITHDRAWAL", value: "Solo retiros" },
];
const ProductoRetiro = [
  {
    option: "Portafolio renta fija #123456789",
    value: "Portafolio renta fija #123456789",
  },
  {
    option: "Portafolio renta fija premium #098765432",
    value: "Portafolio renta fija premium #098765432",
  },
  { option: "Portafolio OTC #456789382", value: "Portafolio OTC #456789382" },
  {
    option: "Portafolio renta variable #908765342",
    value: "Portafolio renta variable #908765342",
  },
];

const convertDecimal = (event) => {
  const { value } = event.target;
  const unformattedValue = value.replace(/[,|.]/g, "");
  const parsedValue = parseFloat(unformattedValue);
  const formattedValue = !isNaN(parsedValue)
    ? parsedValue.toLocaleString("es-ES")
    : "";
  return formattedValue;
};

function convertDecimalAlternate(numero) {
  // Redondear a 3 decimales como máximo
  var numeroRedondeado = Number(numero.toFixed(3));

  // Convertir el número a texto
  var numeroTexto = numeroRedondeado.toLocaleString('es-ES', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3
  });

  return numeroTexto;
}

const indicativos = [
  { "country": "Afganistán", "code": "93" },
  { "country": "Albania", "code": "355" },
  { "country": "Alemania", "code": "49" },
  { "country": "Andorra", "code": "376" },
  { "country": "Angola", "code": "244" },
  { "country": "Anguila", "code": "1-264" },
  { "country": "Antigua y Barbuda", "code": "1-268" },
  { "country": "Arabia Saudita", "code": "966" },
  { "country": "Argelia", "code": "213" },
  { "country": "Argentina", "code": "54" },
  { "country": "Armenia", "code": "374" },
  { "country": "Aruba", "code": "297" },
  { "country": "Australia", "code": "61" },
  { "country": "Austria", "code": "43" },
  { "country": "Azerbaiyán", "code": "994" },
  { "country": "Bahamas", "code": "1-242" },
  { "country": "Baréin", "code": "973" },
  { "country": "Bangladés", "code": "880" },
  { "country": "Barbados", "code": "1-246" },
  { "country": "Bélgica", "code": "32" },
  { "country": "Belice", "code": "501" },
  { "country": "Benín", "code": "229" },
  { "country": "Bermudas", "code": "1-441" },
  { "country": "Bielorrusia", "code": "375" },
  { "country": "Bolivia", "code": "591" },
  { "country": "Bonaire, San Eustaquio y Saba", "code": "599" },
  { "country": "Bosnia y Herzegovina", "code": "387" },
  { "country": "Botsuana", "code": "267" },
  { "country": "Brasil", "code": "55" },
  { "country": "Brunéi", "code": "673" },
  { "country": "Bulgaria", "code": "359" },
  { "country": "Burkina Faso", "code": "226" },
  { "country": "Burundi", "code": "257" },
  { "country": "Bután", "code": "975" },
  { "country": "Cabo Verde", "code": "238" },
  { "country": "Camboya", "code": "855" },
  { "country": "Camerún", "code": "237" },
  { "country": "Chad", "code": "235" },
  { "country": "Chile", "code": "56" },
  { "country": "China", "code": "86" },
  { "country": "Chipre", "code": "357" },
  { "country": "Colombia", "code": "57" },
  { "country": "Comoras", "code": "269" },
  { "country": "Corea del Norte", "code": "850" },
  { "country": "Corea del Sur", "code": "82" },
  { "country": "Costa de Marfil", "code": "225" },
  { "country": "Costa Rica", "code": "506" },
  { "country": "Croacia", "code": "385" },
  { "country": "Cuba", "code": "53" },
  { "country": "Curazao", "code": "599" },
  { "country": "Dinamarca", "code": "45" },
  { "country": "Dominica", "code": "1-767" },
  { "country": "Ecuador", "code": "593" },
  { "country": "Egipto", "code": "20" },
  { "country": "El Salvador", "code": "503" },
  { "country": "Emiratos Árabes Unidos", "code": "971" },
  { "country": "Eritrea", "code": "291" },
  { "country": "Eslovaquia", "code": "421" },
  { "country": "Eslovenia", "code": "386" },
  { "country": "España", "code": "34" },
  { "country": "Estados Unidos", "code": "1" },
  { "country": "Estonia", "code": "372" },
  { "country": "Etiopía", "code": "251" },
  { "country": "Filipinas", "code": "63" },
  { "country": "Finlandia", "code": "358" },
  { "country": "Fiyi", "code": "679" },
  { "country": "Francia", "code": "33" },
  { "country": "Gabón", "code": "241" },
  { "country": "Gambia", "code": "220" },
  { "country": "Georgia", "code": "995" },
  { "country": "Ghana", "code": "233" },
  { "country": "Gibraltar", "code": "350" },
  { "country": "Granada", "code": "1-473" },
  { "country": "Grecia", "code": "30" },
  { "country": "Groenlandia", "code": "299" },
  { "country": "Guadalupe", "code": "590" },
  { "country": "Guam", "code": "1-671" },
  { "country": "Guatemala", "code": "502" },
  { "country": "Guayana Francesa", "code": "594" },
  { "country": "Guernsey", "code": "44" },
  { "country": "Guinea", "code": "224" },
  { "country": "Guinea Ecuatorial", "code": "240" },
  { "country": "Guinea-Bisáu", "code": "245" },
  { "country": "Guyana", "code": "592" },
  { "country": "Haití", "code": "509" },
  { "country": "Honduras", "code": "504" },
  { "country": "Hong Kong", "code": "852" },
  { "country": "Hungría", "code": "36" },
  { "country": "India", "code": "91" },
  { "country": "Indonesia", "code": "62" },
  { "country": "Irán", "code": "98" },
  { "country": "Iraq", "code": "964" },
  { "country": "Irlanda", "code": "353" },
  { "country": "Isla de Man", "code": "44" },
  { "country": "Isla Navidad", "code": "61" },
  { "country": "Isla Norfolk", "code": "672" },
  { "country": "Islandia", "code": "354" },
  { "country": "Islas Caimán", "code": "1-345" },
  { "country": "Islas Cocos", "code": "61" },
  { "country": "Islas Cook", "code": "682" },
  { "country": "Islas Feroe", "code": "298" },
  { "country": "Islas Malvinas", "code": "500" },
  { "country": "Islas Marianas del Norte", "code": "1-670" },
  { "country": "Islas Marshall", "code": "692" },
  { "country": "Islas Pitcairn", "code": "870" },
  { "country": "Islas Salomón", "code": "677" },
  { "country": "Islas Turcas y Caicos", "code": "1-649" },
  { "country": "Islas Vírgenes Británicas", "code": "1-284" },
  { "country": "Islas Vírgenes de los Estados Unidos", "code": "1-340" },
  { "country": "Israel", "code": "972" },
  { "country": "Italia", "code": "39" },
  { "country": "Jamaica", "code": "1-876" },
  { "country": "Japón", "code": "81" },
  { "country": "Jersey", "code": "44" },
  { "country": "Jordania", "code": "962" },
  { "country": "Kazajistán", "code": "7" },
  { "country": "Kenia", "code": "254" },
  { "country": "Kirguistán", "code": "996" },
  { "country": "Kiribati", "code": "686" },
  { "country": "Kosovo", "code": "383" },
  { "country": "Kuwait", "code": "965" },
  { "country": "Laos", "code": "856" },
  { "country": "Lesoto", "code": "266" },
  { "country": "Letonia", "code": "371" },
  { "country": "Líbano", "code": "961" },
  { "country": "Liberia", "code": "231" },
  { "country": "Libia", "code": "218" },
  { "country": "Liechtenstein", "code": "423" },
  { "country": "Lituania", "code": "370" },
  { "country": "Luxemburgo", "code": "352" },
  { "country": "Macao", "code": "853" },
  { "country": "Macedonia del Norte", "code": "389" },
  { "country": "Madagascar", "code": "261" },
  { "country": "Malasia", "code": "60" },
  { "country": "Malaui", "code": "265" },
  { "country": "Maldivas", "code": "960" },
  { "country": "Malí", "code": "223" },
  { "country": "Malta", "code": "356" },
  { "country": "Marruecos", "code": "212" },
  { "country": "Martinica", "code": "596" },
  { "country": "Mauricio", "code": "230" },
  { "country": "Mauritania", "code": "222" },
  { "country": "Mayotte", "code": "262" },
  { "country": "México", "code": "52" },
  { "country": "Micronesia", "code": "691" },
  { "country": "Moldavia", "code": "373" },
  { "country": "Mónaco", "code": "377" },
  { "country": "Mongolia", "code": "976" },
  { "country": "Montenegro", "code": "382" },
  { "country": "Montserrat", "code": "1-664" },
  { "country": "Mozambique", "code": "258" },
  { "country": "Myanmar (Birmania)", "code": "95" },
  { "country": "Namibia", "code": "264" },
  { "country": "Nauru", "code": "674" },
  { "country": "Nepal", "code": "977" },
  { "country": "Nicaragua", "code": "505" },
  { "country": "Níger", "code": "227" },
  { "country": "Nigeria", "code": "234" },
  { "country": "Niue", "code": "683" },
  { "country": "Noruega", "code": "47" },
  { "country": "Nueva Caledonia", "code": "687" },
  { "country": "Nueva Zelanda", "code": "64" },
  { "country": "Omán", "code": "968" },
  { "country": "Países Bajos", "code": "31" },
  { "country": "Pakistán", "code": "92" },
  { "country": "Palaos", "code": "680" },
  { "country": "Palestina", "code": "970" },
  { "country": "Panamá", "code": "507" },
  { "country": "Papúa Nueva Guinea", "code": "675" },
  { "country": "Paraguay", "code": "595" },
  { "country": "Perú", "code": "51" },
  { "country": "Polonia", "code": "48" },
  { "country": "Portugal", "code": "351" },
  { "country": "Qatar", "code": "974" },
  { "country": "Reino Unido", "code": "44" },
  { "country": "República Centroafricana", "code": "236" },
  { "country": "República Checa", "code": "420" },
  { "country": "República del Congo", "code": "242" },
  { "country": "República Democrática del Congo", "code": "243" },
  { "country": "República Dominicana", "code": "1-809" },
  { "country": "Reunión", "code": "262" },
  { "country": "Ruanda", "code": "250" },
  { "country": "Rumanía", "code": "40" },
  { "country": "Rusia", "code": "7" },
  { "country": "Sahara Occidental", "code": "212" },
  { "country": "Samoa", "code": "685" },
  { "country": "Samoa Americana", "code": "1-684" },
  { "country": "San Bartolomé", "code": "590" },
  { "country": "San Cristóbal y Nieves", "code": "1-869" },
  { "country": "San Marino", "code": "378" },
  { "country": "San Martín", "code": "590" },
  { "country": "San Pedro y Miquelón", "code": "508" },
  { "country": "San Vicente y las Granadinas", "code": "1-784" },
  { "country": "Santa Lucía", "code": "1-758" },
  { "country": "Santo Tomé y Príncipe", "code": "239" },
  { "country": "Senegal", "code": "221" },
  { "country": "Serbia", "code": "381" },
  { "country": "Seychelles", "code": "248" },
  { "country": "Sierra Leona", "code": "232" },
  { "country": "Singapur", "code": "65" },
  { "country": "Siria", "code": "963" },
  { "country": "Somalia", "code": "252" },
  { "country": "Sri Lanka", "code": "94" },
  { "country": "Sudáfrica", "code": "27" },
  { "country": "Sudán", "code": "249" },
  { "country": "Sudán del Sur", "code": "211" },
  { "country": "Suecia", "code": "46" },
  { "country": "Suiza", "code": "41" },
  { "country": "Surinam", "code": "597" },
  { "country": "Svalbard y Jan Mayen", "code": "47" },
  { "country": "Swazilandia", "code": "268" },
  { "country": "Tailandia", "code": "66" },
  { "country": "Taiwán", "code": "886" },
  { "country": "Tanzania", "code": "255" },
  { "country": "Tayikistán", "code": "992" },
  { "country": "Timor Oriental", "code": "670" },
  { "country": "Togo", "code": "228" },
  { "country": "Tokelau", "code": "690" },
  { "country": "Tonga", "code": "676" },
  { "country": "Trinidad y Tobago", "code": "1-868" },
  { "country": "Túnez", "code": "216" },
  { "country": "Turkmenistán", "code": "993" },
  { "country": "Turquía", "code": "90" },
  { "country": "Tuvalu", "code": "688" },
  { "country": "Ucrania", "code": "380" },
  { "country": "Uganda", "code": "256" },
  { "country": "Uruguay", "code": "598" },
  { "country": "Uzbekistán", "code": "998" },
  { "country": "Vanuatu", "code": "678" },
  { "country": "Vaticano", "code": "39" },
  { "country": "Venezuela", "code": "58" },
  { "country": "Vietnam", "code": "84" },
  { "country": "Wallis y Futuna", "code": "681" },
  { "country": "Yemen", "code": "967" },
  { "country": "Yibuti", "code": "253" },
  { "country": "Zambia", "code": "260" },
  { "country": "Zimbabue", "code": "263" }
]

export {
  API_URL,
  convertDecimal,
  convertDecimalAlternate,
  Metodo,
  TipoDoc,
  Balance,
  TipoAccount,
  TipoPersona,
  Nationalitys,
  TipoPort,
  Estado,
  TipoPersonal,
  Idiomas,
  Movimientos,
  ProductoRetiro,
  CURRENCY_LOCATION,
  CURRENCY,
  indicativos,
  Countries,
  CountriesTraslation,
  TipoPersonalEnglish,
};

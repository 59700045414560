import React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

import "./styles.scss";

const CustomInput = ({
  label,
  value,
  type,
  error,
  errorMessage,
  success,
  rightIcon,
  leftIcon,
  onRightIconClick,
  onLeftIconClick,
  thousandSeparator = true,
  ...restProps
}) => {
  const inputClassName = `cab-input ${error ? "error" : ""} ${
    success ? "success" : ""
  } ${leftIcon && "input-icon-left"}`;

  return (
    <>
      <div className="input-wrapper">
        {label && <p className="titulo-2">{label}</p>}
        <div className="input-body" style={{ display: "flex" }}>
          {leftIcon && (
            <span className="icon-left" onClick={onLeftIconClick}>
              {leftIcon}
            </span>
          )}
          {type == "number" && thousandSeparator && (
            <NumericFormat
              placeholder={restProps.placeholder}
              className={inputClassName}
              value={value}
              thousandSeparator=","
              onChange={restProps.onChange}
            />
          )}
          {type == "number" && !thousandSeparator && (
            <input
              autoComplete="new-password"
              className={inputClassName}
              type={type}
              value={value}
              {...restProps}
            />
          )}
           {type == "phone" && (
            <input
              autoComplete="new-password"
              className={inputClassName}
              type="number"
              value={value}
              {...restProps}
            />
          )}
          {type != "number" && type !="phone" && (
            <input
              autoComplete="new-password"
              className={inputClassName}
              type={type}
              value={value}
              {...restProps}
            />
          )}

          {rightIcon && (
            <span className="input-icon" onClick={onRightIconClick}>
              {rightIcon}
            </span>
          )}
        </div>
        {error && <p className="text-error">{errorMessage}</p>}
      </div>
    </>
  );
};

CustomInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  rightIcon: PropTypes.node, // Puede ser un componente de icono
  onRightIconClick: PropTypes.func,
  className: PropTypes.string,
};

export default CustomInput;

import React, { useState } from "react";

import { formatDate, formatNumber } from "../../../utils/Utils";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const Table = ({ data }) => {
  const [t, _] = useTranslation("global");

  const [openRows, setOpenRows] = useState([]);

  const handleArrowClick = (index) => {
    setOpenRows((prevOpenRows) => {
      if (prevOpenRows.includes(index)) {
        return prevOpenRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevOpenRows, index];
      }
    });
  };

  const isRowOpen = (index) => {
    return openRows.includes(index);
  };

  const renderTableHead = (item, index) => {
    const isOpen = isRowOpen(index);
    return (
      <tr key={item._id}>
        <th className="product-info">
          <span style={{ width: "100px" }}>
            <span className="title"># {t("withdrawals.contract")}</span>{" "}
            {item.contract_number}
          </span>
          <div>
            <span className="yellow">{t("utilities.utility")}</span>
            <span>$ {formatNumber(item.amount_earning)} </span>
            <span className="dolar">USD</span>
          </div>
          <div
            className={`arrow ${isOpen ? "expanded" : ""}`}
            onClick={() => handleArrowClick(index)}
          ></div>
        </th>
      </tr>
    );
  };

  const renderTableBody = (item, index) => {
    const isOpen = isRowOpen(index);
    return (
      <React.Fragment key={item.id}>
        {isOpen && (
          <tr>
            <td>
              <div className="details">
                <div className="detail-row">
                  <span className="title"># {t("withdrawals.contract")}</span>
                  <span className="title">{t("utilities.portfolio_type")}</span>
                  <span className="title">{t("utilities.date_utility")}</span>
                  <span className="title">{t("utilities.amount_earning")}</span>
                  <span className="title">{t("utilities.fee_admin")}</span>
                </div>
                <div className="detail-row">
                  <span>{item.contract_number}</span>
                  <span>{item.portfolio_type}</span>
                  <span>{formatDate(item.date_settlement)}</span>
                  <span>
                    $ {formatNumber(item.amount_earning)}
                    <span className="dolar">USD</span>
                  </span>
                  <span>
                    $ {formatNumber(item.fee_admin_discount)}
                    <span className="dolar">USD</span>
                  </span>
                </div>
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="table-wrapper">
      <table className="responsive-table">
        {data.map((item, index) => {
          return (
            <>
              <thead>{renderTableHead(item, index)}</thead>
              <tbody>{renderTableBody(item, index)}</tbody>
            </>
          );
        })}
      </table>
      <table className="normal-table">
        <thead>
          <tr>
            <th>{t("utilities.contract_number")}</th>
            <th>{t("utilities.capital")}</th>
            <th>{t("utilities.portfolio_type")}</th>
            <th>{t("utilities.percentage")}</th>
            <th>{t("utilities.date_utility")}</th>
            <th>{t("utilities.amount_earning")}</th>
            <th>{t("utilities.fee_admin")}</th>
            <th>{t("utilities.liquidation_date")} /<br></br> {t("utilities.liquidation_date_end")}</th>
            <th>{t("utilities.period")}</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              return (
                <tr key={item._id}>
                  <td>{item.contract_number}</td>
                  <td>{formatNumber(item.capital)}{" "} <span className="currency"> USD</span></td>
                  <td>{item.portfolio_type}</td>
                  <td>{!isNaN(item.percentage)? `${item?.percentage * 100}%`: `${0}%`}</td>
                  <td>{formatDate(item.date_settlement)}</td>
                  <td>
                    $ {formatNumber(item?.amount_earning ?? 0)}{" "}
                    <span className="currency"> USD</span>
                  </td>
                  <td>
                    $ {formatNumber(item?.fee_admin_discount)}{" "}
                    <span className="currency"> USD</span>
                  </td>
                  <td>
                    {formatDate(item.start_date_liquidation)} /{" "}
                    {formatDate(item.end_date_liquidation)}
                  </td>
                  <td>{formatNumber(item.effective_days)} {t("utilities.days")}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

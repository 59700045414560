import React, { useState } from "react";

import { formatDate, formatNumber } from "../../../utils/Utils";
import { useRetiro } from "../hooks/useRetiro";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const Table = ({ data }) => {
  const [t, i18n] = useTranslation("global");
  const { downloadBill } = useRetiro();

  const [openRows, setOpenRows] = useState([]);

  const handleArrowClick = (index) => {
    setOpenRows((prevOpenRows) => {
      if (prevOpenRows.includes(index)) {
        return prevOpenRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevOpenRows, index];
      }
    });
  };

  const isRowOpen = (index) => {
    return openRows.includes(index);
  };

  const setApprovementName = (approvement) => {
    if (approvement === 'APPROVED') {
      return t("movements.table.approved")
    } else if (approvement === 'UNDER_REVIEW') {
      return t("movements.table.under_review")
    } else {
      return t("movements.table.refused")
    }
  }

  const renderTableHead = (item, index) => {
    const isOpen = isRowOpen(index);
    return (
      <tr key={item._id}>
        <th className="product-info">
          <span>
            <span className="title"># {t("movements.table.withdrawal")}</span>
            {item._id}
          </span>
          <div style={{ width: "100px" }}>
            <span className="yellow">{item.approvement}</span>
          </div>
          <div
            className={`arrow ${isOpen ? "expanded" : ""}`}
            onClick={() => handleArrowClick(index)}
          ></div>
        </th>
      </tr>
    );
  };

  const renderTableBody = (item, index) => {
    const isOpen = isRowOpen(index);
    return (
      <React.Fragment key={item.id}>
        {isOpen && (
          <tr>
            <td>
              <div className="details">
                <div className="detail-row">
                  <span className="title"># {t("withdrawals.contract")}</span>
                  <span className="title">{t("withdrawals.withdrawal_type")}</span>
                  <span className="title">{t("withdrawals.request_date")}</span>
                  <span className="title">{t("movements.table.amount")}</span>
                  <span className="title">Descuento</span>
                  <span className="title">{t("withdrawals.disbursement")}</span>
                  <span></span>
                </div>
                <div className="detail-row">
                  <span>{item.contract_number}</span>
                  <span>{item.withdrawal_type}</span>
                  <span>{formatDate(item.date_request)}</span>
                  <span>$ {formatNumber(item.amount)} USD</span>
                  <span className="dolar">$ {formatNumber(item.discount)} USD</span>
                  <span>$ {formatNumber(item.amount - item.discount)} USD</span>
                  {item.approvement == "APPROVED" && (
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        downloadBill(item.bill_id);
                      }}
                    >
                      <a href="" style={{ color: "#7FC5ED" }}>
                        {" "}
                        {t("movements.table.see_invoice")}
                      </a>
                    </span>
                  )}
                </div>
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="table-wrapper">
      <table className="responsive-table">
        {data.map((item, index) => {
          return (
            <>
              <thead>{renderTableHead(item, index)}</thead>
              <tbody>{renderTableBody(item, index)}</tbody>
            </>
          );
        })}
      </table>
      <table className="normal-table">
        <thead>
          <tr>
            <th># {t("movements.table.withdrawal")}</th>
            <th># {t("withdrawals.contract")}</th>
            <th>{t("withdrawals.withdrawal_type")}</th>
            <th>{t("withdrawals.request_date")}</th>
            <th>{t("movements.table.amount")}</th>
            <th>Descuento</th>
            <th>{t("withdrawals.disbursement")}</th>
            <th>{t("movements.table.status")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr>
                <td># {item._id}</td>
                <td># {item.contract_number}</td>
                <td>{item.withdrawal_type}</td>
                <td>{formatDate(item.date_request)}</td>
                <td>
                  $ {formatNumber(item?.amount)}{" "}
                  <span className="currency"> USD</span>
                </td>
                <td>$ {formatNumber(item.discount)} USD</td>
                <td>
                  <span>$ {formatNumber(item.amount - item.discount)} USD</span>
                </td>
                <td>
                  <span className={
                    item.approvement === "APPROVED"
                      ? "approved"
                      : item.approvement === "UNDER_REVIEW"
                      ? "under_review"
                      : "refused"
                  }>{setApprovementName(item.approvement)}</span>
                </td>
                <td>
                  {item.approvement == "APPROVED" && (
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        downloadBill(item.bill_id);
                      }}
                    >
                      <a href="" style={{ color: "#7FC5ED" }}>
                        {" "}
                        {t("movements.table.see_invoice")}
                      </a>
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

import "./styles.scss";
import React, { useEffect, useState, useContext } from "react";
import { GetInboxMessages } from "./services";
import GeneralContext from "../../context/GeneralContext";
import { useTranslation } from "react-i18next";

import searchIcon from "../../assets/img/svg/searchIcon.svg"
import eclipseIcon from "../../assets/img/svg/eclipse.svg"

const Mensajes = ({ setpage }) => {
  const [t, i18n] = useTranslation("global");
  const [mensajes, setMensajes] = useState([]);
  const [filterText, setFilterText] = useState("");
  const { user } = useContext(GeneralContext);

  useEffect(() => {
    GetInboxMessages(user.client_investor_number, user.token)
      .then((resp) => {
        if (resp.status >= 200 && resp.status <= 299) {
          setMensajes(resp.data);
        } else {
          alert("Hubo un error");
        }
      })
      .catch((err) => {
        //alert(`Ha ocurrido un error: ${err}`);
      });
  }, []);

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  const filteredData = filterText
    ? mensajes.filter(
        (item) =>
          item.autor.toLowerCase().includes(filterText.toLowerCase()) ||
          item.subject.toString().includes(filterText)
      )
    : mensajes;

  return (
    <div className="inbox">
      <div className="title">
        <span>{t("sidebar.messages")}</span>
      </div>
      <div className="search">
        <div className="inputSearch">
          <img src={searchIcon} alt="" />
          <input
            placeholder="Buscar por nombre"
            type="text"
            value={filterText}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      <div className="inbox-table">
        {filteredData.map((item, index) => (
          <div
            onClick={() => setpage("2", item)}
            className={`inbox-row ${index === 0 ? 'first-row' : ''}`}
            key={index}
          >
            <span>
              {item.status === "unRead" ? (
                <img src={eclipseIcon} alt="Read" />
              ) : null}{" "}
              {item.autor}
            </span>
            <span>{item.subject}</span>
            <span>{item.date}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Mensajes;

import axios from "axios";
import { URL_API } from "../../config/config";

const API_URL = `${URL_API}/api`;
const token = localStorage.getItem("token");

const LogOut = () => {
  try {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return axios.get(`${API_URL}/authentication/logout`);
  } catch (error) {
    return Promise.reject('Catch ' + JSON.stringify(error));
  }
}

const BlogList = (userToken) => {
  try {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
    axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`
    //axios.defaults.headers.common['Authorization'] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imp1Y2FtaWxvMzQwKzExQGdtYWlsLmNvbSIsImRhdGUiOjE2ODQ1MDczNTI1MjgsImlwIjoiMTgxLjYyLjUyLjEzOCIsImRldmljZSI6Ik1vemlsbGEvNS4wIChNYWNpbnRvc2g7IEludGVsIE1hYyBPUyBYIDEwXzE1XzcpIEFwcGxlV2ViS2l0LzUzNy4zNiAoS0hUTUwsIGxpa2UgR2Vja28pIENocm9tZS8xMTEuMC4wLjAgU2FmYXJpLzUzNy4zNiIsImlhdCI6MTY4NDUwNzM1MiwiZXhwIjoxNjg5NjkxMzUyfQ.VEiaat-ndjsoeNSOL13KBY_v3XflNkXtYz9usQTDxs8`
    return axios.get(`${API_URL}/dashboard_blog/list`);
  } catch (error) {
    return Promise.reject('Catch ' + JSON.stringify(error));
  }
}

const ArticleDetail = (id, _token) => {
  try {
    axios.defaults.headers.common['Authorization'] = `Bearer ${_token}`
    return axios.get(`${API_URL}/dashboard_blog/detail/` + id);
  } catch (error) {
    return Promise.reject('Catch ' + JSON.stringify(error));
  }
}

const NotificationsList = (id) => {
  try {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return axios.get(`${API_URL}/notifications/` + id);
  } catch (error) {
    
    return Promise.reject('Catch ' + JSON.stringify(error));
  }
}

const NotificationsRead = (id) => {
  try {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    //axios.defaults.headers.common['Authorization'] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imp1Y2FtaWxvMzQwKzExQGdtYWlsLmNvbSIsImRhdGUiOjE2ODQ1MDczNTI1MjgsImlwIjoiMTgxLjYyLjUyLjEzOCIsImRldmljZSI6Ik1vemlsbGEvNS4wIChNYWNpbnRvc2g7IEludGVsIE1hYyBPUyBYIDEwXzE1XzcpIEFwcGxlV2ViS2l0LzUzNy4zNiAoS0hUTUwsIGxpa2UgR2Vja28pIENocm9tZS8xMTEuMC4wLjAgU2FmYXJpLzUzNy4zNiIsImlhdCI6MTY4NDUwNzM1MiwiZXhwIjoxNjg5NjkxMzUyfQ.VEiaat-ndjsoeNSOL13KBY_v3XflNkXtYz9usQTDxs8`
    return axios.put(`${API_URL}/notifications/` + id);
  } catch (error) {
    return Promise.reject('Catch ' + JSON.stringify(error));
  }
}

export const uploadReceipt = async (token, file) => {
  const multipart = new FormData();
  multipart.append("receipt", file);
  const { data } = await axios({
    url: `${API_URL}/wallet/receipt`,
    method: "PUT",
    data: multipart,
    headers: { Authorization: `Bearer ${token}` },
  });
  console.log("🚀 ~ file: services.js:55 ~ uploadContract ~ data:", data);
  return data.success;
};

export const getDataCharts = async (_token) => {
  try {
    axios.defaults.headers.common['Authorization'] = `Bearer ${_token}`
    return axios.get(`${API_URL}/chart/yahoo_finance`);
  } catch (error) {
    return Promise.reject('Catch ' + JSON.stringify(error));
  }
}

export { LogOut, BlogList, ArticleDetail, NotificationsList, NotificationsRead };
import axios from "axios";
import { URL_API } from "../../config/config";

const API_URL = `${URL_API}/api`;

export const GetPortfolios = (token) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.get(`${API_URL}/portfolio`);
  } catch (error) {
    return Promise.reject("Catch " + JSON.stringify(error));
  }
};

export const GetMovements = (token) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.get(`${API_URL}/movements`);
  } catch (error) {
    return Promise.reject("Catch " + JSON.stringify(error));
  }
};

export const downloadBill = async (token, billId) => {
  try {
    const { data, headers } = await axios({
      url: `${API_URL}/downloadBillingById/${billId}`,
      method: "GET",
      // responseType: "arraybuffer",
      headers: { Authorization: `Bearer ${token}` },
    });
    if(data.success){
      window.open(data.document_url_location)
    }
    //downloadBlob(data, headers);
    return data.success;
  } catch (error) {
    console.log("🚀 ~ file: services.js:20 ~ CreateContract ~ error:", error);
    return { success: false };
  }
};

const downloadBlob = (data, headers) => {
  const contentDescription = headers.get("x-file-description");
  const description = JSON.parse(contentDescription);
  const nombreArchivo = description.fileName;

  // Crear una URL del Blob
  const url = window.URL.createObjectURL(new Blob([data]));
  //window.open(url, "_blank");  // To open new tab in browser
  // Crear un enlace temporal y hacer clic en él para descargar el archivo
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", nombreArchivo);
  document.body.appendChild(link);
  link.click();

  // Limpiar la URL del objeto Blob
  window.URL.revokeObjectURL(url);
};

export const checkPendingContracts = async (token) => {
  try {
    const { data } = await axios({
      url: `${API_URL}/check_contracts_pending`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const { success, contract } = data;
    return { success, contract };
  } catch (error) {
    console.log("🚀 ~ file: services.js:20 ~ CreateContract ~ error:", error);
    return { success: false };
  }
};
export const createCustomPortfolio = async (token, body) => {
  try {
    const { data } = await axios({
      url: `${API_URL}/request_custom_portfolio`,
      data: body,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    });
    const { success } = data;
    return { success };
  } catch (error) {
    console.log("🚀 ~ file: services.js:20 ~ CreateCustomPorfolio ~ error:", error);
    return { success: false };
  }
};
export const GetPortfoliosLabels = (token) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.get(`${API_URL}/portfolio_labels`);
  } catch (error) {
    return Promise.reject("Catch " + JSON.stringify(error));
  }
};
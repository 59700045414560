import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { TipoPort, Estado } from "../../utils/constants";
import InputSelect from "../SignUp/InputSelect";

import { useContracts } from "./hooks/useContracts";
import { useMovements } from "../Inversions/hooks/useMovements.jsx";
import Loader from "../../components/loader/loader";
import Table from './Tablefacturas'
import { useTranslation } from "react-i18next";

import arrowBack from "../../assets/img/png/flecha-atras.png";
import searchIcon from "../../assets/img/svg/searchIcon.svg";
import filtroIcon from "../../assets/img/svg/filtroIcon.svg";


import "../Inversions/styles.scss";
import "../Retiros/styles.scss";

const Factura = () => {
  const [t, _] = useTranslation("global");
  const { state } = useLocation();
  const { getBillings } = useContracts();
  const { downloadBillById } = useMovements();

  const [formulario, setFormulario] = useState({
    // State para informacion de usuario
    producto: "",
    transaccion: "",
    estado: "",
  });
  const navigate = useNavigate();
  const [panelF, setpanelF] = useState(false);

  const [bills, setBills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const processGetBills = async () => {
    setIsLoading(true);
    const data = await getBillings(state._id);
    data.length > 0 && setBills(data);
    setIsLoading(false);
  };

  const handleSelectChange = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    processGetBills();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="ContainerCont FacturaContract">
          <div
            onClick={() => navigate("/Portafolio/Detail", { state })}
            className="back"
          >
            <img src={arrowBack} alt="" /> <span>{t("general.back")}</span>
          </div>
          <div className="title">
            <span>{t("invoice.invoices")} # {state.contract_number}</span>
          </div>
          <div className="contratos">
            <div className="search">
              <div className="inputSearch">
                <img src={searchIcon} alt="" />
                <input placeholder="Buscar" type="text" />
              </div>
              <div onClick={() => setpanelF(!panelF)} className="btnFiltro">
                <img src={filtroIcon} alt="" />
                <span>{t("general.filter")}</span>
              </div>
            </div>
            {panelF && (
              <div className="filtros">
                <div className="espacio-input">
                  <InputSelect
                    form={formulario}
                    elements={TipoPort}
                    name="producto"
                    onChange={handleSelectChange}
                  />
                </div>
                <div className="espacio-input">
                  <InputSelect
                    form={formulario}
                    elements={Estado}
                    name="producto"
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
            )}
            {bills && <Table data={bills} /> }
          </div>
        </div>
      )}
    </>
  );
};

export default Factura;

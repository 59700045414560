import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import axios from "axios";

import GeneralContext from "../../context/GeneralContext";
import { URL_API } from "../../config/config";
import bankModalIcon from "../../assets/img/svg/bankModal.svg";
import closeIcon from "../../assets/img/svg/close.svg";

const API_URL = `${URL_API}/api`;

const ModalPay = ({ onOpen, open = false, callback }) => {
  //const navigate = useNavigate();
  const [select, setselect] = useState(null);
  const [t, i18n] = useTranslation("global");

  const [amount, setAmount] = useState("");
  const navigate = useNavigate();

  const GC = useContext(GeneralContext);
  const { user, modificarUser } = GC;

  const pagoCoinpayments = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      const response = await axios.post(API_URL + "/coinpayment", {
        balance: amount,
      });

      if (response.data.success) {
        onOpen(false);
        window.open(response.data.checkout_url);
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  return (
    <div className={open ? `modal` : `hidden`}>
      <div className="contentPay">
        <div className="title">
          <span>{t("invest.modal_pay.title")}</span>
          <img onClick={() => onOpen(false)} src={closeIcon} alt="" />
        </div>

        <div className="pays">
          {select === "1" && (
            <div className="form-input">
              <p>Ingrese el valor a recargar</p>
              <div style={{ width: "90%", padding: 0 }}>
                <span className="dolar">USD</span>
                <input
                  type="number"
                  value={amount}
                  className="input"
                  placeholder="$1’000.0000"
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          )}
          <div
            onClick={() => setselect("2")}
            className={select === "2" ? "select" : ""}
          >
            <img src={bankModalIcon} alt="" />
            <span>{t("invest.modal_pay.bank_account")}</span>
          </div>
        </div>
        {select && (
          <div
            className="btn"
            onClick={() => (select === "1" ? pagoCoinpayments() : callback())}
          >
            <span>{t("invest.modal_pay.continue")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalPay;

import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Nationalitys,
  TipoDoc,
  TipoPersona,
  indicativos,
} from "../../utils/constants";
import { isPermitAge, validateEmail } from "../../utils/Utils";

import CABDropDown from "../../components/CABDropdown";
import CABInput from "../../components/CABInput";
import CABButton from "../../components/CABButton";
import CABCalendar from "../../components/CABCalendar";

import Done from "../../assets/img/svg/Realizado.svg";

import "./SignUp.scss";

const GeneralInformation = ({ changeStep, form, setForm }) => {
  const [t, _] = useTranslation("global");

  const [validForm, setValidForm] = useState(false);

  const [errorBirthday, setErrorBirthday] = useState(false);
  const [errorMsgBirthday, setErrorMsgBirthday] = useState(null);

  const submit = () => {
    if (testForm()) {
      changeStep(2);
    }
  };

  const testForm = React.useCallback(() => {
    if (form.birthday) {
      const permitAge = isPermitAge(new Date(form.birthday));
      setErrorBirthday(permitAge);
    }
    return (
      form.client_type &&
        form.lastName &&
        form.firstName &&
        form.identification_type &&
        form.identification_number &&
        form.birthday &&
        form.nationality &&
        form.email &&
        validateEmail(form.email) &&
        form.code_phone &&
        form.phone_number &&
        form.occupation &&
        form.employer_name &&
        // form.client_type === "COMPANY"? validateCompanyType() : true &&
        !!errorBirthday
    );
  }, [form, errorBirthday]);

  const validateCompanyType = () => {
    if (form.client_type === "COMPANY") {
      return (
        form.legal_representative_name &&
        form.id_legal_representative_name &&
        form.company_name &&
        form.nit
      )
    } else {
      return true
    }
  }

  useEffect(() => {
    setValidForm(testForm() && validateCompanyType());
  }, [form, errorBirthday]);

  return (
    <div className="formulario-registro">
      <div style={{ display: "flex" }}>
        <img className="check" src={Done} alt="" />
        <p className="titulo-section">{t("signup.step_1")}</p>
      </div>
      <div className="container-campos">
        <div>
          <CABDropDown
            label={t("signup.general_information.person_type")}
            options={TipoPersona}
            value={form.client_type}
            onChange={(e) => setForm({ ...form, client_type: e.target.value })}
          />
        </div>
        <div>
          <CABInput
            label={t("signup.general_information.names")}
            type="text"
            placeholder={t("signup.write_here")}
            value={form.firstName}
            onChange={(e) => setForm({ ...form, firstName: e.target.value })}
          />
        </div>
        <div>
          <CABInput
            label={t("signup.general_information.last_name")}
            type="text"
            placeholder={t("signup.write_here")}
            value={form.lastName}
            onChange={(e) => setForm({ ...form, lastName: e.target.value })}
          />
        </div>

        <div>
          <CABDropDown
            label={t("signup.general_information.identification_type")}
            options={TipoDoc}
            name="identification_type"
            value={form.identification_type}
            onChange={(e) =>
              setForm({
                ...form,
                identification_type: e.target.value,
              })
            }
          />
        </div>
        <div>
          <CABInput
            label={t("signup.general_information.identification_number")}
            type="text"
            thousandSeparator={false}
            placeholder={t("signup.write_here")}
            value={form.identification_number}
            onChange={(e) =>
              setForm({
                ...form,
                identification_number: e.target.value,
              })
            }
          />
        </div>
        <div>
          <CABCalendar
            label={t("signup.general_information.birthday_date")}
            placeholder="DD/MM/AAAA"
            value={form.birthday}
            onChangeValue={(value) => {
              const permitAge = isPermitAge(new Date(value));
              setErrorBirthday(permitAge);
              setErrorMsgBirthday(null);
              if (!permitAge) {
                setErrorMsgBirthday("Debe ser mayor de edad");
              }
              setForm({ ...form, birthday: value });
            }}
            error={!errorBirthday}
            errorMessage={errorMsgBirthday}
          />
        </div>
        <div>
          <CABDropDown
            label={t("signup.general_information.nationality")}
            form={form}
            options={Nationalitys}
            name="nationality"
            value={form.nationality}
            onChange={(e) =>
              setForm({
                ...form,
                nationality: e.target.value,
              })
            }
          />
        </div>
        <div>
          <CABInput
            label={t("signup.general_information.email")}
            type="email"
            placeholder={t("signup.write_here")}
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
          />
        </div>

        <div style={{ display: "flex" }}>
          <CABDropDown
            type="phone"
            label={t("signup.general_information.phone")}
            options={indicativos.map((value) => {
              return {
                option: value.code,
                value: `+ ${value.code} ${value.country}`,
              };
            })}
            value={form.code_phone}
            onChange={(e) =>
              {
                console.log(e.target.value);
                setForm({
                  ...form,
                  code_phone: e.target.value,
                })
              }
            }
          />
          <CABInput
            style={{ marginLeft: "10px", marginTop: "52px" }}
            type="phone"
            placeholder="000 000 000"
            value={form.phone_number}
            onChange={(e) =>
              setForm({
                ...form,
                phone_number: e.target.value,
              })
            }
          />
        </div>
        <div>
          <CABInput
            label={t("signup.general_information.occupation")}
            type="text"
            placeholder={t("signup.write_here")}
            value={form.occupation}
            onChange={(e) => setForm({ ...form, occupation: e.target.value })}
          />
        </div>
        <div>
          <CABInput
            label={t("signup.general_information.employee")}
            type="text"
            placeholder={t("signup.write_here")}
            value={form.employer_name}
            onChange={(e) =>
              setForm({
                ...form,
                employer_name: e.target.value,
              })
            }
          />
        </div>
        {form.client_type === "COMPANY" &&
        <>
          <div>
            <CABInput
              label={t("signup.general_information.id_legal_representative")}
              type="text"
              placeholder={t("signup.write_here")}
              value={form.id_legal_representative_name}
              onChange={(e) =>
                setForm({
                  ...form,
                  id_legal_representative_name: e.target.value
                })
              }
            />
          </div>
          <div>
            <CABInput
              label={t("signup.general_information.legal_representative")}
              type="text"
              placeholder={t("signup.write_here")}
              value={form.legal_representative_name}
              onChange={(e) =>
                setForm({
                  ...form,
                  legal_representative_name: e.target.value
                })
              }
            />
          </div>
          <div>
            <CABInput
              label={t("signup.general_information.company_name")}
              type="text"
              placeholder={t("signup.write_here")}
              value={form.company_name}
              onChange={(e) =>
                setForm({
                  ...form,
                  company_name: e.target.value
                })
              }
            />
          </div>
          <div>
            <CABInput
              label={t("signup.general_information.nit")}
              type="text"
              placeholder={t("signup.write_here")}
              value={form.nit}
              onChange={(e) =>
                setForm({
                  ...form,
                  nit: e.target.value
                })
              }
            />
          </div>
        </>}
      </div>
      <CABButton
        className={"big"}
        label={t("general.next")}
        disabled={!validForm}
        onClick={() => submit()}
      />
    </div>
  );
};

export default GeneralInformation;

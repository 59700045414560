import React, { useState } from "react";
import "./styles.scss";
import { useMovements } from "../hooks/useMovements";
import { formatDate, formatNumber } from "../../../utils/Utils";
import { useTranslation } from "react-i18next";
import NotificationPopUp from "../../../components/Notification/Notification";

import incomeIcon from "../../../assets/img/svg/Income.svg";
import substrackIcon from "../../../assets/img/svg/substract.svg";

const Table = ({ data }) => {
  const { downloadBillById } = useMovements();

  const [openRows, setOpenRows] = useState([]);
  const [popUp, setPopUp] = useState(null);

  const [t, i18n] = useTranslation("global");

  const showNotification = (message, description, type) => {
    setPopUp({ message, description, type });
  };

  const closeNotification = () => {
    setPopUp(null);
  };

  const handleArrowClick = (index) => {
    setOpenRows((prevOpenRows) => {
      if (prevOpenRows.includes(index)) {
        return prevOpenRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevOpenRows, index];
      }
    });
  };

  const isRowOpen = (index) => {
    return openRows.includes(index);
  };

  const renderTableHead = (item, index) => {
    const isOpen = isRowOpen(index);
    return (
      <tr key={item._id}>
        <th colSpan="2" className="product-info">
          <span>
            <span className="title">{t("movements.table.product")}</span> <br />{" "}
            {t("investments.inversion_item.portfolio")} # {item.contract_number}
          </span>
          <div style={{ width: "100px" }}>
            {item?.type === "In" ? (
              <>
                <img
                  style={{ marginRight: "5px" }}
                  src={incomeIcon}
                  alt=""
                />
                <span>{t("movements.table.income")}</span>
              </>
            ) : (
              <>
                <img
                  style={{ marginRight: "5px" }}
                  src={substrackIcon}
                  alt=""
                />
                <span>{t("movements.table.withdrawal")}</span>
              </>
            )}
          </div>
          <div
            className={`arrow ${isOpen ? "expanded" : ""}`}
            onClick={() => handleArrowClick(index)}
          ></div>
        </th>
      </tr>
    );
  };

  const downloadBill = async (path_invoice) => {
    const success = await downloadBillById(path_invoice);
    if (!success) {
      showNotification(
        t("invest.validate_contract.failed_notification_title"),
        t("general.notification_failed"),
        "error"
      );
    }
  };

  const renderTableBody = (item, index) => {
    const isOpen = isRowOpen(index);
    return (
      <React.Fragment key={item._id}>
        {isOpen && (
          <tr>
            <td>
              <div className="details">
                <div className="detail-row">
                  <span className="title">
                    {t("movements.table.transaction")}
                  </span>
                  <span className="title">{t("movements.table.date")}</span>
                  <span className="title">{t("movements.table.amount")}</span>
                </div>
                <div className="detail-row">
                  <span>
                    {item?.type === "In"
                      ? t("movements.table.balance")
                      : t("movements.table.withdrawal")}
                  </span>
                  <span>{formatDate(item?.date)}</span>
                  <span>
                    $ {formatNumber(item?.amount)}{" "}
                    <span className="currency"> USD</span>
                  </span>
                  {item.document_url_location && (
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        downloadBill(item.document_url_location);
                      }}
                    >
                      <a href="" style={{ color: "#7FC5ED" }}>
                        {" "}
                        {t("movements.table.see_invoice")}
                      </a>
                    </span>
                  )}
                </div>
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      {popUp && (
        <NotificationPopUp
          message={popUp.message}
          type={popUp.type}
          description={popUp.description}
          onClose={closeNotification}
        />
      )}

      <div className="table-wrapper">
        <table className="responsive-table">
          {data.map((item, index) => {
            return (
              <>
                <thead>{renderTableHead(item, index)}</thead>
                <tbody>{renderTableBody(item, index)}</tbody>
              </>
            );
          })}
        </table>
        <table className="normal-table">
          <thead>
            <tr>
              <th>{t("movements.table.product")}</th>
              <th>{t("movements.table.transaction")}</th>
              <th>{t("movements.table.date")}</th>
              <th>{t("movements.table.amount")}</th>
              <th>{t("movements.table.status")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={item._id}>
                  <td>
                    {item?.type === "In" && "Balance"}
                    {item?.type === "Out" &&
                      t("investments.inversion_item.portfolio") + " # "}
                    {item.contract_number}
                  </td>
                  <td>
                    {" "}
                    {item?.type === "In"
                      ? t("movements.table.balance")
                      : t("movements.table.withdrawal")}
                  </td>
                  <td>{formatDate(item?.date)}</td>
                  <td>
                    $ {formatNumber(item?.amount)}{" "}
                    <span className="currency"> USD</span>
                  </td>
                  <td>
                    {item?.type === "In" ? (
                      <>
                        <img
                          style={{ marginRight: "5px" }}
                          src={incomeIcon}
                          alt=""
                        />
                        <span>{t("movements.table.income")}</span>
                      </>
                    ) : (
                      <>
                        <img
                          style={{ marginRight: "5px" }}
                          src={substrackIcon}
                          alt=""
                        />
                        <span>{t("movements.table.withdrawal")}</span>
                      </>
                    )}
                  </td>
                  <td>
                    {item.document_url_location && (
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          downloadBill(item.document_url_location);
                        }}
                      >
                        <a href="" style={{ color: "#7FC5ED" }}>
                          {" "}
                          {t("movements.table.see_invoice")}
                        </a>
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;

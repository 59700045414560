import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { createChart, ColorType } from "lightweight-charts";

import "./styles.scss";
import { formatNumber } from "../../utils/Utils";

const CABGraph = ({ props }) => {
  const backgroundColor = "transparent";
  const textColor = "white";

  const { data, lineColor, areaTopColor, areaBottomColor } = props;
  const chartContainerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({
        width: chartContainerRef.current.clientWidth,
      });
    };

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      width: chartContainerRef.current.clientWidth,
      height: 300,
    });
    chart.timeScale().fitContent();
    if(!data) return;
    const newSeries = chart.addAreaSeries({
      lineColor,
      topColor: areaTopColor,
      bottomColor: areaBottomColor,
      lineType: 2,
      lineWidth: 5,
    });
    newSeries.setData(data);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [
    data,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
  ]);

  return (
    <div className="_chart">
      <div className="container_chart">
        <span className="title">{props.info.currencyName}</span>
        <span className="subtitle">
          {formatNumber(data[data.length - 1]?.value ?? 0)}
        </span>
        <span className="span">{Date(data[data.length - 1]?.time).toString()}</span>
      </div>
      <div ref={chartContainerRef} />
    </div>
  );
};

CABGraph.propTypes = {
  props: PropTypes.object,
};

export default CABGraph;

import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BlogList } from "./services";
import { Helmet } from "react-helmet";

import { formatNumber } from "../../utils/Utils";
import CABGraph from "../../components/CABGraph";

import "./styles.scss";

import arrowLeft from "../../assets/img/svg/arrow-left-white.svg";
import arrowLeftDisable from "../../assets/img/svg/arrow-left-disable.svg";
import arrowRight from "../../assets/img/svg/arrow-right-white.svg";
import arrowRightDisable from "../../assets/img/svg/arrow-right-disable.svg";

import axios from "axios";

//Imports de componentes auxiliares
import GeneralContext from "../../context/GeneralContext";
import ModalPay from "../../components/Modal/ModalPay";
import { useContracts } from "../Contratos/hooks/useContracts";

import { URL_API } from "../../config/config";
import { useTranslation } from "react-i18next";

import totalIcon from "../../assets/img/svg/backMoney5-white.svg";
import walletIcon from "../../assets/img/svg/wallet.svg";
import utilitiesIcon from "../../assets/img/svg/utilidades.svg";
import retiroIcon from "../../assets/img/svg/retiros.svg";
import { useHome } from "./hook/useHome";

const Home = () => {
  const [t, i18n] = useTranslation("global");
  const GC = useContext(GeneralContext);
  const { user } = GC;

  const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;
  const navigate = useNavigate();
  const [panel, setpanel] = useState("1");
  const [open, setOpen] = useState(false);
  const [articulos, setArticulos] = useState([]);
  const [openSupport, setOpenSupport] = useState(false);
  const { getContracts, isLoading } = useContracts();

  const [balance, setBalance] = useState({ consultado: false, valor: 0 });
  const [utilidades, setUtilidades] = useState({ consultado: false, valor: 0 });
  const [retiros, setRetiros] = useState({ consultado: false, valor: 0 });

  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [total, setTotal] = useState([]);

  const [chart, setChart] = useState({
    nasqad: [],
    donwjones: [],
    sp500: [],
    btc: [],
  });

  const { getDataChartFinance } = useHome();

  const toggleChatSupport = () => {
    setOpenSupport(!openSupport);
  };

  const getDateFormat = (fechaString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const fecha = new Date(fechaString);
    const mesCapitalizado = fecha
      .toLocaleDateString("es-ES", options)
      .replace(/\b\w/g, (l) => l.toUpperCase())
      .replace(/(\d+) (\w+) (\d+)/, "$1 de $2 del $3");

    return mesCapitalizado;
  };

  const processGetContracts = async () => {
    const data = await getContracts();
    if (data.length > 0) {
      let total = 0;
      data.map((item) => {
        total += item.investment[0]?.amount - item.fee_admin;
      });
      setTotal(total);
    }
  };

  useEffect(() => {
    if (!user) navigate("/");
    if (!balance.consultado && user) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;
      axios
        .get(`${URL_API}/api/wallet`)
        .then((resp) => {
          if (resp.status >= 200 && resp.status <= 299) {
            setBalance({ consultado: true, valor: resp.data.wallet.balance });
          } else {
            //alert("Hubo un error");
          }
        })
        .catch((err) => {
          //alert(`Ha ocurrido un error: ${err}`);
        });
    }
    if (!utilidades.consultado && user) {
      setIsLoading1(true);
      axios.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;
      axios
        .get(`${URL_API}/api/settlements`)
        .then((resp) => {
          if (resp.status >= 200 && resp.status <= 299) {
            let totalAmountEarning = 0;
            resp.data.settlement.forEach((element) => {
              totalAmountEarning += element.amount_earning;
            });
            setUtilidades({ consultado: true, valor: totalAmountEarning ?? 0 });
          } else {
            //alert("Hubo un error");
          }
          setIsLoading1(false);
        })
        .catch((err) => {
          //alert(`Ha ocurrido un error: ${err}`);
          setIsLoading1(false);
        });
    }

    if (!retiros.consultado && user) {
      setIsLoading2(true);
      axios.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;
      axios
        .get(`${URL_API}/api/withdrawal`)
        .then((resp) => {
          if (resp.status >= 200 && resp.status <= 299) {
            let retirosTotal = 0;
            retirosTotal = resp.data.withdrawal
              .filter((a) => a.approvement == "APPROVED")
              .reduce(
                (a, b) => a.payment_amount_capital + b.payment_amount_capital
              ).payment_amount_capital;
            setRetiros({ consultado: true, valor: retirosTotal });
          } else {
            //alert("Hubo un error");
          }
          setIsLoading2(false);
        })
        .catch((err) => {
          //alert(`Ha ocurrido un error: ${err}`);
          setIsLoading2(false);
        });
    }

    if (user) {
      processGetContracts();
      BlogList(user?.token)
        .then((resp) => {
          if (resp.status >= 200 && resp.status <= 299) {
            const blogList = resp.data;
            const updatedArticles = blogList.map((item, i) => (
              <div
                key={item._id}
                onClick={() => navigate("/article", { state: item._id })}
                className="article"
              >
                <div style={{ padding: "5px" }}>
                  <span>
                    Publicado el{" "}
                    {item.publication_date
                      ? item.publication_date
                      : getDateFormat(item.createdAt)}
                  </span>
                  <h6>{item.title}</h6>
                  <p>{item.resume}</p>
                </div>
                <img
                  style={{ objectFit: "cover" }}
                  src={item.location_image}
                  alt=""
                />
              </div>
            ));
            setArticulos(updatedArticles);
          } else {
            //alert("Hubo un error");
          }
        })
        .catch((err) => {
          //alert(`Ha ocurrido un error: ${err}`);
        });
    }
  }, [navigate]);

  // Definir el tamaño de la página (en este caso, 3 elementos por página)
  const elementosPorPagina = 3;

  // Calcular el número total de páginas
  const numeroDePaginas = Math.ceil(articulos.length / elementosPorPagina);

  // Estado para almacenar la página actual
  const [paginaActual, setPaginaActual] = React.useState(1);

  // Obtener los elementos para la página actual
  const elementosPaginaActual = articulos.slice(
    (paginaActual - 1) * elementosPorPagina,
    paginaActual * elementosPorPagina
  );

  // Función para cambiar de página
  const cambiarPagina = (numeroPagina) => {
    setPaginaActual(numeroPagina);
  };

  const getChartDataFromYahooFinance = async () => {
    const response = await getDataChartFinance();
    if (response.length > 0) {
      try {
        const nasqad = response
          .filter((e) => e.currency === "Nasqad")
          ?.sort((a, b) => {
            return parseInt(a.timestamp) - parseInt(b.timestamp);
          });
        const downjones = response
          .filter((e) => e.currency === "DownJones")
          .sort((a, b) => {
            return parseInt(a.timestamp) - parseInt(b.timestamp);
          });
        const sp500 = response
          .filter((e) => e.currency === "SP500")
          .sort((a, b) => {
            return parseInt(a.timestamp) - parseInt(b.timestamp);
          });
        const btc = response
          .filter((e) => e.currency === "BTC")
          .sort((a, b) => {
            return parseInt(a.timestamp) - parseInt(b.timestamp);
          });
        setChart({
          nasqad: nasqad
            ? nasqad.map((e) => {
                return {
                  time: parseInt(e.timestamp),
                  value: parseFloat(e.closingPrice),
                };
              })
            : [],
          donwjones: downjones
            ? downjones.map((e) => {
                return {
                  time: parseInt(e.timestamp),
                  value: parseFloat(e.closingPrice),
                };
              })
            : [],
          sp500: sp500
            ? sp500.map((e) => {
                return {
                  time: parseInt(e.timestamp),
                  value: parseFloat(e.closingPrice),
                };
              })
            : [],
          btc: btc
            ? btc.map((e) => {
                return {
                  time: parseInt(e.timestamp),
                  value: parseFloat(e.closingPrice),
                };
              })
            : [],
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    getChartDataFromYahooFinance();
  }, []);

  return (
    <>
      <div className="right">
        <h1 style={{ marginLeft: "30px" }}>
          {t("home.title")} {user?.fullname}
        </h1>
        <div id="ze-snippet"></div>
        <div className="panelsB">
          <div className="bill">
            <div className="headerW">
              <div>
                <img src={totalIcon} alt="" />
                <span>{t("home.total")}</span>
              </div>
              <span>USD</span>
            </div>
            <div className="bodyW">
              <h6>$ {formatNumber(total)}</h6>
              <span>{t("home.total_invested")}</span>
            </div>
          </div>
          <div className="bill">
            <div className="headerW">
              <div>
                <img src={walletIcon} alt="" />
                <span>{t("home.balance")}</span>
              </div>
              <span>USD</span>
            </div>
            <div className="bodyW">
              <h6>$ {formatNumber(balance.valor)}</h6>
              <span>{t("home.available_balance")}</span>
              <span onClick={() => setOpen(!open)} className="under">
                {t("home.load_balance")}
              </span>
            </div>
          </div>
          <div className="bill">
            <div className="headerW">
              <div>
                <img src={utilitiesIcon} alt="" />
                <span>{t("home.utilities")}</span>
              </div>
              <span>USD</span>
            </div>
            <div className="bodyW">
              <h6>
                {isLoading1
                  ? "Loading..."
                  : `$ ${formatNumber(utilidades.valor)}`}
              </h6>
              <span>{t("home.profits_generated")}</span>
              <span className="under" onClick={() => navigate("/Utilidades")}>
                {t("home.view_history")}
              </span>
            </div>
          </div>
          <div className="bill">
            <div className="headerW">
              <div>
                <img src={retiroIcon} alt="" />
                <span>{t("home.withdrawals")}</span>
              </div>
              <span>USD</span>
            </div>
            <div className="bodyW">
              <h6>
                {isLoading2 ? "Loading..." : `$ ${formatNumber(retiros.valor)}`}
              </h6>
              <span>{t("home.withdrawals_made")}</span>
              <span className="under" onClick={() => navigate("/Retiros")}>
                {t("home.withdraw")}
              </span>
            </div>
          </div>
        </div>
        <div className="inversions-home">
          <div
            onClick={() => setpanel("1")}
            className={panel === "1" ? `itemI` : `item`}
          >
            <span>Nasdaq</span>
          </div>
          <div
            onClick={() => setpanel("2")}
            className={panel === "2" ? `itemI` : `item`}
          >
            <span>Dow Jones</span>
          </div>
          <div
            onClick={() => setpanel("3")}
            className={panel === "3" ? `itemI` : `item`}
          >
            <span>{"S&P 500"}</span>
          </div>
          <div
            onClick={() => setpanel("4")}
            className={panel === "4" ? `itemI` : `item`}
          >
            <span>BTC</span>
          </div>
        </div>
        <div
          className="panel"
          style={{ marginRight: "20px", marginTop: "10px" }}
        >
          {panel === "1"
            ? chart &&
              chart.nasqad.length > 0 && (
                <CABGraph
                  props={{
                    info: {
                      currencyName: "Nasdaq Composite",
                    },
                    data: chart.nasqad,
                    lineColor: "#F94144",
                    areaTopColor: "rgba(249,65,68, 0.28)",
                    areaBottomColor: "rgba(249,65,68, 0.28)",
                  }}
                ></CABGraph>
              )
            : // <img style={{ width: "100%" }} src={panel1} alt="" />
            panel === "2"
            ? chart &&
              chart.donwjones.length > 0 && (
                <CABGraph
                  props={{
                    info: {
                      currencyName: "Dow Jones Industrial Average",
                    },
                    data: chart.donwjones,
                    lineColor: "#7FC5ED",
                    areaTopColor: "rgba(127,197,237,0.28)",
                    areaBottomColor: "rgba(127,197,237, 0.28)",
                  }}
                ></CABGraph>
              )
            : // <img style={{ width: "100%" }} src={panel2} alt="" />
            panel === "3"
            ? chart &&
              chart.sp500.length > 0 && (
                <CABGraph
                  props={{
                    info: {
                      currencyName: "S&P 500",
                    },
                    data: chart.sp500,
                    lineColor: "#F4D06F",
                    areaTopColor: "rgba(244,208,111,0.28)",
                    areaBottomColor: "rgba(244,208,111,0.28)",
                  }}
                ></CABGraph>
              )
            : // <img style={{ width: "100%" }} src={panel3} alt="" />
              panel === "4" &&
              chart &&
              chart.btc.length > 0 && (
                <CABGraph
                  props={{
                    info: {
                      currencyName: "BTC-USD",
                    },
                    data: chart.btc,
                    lineColor: "#26C485",
                    areaTopColor: "rgba(38,196,133,0.28)",
                    areaBottomColor: "rgba(38,196,133,0.28)",
                  }}
                ></CABGraph>
                // <img style={{ width: "100%" }} src={panel4} alt="" />
              )}
        </div>
        <div className="articles">
          {elementosPaginaActual.map((elemento, index) => (
            <div key={index}>{elemento}</div>
          ))}
        </div>
        <div className="paginationContainer">
          <div className="pagination-btns">
            <button
              className="btn-pagination"
              disabled={paginaActual === 1}
              onClick={() => cambiarPagina(paginaActual - 1)}
            >
              {paginaActual === 1 && (
                <img src={arrowLeftDisable} alt="arrow button" />
              )}
              {paginaActual > 1 && <img src={arrowLeft} alt="arrow button" />}
            </button>
            {/* Generar los botones de paginación */}
            {Array.from({ length: numeroDePaginas }, (_, index) => (
              <button
                className={`btn-pagination ${
                  index + 1 === paginaActual ? "active" : ""
                }`}
                key={index}
                onClick={() => cambiarPagina(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className="btn-pagination"
              disabled={paginaActual === numeroDePaginas}
              onClick={() => cambiarPagina(paginaActual + 1)}
            >
              {paginaActual === numeroDePaginas && (
                <img src={arrowRightDisable} alt="arrow button" />
              )}
              {paginaActual < numeroDePaginas && (
                <img src={arrowRight} alt="arrow button" />
              )}
            </button>
          </div>
        </div>
      </div>
      <Helmet>
        <script
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`}
          async
        >
          {" "}
        </script>
      </Helmet>
      <ModalPay
        onOpen={setOpen}
        open={open}
        callback={() => {
          //processRequestContract();
          navigate("/load-balance");
        }}
      />
    </>
  );
};

export default Home;

//CLASES AUXILIARES (Funciones de uso general)

import { ENCRIPTION_KEY } from "../config/config";
import CryptoJS from "crypto-js";
import { CURRENCY_LOCATION } from "./constants";
// Location 'es-CO' should be change to 'en-US' if change the traduction language

export const formatNumber = (number) => {
  const truncatedNumber = Math.floor(number * 100) / 100;
  return new Intl.NumberFormat(CURRENCY_LOCATION).format(truncatedNumber ?? 0);
};

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString(CURRENCY_LOCATION);
};

export const formatLongDate = (date) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(date).toLocaleDateString(CURRENCY_LOCATION, options);
};

// Función para encriptar los datos de sesión
export function encriptarDatos(datos) {
  const datosString = JSON.stringify(datos);
  const clave = ENCRIPTION_KEY;

  return CryptoJS.AES.encrypt(datosString, clave).toString();
}

// Función para descifrar los datos de sesión
function descifrarDatos(datosEncriptados) {
  const clave = ENCRIPTION_KEY;
  const bytesDescifrados = CryptoJS.AES.decrypt(datosEncriptados, clave);
  const datosDescifradosString = bytesDescifrados.toString(CryptoJS.enc.Utf8);

  return JSON.parse(datosDescifradosString);
}

// Función para guardar la información de sesión en el localStorage
export const guardarSesion = (usuario) => {
  const datosEncriptados = encriptarDatos(usuario);

  const fechaExpiracion = new Date();
  fechaExpiracion.setDate(fechaExpiracion.getDate() + 1);

  const sesion = {
    datos: datosEncriptados,
    expiracion: fechaExpiracion.getTime(),
  };

  localStorage.setItem("sesionUsuario", JSON.stringify(sesion));
};

// Función para obtener y validar la información de sesión del localStorage
export const obtenerSesion = () => {
  const sesionGuardada = localStorage.getItem("sesionUsuario");

  if (sesionGuardada) {
    const sesion = JSON.parse(sesionGuardada);

    if (sesion.expiracion < Date.now()) {
      eliminarSesion();
      return null;
    }

    const datosDescifrados = descifrarDatos(sesion.datos);

    return datosDescifrados;
  }

  return null;
};

// Función para eliminar la información de sesión del localStorage
export const eliminarSesion = () => {
  localStorage.removeItem("sesionUsuario");
};

export const testPassword= (password) => {
  const regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]).{8,}$"
  );
  return regex.test(password);
};

export const validateEmail = (email) => {
  const regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return regex.test(email);
};

export function isPermitAge(birthday) {
  
  var actualDate = new Date();

  // Calcular la edad restando el año de nacimiento al año actual
  var age = actualDate.getFullYear() - birthday.getFullYear();

  // Verificar si el día y el mes de nacimiento ya han pasado este año
  if (
    birthday.getMonth() > actualDate.getMonth() ||
    (birthday.getMonth() === actualDate.getMonth() &&
      birthday.getDate() > actualDate.getDate())
  ) {
    age--;
  }

  // Verificar si la persona es mayor de edad (18 años o más)
  return age >= 18;
}
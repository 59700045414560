import "../Inversions/styles.scss";
import "./styles.scss";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Balance, TipoAccount } from "../../utils/constants";
import { useRetiro } from "./hooks/useRetiro";
import InputSelect from "../SignUp/InputSelect";
import ModalRetiro from "../../components/Modal/ModalRetiro";
import Loader from "../../components/loader/loader";
import Table from "./Table";
import { useTranslation } from "react-i18next";

import arrowBack from "../../assets/img/svg/back.svg";
import searchIcon from "../../assets/img/svg/searchIcon.svg";
import filtroIcon from "../../assets/img/svg/filtroIcon.svg";
import retiroIcon from "../../assets/img/svg/retiro.svg";

const Retiros = () => {
  const [t, i18n] = useTranslation("global");
  const { getWithdrawals, isLoading, downloadBill } = useRetiro();
  const [formulario, setFormulario] = useState({
    // State para informacion de usuario
    producto: "",
    transaccion: "",
    estado: "",
  });
  const navigate = useNavigate();
  const [panelF, setpanelF] = useState(false);
  const [open, setOpen] = useState(false);

  const [retiros, setRetiros] = useState([]);
  const [retirosOld, setRetirosOld] = useState([]);

  const handleSelectChange = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value,
    });
    searchOnTable(e.target.value);
  };

  const searchOnTable = (word) => {
    if (word) {
      setRetiros(
        retirosOld.filter((objeto) => {
          return Object.values(objeto).some((valor) => {
            if (valor)
              if (typeof valor == "object") {
                return Object.values(valor).some((objectValue) => {
                  return objectValue
                    .toString()
                    .toLowerCase()
                    .includes(word.toLowerCase());
                });
              } else {
                return valor
                  .toString()
                  .toLowerCase()
                  .includes(word.toLowerCase());
              }
          });
        })
      );
    } else {
      setRetiros(retirosOld);
    }
  };

  const processGetWithdrawals = async () => {
    const data = await getWithdrawals();
    if (data.length > 0) {
      setRetiros(data);
      setRetirosOld(data);
    }
  };

  useEffect(() => {
    processGetWithdrawals();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="ContainerCont retiro">
          <div onClick={() => navigate("/Home")} className="back">
            <img src={arrowBack} alt="" /> <span>{t("general.back")}</span>
          </div>
          <div className="header">
            <h1>{t("home.withdrawals")}</h1>
            <div onClick={() => setOpen(true)} className="btnR">
              <img src={retiroIcon} alt="" />
              <span>{t("investments.request_withdrawal")}</span>
            </div>
          </div>
          <div className="contratos">
            <div className="search">
              <div className="inputSearch">
                <img src={searchIcon} alt="" />
                <input
                  placeholder="Buscar"
                  type="text"
                  onChange={(e) => searchOnTable(e.target.value)}
                />
              </div>
              <div onClick={() => setpanelF(!panelF)} className="btnFiltro">
                <img src={filtroIcon} alt="" />
                <span>{t("general.filter")}</span>
              </div>
            </div>
            {panelF ? (
              <div className="filtros">
                <div className="espacio-input">
                  <InputSelect
                    form={formulario}
                    elements={Balance}
                    name="producto"
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {retiros && <Table data={retiros} />}
          </div>
          <ModalRetiro onOpen={setOpen} open={open} />
        </div>
      )}
    </>
  );
};

export default Retiros;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { TipoPort, Estado } from "../../utils/constants";
import { useContracts } from "./hooks/useContracts";

import InputSelect from "../SignUp/InputSelect";
import Loader from "../../components/loader/loader";
import Table from "./Table";

import searchIcon from "../../assets/img/svg/searchIcon.svg";
import filtroIcon from "../../assets/img/svg/filtroIcon.svg";

import "../Inversions/styles.scss";
import "../Retiros/styles.scss";

const Contratos = () => {
  const [t, i18n] = useTranslation("global");
  const { getContracts, isLoading } = useContracts();

  const [contracts, setContracts] = useState([]);
  const [contractsOld, setContractsOld] = useState([]);

  const [formulario, setFormulario] = useState({
    // State para informacion de usuario
    producto: "",
    transaccion: "",
    estado: "",
  });
  const navigate = useNavigate();
  const [panelF, setpanelF] = useState(false);
  const handleSelectChange = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value,
    });

    const filter = contractsOld.filter(
      (x) => x.status.toLowerCase() == e.target.value.toLowerCase()
    );
    setContracts(filter);
  };

  const searchOnTable = (word) => {
    if (word) {
      setContracts(
        contractsOld.filter((objeto) => {
          return Object.values(objeto).some((valor) => {
            if (valor)
              if (typeof valor == "object") {
                return Object.values(valor).some((objectValue) => {
                  return objectValue
                    .toString()
                    .toLowerCase()
                    .includes(word.toLowerCase());
                });
              } else {
                return valor
                  .toString()
                  .toLowerCase()
                  .includes(word.toLowerCase());
              }
          });
        })
      );
    } else {
      setContracts(contractsOld);
    }
  };

  const processGetContracts = React.useCallback(async () => {
    const data = await getContracts();
    if (data.length > 0) {
      setContracts(data.reverse());
      setContractsOld(data);
    }
  }, [getContracts, setContracts]);

  useEffect(() => {
    processGetContracts();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="ContainerCont">
          <div className="title">
            <span style={{marginLeft: '20px'}}>{t("withdrawals.contracts")}</span>
          </div>
          <div className="contratos">
            <div className="search">
              <div className="inputSearch">
                <img src={searchIcon} alt="" />
                <input
                  name="search"
                  placeholder="Buscar"
                  onChange={(e) => searchOnTable(e.target.value)}
                  type="text"
                />
              </div>
              <div
                onClick={() => {
                  setpanelF(!panelF);
                  setContracts(contractsOld);
                }}
                className="btnFiltro"
              >
                <img src={filtroIcon} alt="" />
                <span>{t("general.filter")}</span>
              </div>
            </div>
            {panelF && (
              <div className="filtros">
                <div className="espacio-input">
                  <InputSelect
                    form={formulario}
                    elements={TipoPort}
                    name="producto"
                    onChange={(e) => {
                      setFormulario({
                        ...formulario,
                        [e.target.name]: e.target.value,
                      });

                      const filter = contractsOld.filter(
                        (x) =>
                          x.descriptionContract.type.toLowerCase() ==
                          e.target.value.toLowerCase()
                      );
                      setContracts(filter);
                    }}
                  />
                </div>
                <div className="espacio-input">
                  <InputSelect
                    form={formulario}
                    elements={Estado}
                    name="producto"
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
            )}
            {contracts && <Table data={contracts} />}
          </div>
        </div>
      )}
    </>
  );
};

export default Contratos;

import { useContext } from "react";
import { GetContracts } from "../../Contratos/services.js";
import GeneralContext from "../../../context/GeneralContext";

export const useMyInversions = () => {
  const { user } = useContext(GeneralContext);
  const getMyInversions = async () => {
    try {
      const { data } = await GetContracts(user.token);
      const { contracts } = data;
      return contracts.length > 0 ? contracts.reverse() : [];
    } catch (error) {
      return [];
    }
  };

  return { getMyInversions };
};

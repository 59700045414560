import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import OTPInput from "../../components/OTPInput";
import { validateEmail } from "../../utils/Utils";
import { usePasswordRecovery } from "./hooks/usePasswordRecovery";
import CABInput from "../../components/CABInput";
import CABButton from "../../components/CABButton";

import logoTitle from "../../assets/img/svg/title-logo.svg";
import arrowBack from "../../assets/img/png/flecha-atras.png";
import eyeShow from "../../assets/img/png/ojo.png";
import eyeClose from "../../assets/img/svg/ojoClose.svg";

import "../../styles/auth.scss";

const PasswordRecovery = () => {
  const [t, _] = useTranslation("global");
  const navigate = useNavigate();

  const { recoverPassword, changePassword } = usePasswordRecovery();

  //FIRST STEP
  const [form, setForm] = useState({
    email: "",
  });
  const [validateForm, setValidateForm] = useState(null);
  const [hasErrorForm, setHasErrorForm] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isValid2, setIsValid2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const testForm = React.useCallback(() => {
    return form.email !== "" && validateEmail(form.email);
  }, [form]);

  useEffect(() => {
    setValidateForm(testForm());
  }, [form]);
  // FIN FIRST STEP

  //SECOND STEP
  const [formPassword, setFormPassword] = useState({
    // State para informacion de usuario
    code: "",
    password: "",
    confirm: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const [validateFormPass, setValidateFormPass] = useState(false);

  const [validatePin, setValidatePin] = useState(null);

  const testFormPass = React.useCallback(() => {
    return (
      formPassword.code !== "" &&
      formPassword.code.length === 4 &&
      formPassword.password !== "" &&
      formPassword.confirm !== ""
    );
  }, [formPassword]);

  const testMatchPassword = React.useCallback(() => {
    return formPassword.password === formPassword.confirm;
  });

  const verify = (e, confirm) => {
    const tieneMayuscula = /[A-Z]/.test(e);
    const tieneMinimoCaracteres = e.length >= 8;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(e);
    const sonIguales = formPassword.password === e;
    if (confirm) {
      if (tieneMayuscula && sonIguales && tieneMinimoCaracteres) {
        setIsValid2(false);
        setErrorMessage("");
      } else if (!tieneMayuscula) {
        setIsValid2(true);
        setErrorMessage(t("password_change.requirement_1"));
      } else if (!tieneMinimoCaracteres) {
        setIsValid2(true);
        setErrorMessage(t("password_change.requirement_3"));
      } else if (!hasSpecialChar) {
        setIsValid2(true);
        setErrorMessage(t("password_change.requirement_4"));
      } else {
        setIsValid2(true);
        setErrorMessage(t("password_change.requirement_2"));
      }
    } else {
      if (!tieneMayuscula) {
        setIsValid(true);
        setErrorMessage(t("password_change.requirement_1"));
      } else if (!tieneMinimoCaracteres) {
        setIsValid(true);
        setErrorMessage(t("password_change.requirement_3"));
      } else if (!hasSpecialChar) {
        setIsValid(true);
        setErrorMessage(t("password_change.requirement_4"));
      } else {
        setIsValid2(false);
        setErrorMessage("");
      }
    }
  };
  useEffect(() => {
    setValidateFormPass(testFormPass() && testMatchPassword);
  }, [formPassword]);

  //FIN SECOND STEP

  const [step, setStep] = useState(1);

  const changeStep = async () => {
    if (step === 1) {
      const response = await recoverPassword(form.email);
      if (response) {
        setStep(2);
        return;
      }
      setHasErrorForm(true);
    }

    if (step === 2) {
      const params = {
        email: form.email,
        code: formPassword.code,
      };
      const body = {
        password: formPassword.password,
        confirmPassword: formPassword.confirm,
      };
      const response = await changePassword(params, body);
      if (response) {
        navigate("/");
        setValidatePin(true);
        return;
      }
      setValidatePin(false);
      setValidateFormPass(false);
    }
  };

  return (
    <div className="container vector-right">
      <div className="left-column">
        <div className="container-form">
          <form onSubmit={(e) => e.preventDefault()} className="form">
            <div className="logo">
              <img src={logoTitle} className="titulo-img" alt="titulo"></img>
            </div>
            <div onClick={() => navigate("/")} className="back_title">
              <img src={arrowBack} className="arrow-back"></img>
              <p className="titulo-1">{t("password_recovery.title_form")}</p>
            </div>
            <p className="titulo-2">
              {t("password_recovery.description_form")}
            </p>

            {step === 1 ? (
              <>
                <CABInput
                  label={t("password_recovery.email")}
                  error={hasErrorForm}
                  errorMessage="Lo sentimos algo no ha salido bien, por favor inténtelo más tarde o
                contacte al administrador"
                  type="email"
                  placeholder={t("signup.write_here")}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />

                <CABButton
                  disabled={!validateForm}
                  label={t("password_recovery.send")}
                  onClick={() => changeStep()}
                />
              </>
            ) : (
              <></>
            )}

            {step === 2 ? (
              <>
                <OTPInput
                  length={4}
                  label="Código de verificación *"
                  validatePin={validatePin}
                  onChange={(value) => {
                    let otpJoined = Object.values(value).join("");
                    setFormPassword({
                      ...formPassword,
                      code: otpJoined,
                    });
                  }}
                />

                <CABInput
                  label="Nueva contraseña *"
                  type={showPassword ? "text" : "password"}
                  placeholder={t("signup.write_here")}
                  rightIcon={
                    <img
                      src={showPassword ? eyeShow : eyeClose}
                      alt="ojo"
                    ></img>
                  }
                  onRightIconClick={() => setShowPassword(!showPassword)}
                  onChange={(e) =>{
                    setFormPassword({
                      ...formPassword,
                      password: e.target.value,
                    })
                    verify(e.target.value, false)
                  }}
                />
                {isValid && <p className="texto-error">{errorMessage}</p>}

                <CABInput
                  label="Confirmación nueva contraseña *"
                  type={showConfirmPass ? "text" : "password"}
                  placeholder="Escribe aquí"
                  rightIcon={
                    <img
                      src={showConfirmPass ? eyeShow : eyeClose}
                      className="ojo"
                      alt="ojo"
                    ></img>
                  }
                  onRightIconClick={() => setShowConfirmPass(!showConfirmPass)}
                  onChange={(e) =>{
                    setFormPassword({
                      ...formPassword,
                      confirm: e.target.value,
                    })
                    verify(e.target.value, true)
                  }}
                />
                {isValid2 && <p className="texto-error">{errorMessage}</p>}
                <CABButton
                  disabled={!validateFormPass}
                  label={t("password_recovery.send")}
                  onClick={() => changeStep()}
                />
              </>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>

      <div className="right-column">
        <div>
          <p className="titulo-1">{t("password_recovery.title")}</p>
          <p className="titulo-2">{t("password_recovery.description")}</p>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecovery;

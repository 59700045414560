import React, { useState, useRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

function OTPInput({ length, onChange, validatePin, label }) {
  const [t, _] = useTranslation("global");

  const [otp, setOTP] = useState([...Array(length).fill("")]);

  const inputRefs = useMemo(
    () =>
      Array(length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const [input, setInput] = useState("input_otp"); // State para className de input

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (isNaN(value) || value.length > 1) {
      return;
    }

    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (index < length - 1 && value !== "") {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleInputPaste = (e, index) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text/plain").trim();

    if (pasteData.length !== length - index) {
      return;
    }

    const newOTP = [...otp];
    for (let i = index; i < index + pasteData.length; i++) {
      if (!isNaN(pasteData[i - index])) {
        newOTP[i] = pasteData[i - index];
      }
    }

    setOTP(newOTP);
    for (let i = index; i < index + pasteData.length; i++) {
      inputRefs[i].current.value = newOTP[i];
    }
  };

  const handleInputBackspace = (e, index) => {
    if (e.key === "Backspace" && index > 0 && e.target.value === "") {
      const newOTP = [...otp];
      newOTP[index - 1] = "";
      setOTP(newOTP);
      inputRefs[index - 1].current.focus();
    }
  };

  useEffect(() => {
    if (!validatePin) setInput("input_error_otp");
    if (validatePin == null) setInput("input_otp");
  }, [validatePin]);

  useEffect(() => {
    onChange(otp);
    let otpJoined = Object.values(otp).join("");
    if (otpJoined.length === length) {
      setInput("input_success_otp");
      return;
    }
    setInput("input_otp");
  }, [otp]);

  return (
    <div style={{ alignSelf: "center" }}>
      <p className="titulo-2">{label}</p>
      <div className="espacio-input-codigo">
        {otp.map((digit, index) => (
          <input
            className={input}
            key={index}
            type="text"
            value={digit}
            onChange={(e) => handleInputChange(e, index)}
            onPaste={(e) => handleInputPaste(e, index)}
            onKeyDown={(e) => handleInputBackspace(e, index)}
            ref={inputRefs[index]}
            maxLength="1"
          />
        ))}
      </div>
      {validatePin != null && !validatePin && (
        <p className="text-error">{t("login.wrong_code")}</p>
      )}
    </div>
  );
}

export default OTPInput;

import axios from "axios";
import { URL_API } from "../../config/config";
const API_URL = `${URL_API}/api`;

export const GetInboxMessages = (id, token) => {
  try {
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["Access-Control-Allow-Headers"] =
      "Origin, X-Requested-With, Content-Type, Accept";
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.get(`${API_URL}/inbox/` + id);
  } catch (error) {
    return Promise.reject("Catch " + JSON.stringify(error));
  }
};

export const MessagesRead = (id, token) => {
  try {
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["Access-Control-Allow-Headers"] =
      "Origin, X-Requested-With, Content-Type, Accept";
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.put(`${API_URL}/inbox/` + id);
  } catch (error) {
    return Promise.reject("Catch " + JSON.stringify(error));
  }
};
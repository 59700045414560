import React from 'react';

function Pagination() {
  // Supongamos que tienes una lista de elementos
  const elementos = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  // Definir el tamaño de la página (en este caso, 3 elementos por página)
  const elementosPorPagina = 3;

  // Calcular el número total de páginas
  const numeroDePaginas = Math.ceil(elementos.length / elementosPorPagina);

  // Estado para almacenar la página actual
  const [paginaActual, setPaginaActual] = React.useState(1);

  // Obtener los elementos para la página actual
  const elementosPaginaActual = elementos.slice(
    (paginaActual - 1) * elementosPorPagina,
    paginaActual * elementosPorPagina
  );

  // Función para cambiar de página
  const cambiarPagina = (numeroPagina) => {
    setPaginaActual(numeroPagina);
  };

  return (
    <div>
      <h1>Elementos</h1>
      <div>
        {elementosPaginaActual.map((elemento) => (
          <div key={elemento}>{elemento}</div>
        ))}
      </div>
      <div>
        {/* Generar los botones de paginación */}
        {Array.from({ length: numeroDePaginas }, (_, index) => (
          <button key={index + 1} onClick={() => cambiarPagina(index + 1)}>
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Pagination;
import React, { useEffect } from "react";
import InversionItem from "./inversionItem";
import { usePortfolios } from "./hooks/usePortfolios";
import Loader from "../../components/loader/loader";
import Modal from "../../components/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Inversions = () => {
  const [t, _] = useTranslation("global");
  // const navigate = useNavigate();
  const { portfolios, isLoading } = usePortfolios();
  const [openModal, setOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState({
    title: "",
    description: "",
    type: 0,
  });

  const handleOpenModal = (flag) => {
    if (flag) {
      setMessageModal({
        title: t("general.success_title"),
        description: t("general.description_success"),
        type: "2",
      });
    }
    if (!flag) {
      setMessageModal({
        title: t("general.title_error"),
        description: t("general.error_message"),
        type: "1",
      });
    }

    setOpenModal(true);
  };

  return (
    <>
      <Modal
        type={messageModal.type}
        btnlabel={t("general.accept")}
        onConfirm={() => {
          setOpenModal(!openModal);
        }}
        onOpen={setOpenModal}
        open={openModal}
        title={messageModal.title}
        text={messageModal.description}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="card-list">
          {portfolios?.filter((p) => p.status === 'Habilitado').map((p) => (
          <InversionItem
            handleModal={handleOpenModal}
            Portfolio={p}
            key={p._id}
          />
        ))}
        </div>
      )}
    </>
  );
};
export default Inversions;

import alert from "../../assets/img/svg/alert.svg";
import success from "../../assets/img/svg/sucess.svg";
import error from "../../assets/img/svg/error.svg";

import "./styles.scss";
const Modal = ({
  title,
  text,
  onOpen,
  open,
  type,
  cancel,
  onConfirm,
  btnlabel,
}) => {
  return (
    <div className={open ? `modal` : `hidden`}>
      <div className="content">
        <div className="Header">
          <img
            src={type === "1" ? alert : type === "2" ? success : error}
            alt=""
          />
          <span>{title}</span>
        </div>
        <p>{text}</p>
        {cancel ? (
          <div className="btns">
            <div
              onClick={() => {
                onOpen(!open);
              }}
            >
              <span>Cancelar</span>
            </div>
            <div
              onClick={() => {
                onOpen(!open);
                onConfirm && onConfirm();
              }}
            >
              <span>Aceptar</span>
            </div>
          </div>
        ) : (
          <div
            className="btn"
            onClick={() => {
              onOpen(!open);
              onConfirm && onConfirm();
            }}
          >
            <span>{btnlabel ? btnlabel : "Aceptar"}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;

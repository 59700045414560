import { useContext, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import Modal from "../../components/Modal";

import { useContracts } from "./hooks/useContracts";
import Loader from "../../components/loader/loader";
import GeneralContext from "../../context/GeneralContext";
import { useTranslation } from "react-i18next";
import Notification from "../../components/Notification/Notification";

import arrowBack from "../../assets/img/png/flecha-atras.png";
import infoBIcon from "../../assets/img/svg/infoB.svg";

import "../../components/Firma/styles.scss";

const FileCobro = () => {
  const { uploadDocumentSigned } = useContracts();
  const { state } = useLocation();
  const { user } = useContext(GeneralContext);
  const [FileD, setFile] = useState("");
  const [ClickFile, setClickFile] = useState("");
  const [FileO, setFileO] = useState("");
  const navigate = useNavigate();
  const fileInput = useRef(null);
  const fileInputO = useRef(null);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [t, _] = useTranslation("global");
  const [notification, setNotification] = useState(null);
  const showNotification = (message, description, type) => {
    setNotification({ message, description, type });
  };
  const closeNotification = () => {
    setNotification(null);
  };
  const handleClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };
  const handleFile = (file) => {
    setFile(file.target?.files[0]);
  };
  const handleClickO = () => {
    if (fileInputO.current) {
      fileInputO.current.click();
    }
  };
  const handleFileO = (file) => {
    setFileO(file.target?.files[0]);
  };
  function abrirDocumento() {
    if (FileD) {
      var url = URL.createObjectURL(FileD);
      window.open(url, "_blank");
    }
  }
  const processUploadDoc = async () => {
    setIsLoading(true);
    const { SignNowResponse } = await uploadDocumentSigned(
      state.idDocumentSignNow
    );
    const status = SignNowResponse?.field_invites[0]?.status;
    if (status === "fulfilled") {
      setOpen2(true);
    } else {
      showNotification(
        t("invest.validate_contract.failed_notification_title"),
        t("invest.validate_contract.failed_notification_description"),
        "error"
      );
    }
    if (false) setOpen2(true);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {notification && (
            <Notification
              message={notification?.message}
              type={notification?.type}
              description={notification?.description}
              onClose={closeNotification}
            />
          )}
          <div className="balance containerCon">
            <div
              onClick={() => navigate("/Home", { state: "3" })}
              className="back"
            >
              <img src={arrowBack} alt="back" />
              <span>{t("general.back")}</span>
            </div>
            <div className="right">
              <div className="description">
                {!state.success && (
                  <>
                    <span>
                      {t(
                        "contracts.pending_document_to_sign"
                      )}
                    </span>
                    <br />
                  </>
                )}
              </div>
              <div className="titleSeccion">
                <span>{t("charge_account_request.title")}</span>
              </div>
              <div className="panelInfo">
              <img src={infoBIcon} alt="" />
                <p>
                  {t("invest.validate_contract.message_email_1")}{" "}
                  <span>{user?.email}</span>{" "}
                  {t("invest.validate_contract.message_email_2")}
                </p>
              </div>
              <div
                onClick={() => {
                  processUploadDoc();
                }}
                className={`btnSend`}
              >
                <span>{t("invest.validate_contract.btn_message")}</span>
              </div>
            </div>
            <Modal
              type="2"
              onConfirm={() => navigate("/Home", { state: "3" })}
              onOpen={setOpen2}
              open={open2}
              title={"¡Se ha enviado tu solicitud con éxito!"}
              text={
                "El certificado lo recibirás a tu correo electrónico 24 horas después de realizada la solicitud."
              }
            />
            <Modal
              type="1"
              onConfirm={() => {
                if (ClickFile === "1") {
                  setFile("");
                } else {
                  setFileO("");
                }
                setOpen(false);
              }}
              cancel
              onOpen={setOpen}
              open={open}
              title={"¿Estás seguro de eliminar el archivo?"}
              text={
                "Si eliminas el archivo, este no se recuperará y tendrás que adjuntar nuevamente un documento."
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default FileCobro;

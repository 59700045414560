import { useEffect, useState, useContext } from "react";
import {
  GetPortfoliosLabels
} from "../services";
import GeneralContext from "../../../context/GeneralContext";

export const usePortfolioLabels = () => {
  const { user } = useContext(GeneralContext);
  const [portFoliosLabels, setPortfoliosLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getPortfoliosLabels = async () => {
    setIsLoading(true);
    try {
      const { data } = await GetPortfoliosLabels(user.token)
      setPortfoliosLabels(data);
    } catch (error) {}
    setIsLoading(false);
    
  }

  useEffect(() => {
    getPortfoliosLabels();
  }, []);

  return {
    portFoliosLabels,
    isLoading
  }
}
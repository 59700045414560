import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/Utils";
import { useSign } from "./hooks/useSign";
import { NumericFormat } from "react-number-format";
import Modal from "../Modal";
import ModalPay from "../Modal/ModalPay";
import Loader from "../loader/loader";
import { usePortfolioLabels } from "../../pages/Inversions/hooks/usePortfolioLabels";

import arrowBack from "../../assets/img/svg/back.svg";
import icon from "../../assets/img/svg/backMoney.svg";
import icon1 from "../../assets/img/svg/backMoney1.svg";
import icon2 from "../../assets/img/svg/backMoney2.svg";
import icon4 from "../../assets/img/svg/backMoney4.svg";
import icon5 from "../../assets/img/svg/backMoney5.svg";
import icon6 from "../../assets/img/svg/backMoney6.svg";

import "./styles.scss";

const Firmar = () => {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [translationList, setTranslationList] = useState();
  const { portFoliosLabels, isLoading: isLoadingPortfolios } = usePortfolioLabels([]);

  const [totalAmount, setTotalAmount] = useState(0);

  const { state } = useLocation();
  const {
    type,
    minimun_amount_investment,
    cost_effectiveness,
    risk,
    minimum_expiration_time,
    payment_period_cost_effectiveness,
    template_number,
    fee_admin_percentage,
    maximum_amount_investment,
    color_card,
    icon_card,
    status,
    extra_values,
    document
  } = state;
  const { generateContract, getBalanceUser } = useSign();

  const typePortfolio = [
    { type: "Renta Fija", color: "#FCF0D2", icon: icon },
    {
      type: "Renta Fija premium",
      color: "#ebf5c9",
      icon: icon1,
    },
    { type: "Renta Variable", color: "#c7e4f5", icon: icon2 },
    { type: "Renta Cripto", color: "#d8dbdd", icon: icon4 },
    { type: "OTC", color: "#98D2A9", icon: icon5 },
    {
      type: "hecho a tu medida",
      color: " #F4D06F",
      icon: icon6,
    },
  ];

  const setIcon = () => {
    if (icon_card == 'diamond') {
      return icon1
    } else if (icon_card == 'graph') {
      return icon2
    } else if (icon_card == 'btc') {
      return icon4
    } else if (icon_card == 'line-dollar') {
      return icon5
    } else if (icon_card == 'global-dollar') {
      return icon6
    } else {
      return icon
    }
  }
  const processRequestContract = async () => {
    setIsLoading(true);
    const totalAmount = parseInt(amount.replace(",", ""))
    const feeAdmin = (amount.replace(",", "") * fee_admin_percentage) / 100;
    const { success } = await generateContract(
      totalAmount,
      feeAdmin,
      template_number
    );
    if (!success) {
      setOpen2(true);
      setIsLoading(false);
    }
    setOpen3(false);
    setIsLoading(false);
  };

  const validateBalance = async () => {
    const { success, wallet } = await getBalanceUser();
    if (!success || !wallet) setOpen4(true);
    if (success && wallet) {
      const { balance } = wallet;
      balance > minimun_amount_investment
        ? processRequestContract()
        : setOpen4(true);
    }
  };

  const [validButton, setValidButton] = useState(false);
  useEffect(() => {
    if (!amount) {
      setValidButton(false);
      return;
    }
    const amountWithoutSemicolon = amount.replaceAll(",", "");
    if (
      parseInt(amountWithoutSemicolon) < minimun_amount_investment
    ) {
      setValidButton(false);
      return;
    }
    if (
      parseInt(amountWithoutSemicolon) > maximum_amount_investment
    ) {
      setValidButton(false);
      return;
    }
    setValidButton(true);
  }, [amount]);

  useEffect(() => {
    if (!isLoading && portFoliosLabels.length > 0) {
      const lowerCaseExtraValues = Object.fromEntries(
        Object.entries(extra_values).map(([key, value]) => [
          key.toLowerCase().trim(),
          value
        ])
      );
  
      const tempList = portFoliosLabels.reduce((acc, curr) => {
        const lowerCaseAlias = curr.alias.toLowerCase().trim();
        if (lowerCaseExtraValues[lowerCaseAlias]) {
          acc.push({
            es_label: curr.es,
            en_label: curr.en,
            ...lowerCaseExtraValues[lowerCaseAlias]
          });
        }
        return acc;
      }, []);
      setTranslationList(tempList);
    }
  }, [extra_values, isLoading, portFoliosLabels]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="mobil">
            <div
              onClick={() => navigate("/Home", { state: "2" })}
              className="back"
            >
              <img src={arrowBack} alt="" />
              <span>{t("general.back")}</span>
            </div>
            <div className="title">
              <span>{t("invest.title")}</span>
            </div>
            <div className="description">
              <span>
                {t("invest.description")} {type}:
              </span>
            </div>
            <div className="form">
              <div className="espacio-input">
                <p className="titulo-2">{t("invest.label_input")}</p>
                <span className="dolar">USD</span>
                <NumericFormat
                  placeholder="$1’000.0000"
                  className="input"
                  value={amount}
                  thousandSeparator=","
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
                {/* <span className="dolar">USD</span>
                <input
                  type="number"
                  value={amount}
                  className="input"
                  placeholder="$1’000.0000"
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                ></input> */}
              </div>
            </div>
            <div
              onClick={(e) => {
                validButton && setOpen(true);
              }}
              className={`btnSend ${!validButton && "btnDisable"}`}
            >
              <span>{t("investments.invest")}</span>
            </div>
            <div className="banks">
              <div
                className={`bank ${color_card}`}
              >
                <div className="bankTitle">
                  <img
                    width="40"
                    height="40"
                    src={setIcon()}
                    alt=""
                  />
                  <span>
                    {t("investments.inversion_item.portfolio")} {type}
                  </span>
                </div>
                <div className="bankD">
                  <span>{t("investments.investment_minimum")}</span>
                  <p>$ {formatNumber(minimun_amount_investment)}</p>
                </div>
                <div className="bankD">
                  <span>{t("invest.management_percentage")}</span>
                  <p>{fee_admin_percentage} %</p>
                </div>
                <div className="bankD">
                  <span>{t("investments.investment_maximum")}</span>
                  <p>$ {formatNumber(maximum_amount_investment)} USD</p>
                </div>
                <div className="bankD">
                  <span>{t("investments.cost_effectiveness")}</span>
                  <p>{cost_effectiveness}</p>
                </div>
                <div className="bankD">
                  <span>{t("investments.risk")}</span>
                  <p>{risk}</p>
                </div>
                <div className="bankD">
                  <span>{t("investments.contract_expiration")}</span>
                  <p>
                    {Math.round(minimum_expiration_time / 30)}{" "}
                    {t("investments.months")}
                  </p>
                </div>
                <div className="bankD">
                  <span>{t("investments.pay_profitability")}</span>
                  <p>{payment_period_cost_effectiveness}</p>
                  {translationList && translationList.map((label, index) => {
                    if (translationList.length === 1 || index >= translationList.length / 2) {
                      return (
                        <React.Fragment key={index}>
                          <span>{i18n.language === 'es' ? label.es_label : label.en_label}</span>
                          <p className="extra-long-word">{i18n.language === 'es' ? label.es : label.en}</p>
                        </React.Fragment>
                      );
                    }
                  })}
                </div>
                <div className="bankD">
                  <span>{t("investments.pay_profitability")}</span>
                  <p>{payment_period_cost_effectiveness}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="desktop">
            <div className="balanceBank">
              <div className="left">
                <div
                  onClick={() => navigate("/Home", { state: "2" })}
                  className="back"
                >
                  <img src={arrowBack} alt="" />
                  <span>{t("general.back")}</span>
                </div>
                <div className="titles">
                  <div className="title">
                    <span>{t("invest.title")}</span>
                  </div>
                  <div className="description">
                    <span>{t("invest.description")}</span>
                  </div>
                </div>
                <div className="banks">
                  <div
                    className={`bank ${color_card}`}
                  >
                    <div className="bankTitle">
                      <img
                        width="40"
                        height="40"
                        src={setIcon()}
                        alt=""
                      />
                      <span>
                        {t("investments.briefcase")} {type}
                      </span>
                    </div>
                    <div className="bankD">
                      <span>{t("investments.investment_minimum")}</span>
                      <p>$ {formatNumber(minimun_amount_investment)}</p>
                    </div>
                    <div className="bankD">
                      <span>{t("invest.management_percentage")}</span>
                      <p>{fee_admin_percentage} %</p>
                    </div>
                    <div className="bankD">
                      <span>{t("investments.investment_maximum")}</span>
                      <p>$ {formatNumber(maximum_amount_investment)} USD</p>
                    </div>
                    <div className="bankD">
                      <span>{t("investments.cost_effectiveness")}</span>
                      <p>{cost_effectiveness}</p>
                    </div>
                    <div className="bankD">
                      <span>{t("investments.risk")}</span>
                      <p>{risk}</p>
                    </div>
                    <div className="bankD">
                      <span>{t("investments.contract_expiration")}</span>
                      <p>
                        {Math.round(minimum_expiration_time / 30)}{" "}
                        {t("investments.months")}
                      </p>
                    </div>
                    <div className="bankD">
                      <span>{t("investments.pay_profitability")}</span>
                      <p>{payment_period_cost_effectiveness}</p>
                    </div>
                    <div className="bankD">
                      {translationList && translationList.map((label, index) => {
                        if (translationList.length === 1 || index >= translationList.length / 2) {
                          return (
                            <React.Fragment key={index}>
                              <span>{i18n.language === 'es' ? label.es_label : label.en_label}</span>
                              <p className="extra-long-word">{i18n.language === 'es' ? label.es : label.en}</p>
                            </React.Fragment>
                          );
                        }
                      })}
                    </div>
                    <div className="bankD">
                      <span>{t("investments.preview")}</span>
                      <p>
                        <a
                          className="contract-link"
                          target="_blank"
                          href={document?.file_pdf} rel="noreferrer"
                        >{t("investments.contract")}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="title">
                  <span>{t("invest.message_enter_value_to_invest")} {type}</span>
                </div>
                <div className="form">
                  <div className="espacio-input">
                    <p className="titulo-2">{t("invest.label_input")}</p>
                    <span className="dolar">USD</span>
                    <NumericFormat
                      placeholder="$1’000.0000"
                      className="input"
                      value={amount}
                      thousandSeparator=","
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div
                  onClick={(e) => {
                    validButton && setOpen(true);
                  }}
                  className={`btnSend ${!validButton && "btnDisable"}`}
                >
                  <span>{t("investments.invest")}</span>
                </div>
              </div>
            </div>
          </div>
          <Modal
            type="1"
            className="custom-zise-modal"
            onConfirm={() => validateBalance()}
            onOpen={setOpen}
            open={open}
            cancel
            title={t("invest.modal.success_title")}
            text={`${t("invest.modal.success_description_1")} ${amount} ${t("invest.modal.success_description_1_1")} 
            ${formatNumber(
              parseInt(amount.replace(",", "")) -
                parseInt((amount.replace(",", "") * fee_admin_percentage) / 100)
            )} ${t("invest.modal.success_description_2")}`}
          />
          <Modal
            type="3"
            onOpen={setOpen2}
            open={open2}
            title={`${t("invest.modal.failed_title")}`}
            text={t("invest.modal.failed_description_1")}
          />
          <Modal
            type="3"
            onOpen={setOpen4}
            open={open4}
            title={`${t("invest.modal.failed_title")}`}
            text={t("invest.modal.failed_description_2")}
          />
          <ModalPay
            onOpen={setOpen3}
            open={open3}
            callback={() => {
              processRequestContract();
              /// navigate("/uploadSign");
            }}
          />
        </>
      )}
    </>
  );
};

export default Firmar;

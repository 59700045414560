import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";

import BussinessInformation from "./BussinessInformation";
import CreatePassword from "./CreatePassword";
import GeneralInformation from "./GeneralInformation";
import GeneralContext from "../../context/GeneralContext";
//TODO organizar clases Flex-Box de forma genereal, optimizar clases reutilizadas
import TermsAndCondition from "./TermsAndConditions";
import { useTranslation } from "react-i18next";
import { Nationalitys, TipoDoc, TipoPersona } from "../../utils/constants";

import logoTitle from "../../assets/img/svg/title-logo.svg";
import arrowBack from "../../assets/img/png/flecha-atras.png";
import checkSignUpBlue from "../../assets/img/svg/check-signUp-blue.svg";
import checkSignUp from "../../assets/img/svg/check-signUp.svg";

import "./SignUp.scss";

const SignUp = () => {
  const [t, _] = useTranslation("global");
  //Context
  const GC = useContext(GeneralContext);
  const { user } = GC;
  const [formulario, setFormulario] = useState({
    //GENERAL INFORMATION
    firstName: "",
    lastName: "",
    identification_number: "",
    birthday: "",
    client_type: TipoPersona[0].option,
    identification_type: TipoDoc[0].value,
    email: "",
    code_phone: "57",
    phone_number: "",
    nationality: Nationalitys[0].value,
    occupation: "",
    employer_name: "",
    //BUSINESS INFORMATION
    reason_for_user_CAB: "",
    source_of_funds_and_wealth: "",
    estimated_annual_gross_income: "",
    estimated_net_worth: "",
    anticipated_deposit_amount: "",
    //CREATE PASSWORD
    password: "",
    confirmPassword: "",
    company_name: "",
    legal_representative_name: "",
    id_legal_representative_name: "",
    nit: "",
  });
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [codigo] = useState({
    digito1: "",
    digito2: "",
    digito3: "",
    digito4: "",
    digito5: "",
  }); // State para codigo de acceso

  const [terminosYCondiciones, setTerminosYCondiciones] = useState({
    terminos: false,
    tratamiento: false,
  });

  const [opcionPago] = useState(null);

  const [paso, setPaso] = useState(1); // State para asignacion de paso de registro
  const [boton, setBoton] = useState("boton-continuar-disabled"); // State para claseName de boton "Continuar"
  //const [input, setInput] = useState("input") // State para className de input

  //TODO Establecer comprobacion general
  const comprobarFormulario = React.useCallback(() => {
    return (
      formulario.empresa !== "" &&
      formulario.correo !== "" &&
      formulario.password !== "" &&
      formulario.confirmPassword !== ""
    );
  }, [formulario]);

  //TODO Establecer comprobacion general
  const comprobarCodigo = React.useCallback(() => {
    return (
      codigo.digito1 === "1" &&
      codigo.digito2 === "2" &&
      codigo.digito3 === "3" &&
      codigo.digito4 === "4" &&
      codigo.digito5 === "5"
    );
  }, [codigo]);

  //TODO Establecer comprobacion general
  const comprobarTerminos = React.useCallback(() => {
    return terminosYCondiciones.terminos && terminosYCondiciones.tratamiento;
  }, [terminosYCondiciones]);

  //TODO Establecer comprobacion general
  const comprobarPago = React.useCallback(() => {
    return opcionPago !== null;
  }, [opcionPago]);

  const cambiarPaso = (paso) => {
    if (paso) {
      setPaso(paso);
    } else if (paso === 4) {
      setPaso(4);
      setTerminosYCondiciones({ terminos: false, tratamiento: false });
    }
  };

  //TODO Validacion de sesion activa
  // Cambio en tiempo real para className boton "Continuar" e input
  useEffect(() => {
    if (user && user.terms_conditions) navigate("/Home");
    if (comprobarFormulario()) {
      setBoton("boton-continuar-enabled");
    } else if (comprobarCodigo()) {
      //setInput("input-success")
      setBoton("boton-continuar-enabled");
    } else if (comprobarTerminos()) {
      setBoton("boton-continuar-enabled");
    } else if (comprobarPago()) {
      setBoton("boton-continuar-enabled");
    } else {
      if (boton !== "boton-continuar-disabled") {
        setBoton("boton-continuar-disabled");
      }
      //if(!comprobarCodigo() && codigo.digito1 !== "" &&  codigo.digito2 !== "" && codigo.digito3 !== "" && codigo.digito4 !== "" && codigo.digito5 !== "")
      //setInput("input-error")
    }
  }, [
    boton,
    setBoton,
    formulario,
    codigo,
    comprobarCodigo,
    comprobarFormulario,
    comprobarTerminos,
    comprobarPago,
    navigate,
    user,
  ]);

  const back = () => {
    if (paso !== 1) {
      setPaso(paso - 1);
    } else {
      navigate("/");
    }
  };
  return (
    <div className="general-bodys">
      <aside className="componente-mitad-left">
        <img className="logo-register" src={logoTitle} alt="CABCAPITAL" />
        <div onClick={paso !== 4 ? () => back() : null} className="espacio-subtitulo">
          {paso !== 4 &&
            <img src={arrowBack} className="flecha-atras" alt="flecha-atras" />
          }
          <p className="titulo-select">{t("signup.title")}</p>
        </div>
        <div className="steps-signUp">
          <div className="step">
            <img src={`${paso === 1 ? checkSignUpBlue : checkSignUp}`} alt="" />{" "}
            <p className={`${paso === 1 ? "titulo-select" : "titulo-1"}`}>
              {t("signup.step_1")}
            </p>
          </div>
          <div className="step">
            <img src={`${paso === 2 ? checkSignUpBlue : checkSignUp}`} alt="" />{" "}
            <p className={`${paso === 2 ? "titulo-select" : "titulo-1"}`}>
              {t("signup.step_2")}
            </p>
          </div>
          <div className="step">
            <img src={`${paso === 3 ? checkSignUpBlue : checkSignUp}`} alt="" />{" "}
            <p className={`${paso === 3 ? "titulo-select" : "titulo-1"}`}>
              {t("signup.step_3")}
            </p>
          </div>
          <div className="step">
            <img src={`${paso === 4 ? checkSignUpBlue : checkSignUp}`} alt="" />{" "}
            <p className={`${paso === 4 ? "titulo-select" : "titulo-1"}`}>
              {t("signup.step_4")}
            </p>
          </div>
        </div>
      </aside>
      <div className="componente-mitad-SignUp">
        <div className="mobile-signup">
          {paso === 1 && (
            <>
              <img className="logo-register" src={logoTitle} alt="CABCAPITAL" />
              <div onClick={() => back()} className="espacio-subtitulo">
                <img
                  src={arrowBack}
                  className="flecha-atras"
                  alt="flecha-atras"
                ></img>
                <p className="titulo-select">{t("signup.title")}</p>
              </div>
            </>
          )}
          <progress
            className="progress"
            style={{}}
            id="progress"
            max="4"
            value={paso}
          />
        </div>

        {paso === 1 ? (
          <>
            <GeneralInformation
              setForm={setFormulario}
              form={formulario}
              changeStep={cambiarPaso}
            />
          </>
        ) : paso === 2 ? (
          <BussinessInformation
            setForm={setFormulario}
            form={formulario}
            changeStep={cambiarPaso}
          />
        ) : paso === 3 ? (
          <CreatePassword
            setPassword={setPassword}
            setForm={setFormulario}
            form={formulario}
            changeStep={cambiarPaso}
            setPasswordMirror={setPassword}
          />
        ) : (
          <TermsAndCondition
            form={formulario}
            email={formulario.email}
            password={password}
            cambiarPaso={cambiarPaso}
          />
        )}
      </div>
    </div>
  );
};

export default SignUp;

import React , {useReducer, useEffect} from 'react';

import GeneralContext from './GeneralContext';
import GeneralReducer from './GeneralReducer';

import { obtenerSesion } from '../utils/Utils';
import { WS_API } from '../config/config';
import { eliminarSesion } from '../utils/Utils';

import { 
    MODIFICAR_USER
} from '../types'

const GeneralState = props => {
    const initialState = {
        user: null
    }

    //dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(GeneralReducer, initialState);

    //Serie de funciones 
    const modificarUser = user  => { dispatch({ type: MODIFICAR_USER, payload: user }) }


    useEffect(()=>{
        //toma de datos de localstorage para mantener sesion
        const newUser = obtenerSesion();
        newUser && !state.user && modificarUser(newUser);
    })

    useEffect(() => {
        const ws = new WebSocket(WS_API);
        try {
          ws.onopen = () => {
            console.log('Conexión WebSocket establecida');
          };

          ws.onmessage = (event) => {
            const updatedUserInfo = JSON.parse(event.data);
            if (updatedUserInfo.documentKey._id === state.user?._id) {
              if (updatedUserInfo.updateDescription?.updatedFields?.email) {
                if (updatedUserInfo.updateDescription?.updatedFields?.email !== state.user?.email) {
                  eliminarSesion();
                  window.location.reload();
                  return
                }
              }
              modificarUser(newUser);
            }
          };
        } catch (error) {
          console.log(error);
        }

        // Toma de datos de localStorage para mantener sesión
        const newUser = obtenerSesion();
        newUser && !state.user && modificarUser(newUser);

        return () => {
          ws.close();
        };
      }, [state.user]);
    
    return (
        <GeneralContext.Provider
            value ={{ 
                user: state.user,
                modificarUser
            }}
        >
            {props.children}
        </GeneralContext.Provider>
    )
}

export default GeneralState;
import axios from "axios";
import { URL_API } from "../../config/config";
const API_URL = `${URL_API}/api`;

export const GetContracts = (token) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.get(`${API_URL}/user/contract/active`);
  } catch (error) {
    return Promise.reject("Catch " + JSON.stringify(error));
  }
};

export const GetUtilities = (token, contractNumber) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.get(`${API_URL}/settlement/contract/${contractNumber}`);
  } catch (error) {
    return Promise.reject("Catch " + JSON.stringify(error));
  }
};

export const DownloadChargeAccount = async (token, contractNumber) => {
  try {
    const { data, headers } = await axios({
      url: `${API_URL}/downloadBillingStatement/${contractNumber}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    return { status: data.status, idDocumentSignNow: data.idDocumentSignNow };
  } catch (error) {
    return { success: false };
  }
};

const downloadBlob = (arrayBuffer, headers) => {
  try {
    const contentDescription = headers.get("x-file-description");
    const description = contentDescription && JSON.parse(contentDescription);
    const nombreArchivo = description ? description.fileName : "File.pdf";

    // Crear una URL del Blob
    const url = window.URL.createObjectURL(
      new Blob([arrayBuffer], { type: "application/pdf" })
    );

    // Crear un enlace temporal y hacer clic en él para descargar el archivo
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", nombreArchivo);
    document.body.appendChild(link);
    link.click();

    // Limpiar la URL del objeto Blob
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log("🚀 ~ file: services.js:43 ~ downloadBlob ~ error:", error);
  }
};

const downloadBlobContract = (data) => {
  // Crear una URL del Blob
  var blob = new Blob([data], { type: "pdf" });
  var url = URL.createObjectURL(blob);
  // window.open(url);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "Contract.pdf");
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const UploadBillingStatement = async (token, idDocumentSignNow) => {
  try {
    // const multipart = new FormData();
    // multipart.append("file", file);
    const { data } = await axios({
      //url: `${API_URL}/signBillingStatement/${contractNumber}`,
      url: `${API_URL}/validate_charge_account/${idDocumentSignNow}`,
      method: "GET",
      // data: multipart,
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log("🚀 ~ file: services.js:90 ~ UploadBillingStatement ~ error:", error)
    return false;
  }
};

export const GetBillings = (token, contract_id) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.get(`${API_URL}/billingByContract/${contract_id}`);
  } catch (error) {
    return Promise.reject("Catch " + JSON.stringify(error));
  }
};

export const DownloadContract = async (token, documentId) => {
  try {
    const { data, headers } = await axios({
      url: `${API_URL}/download_document/${documentId}`,
      method: "GET",
      responseType: "arraybuffer",
      headers: { Authorization: `Bearer ${token}` },
    });
    downloadBlob(data, headers);
    return { success: true };
  } catch (error) {
    return { success: false };
  }
};

export const DownloadCertificate = async (token, body) => {
  try {
    const { data, headers } = await axios({
      url: `${API_URL}/certificate`,
      method: "POST",
      data: body,
      responseType: "arraybuffer",
      headers: { Authorization: `Bearer ${token}` },
    });
    downloadBlob(data, headers);
    return { success: true };
  } catch (error) {
    return { success: false };
  }
};

export const requestLiquidationBackOffice = (token, contractNumber) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.post(`${API_URL}/manual_liquidation`, {
      "contract_number": contractNumber,
      "observations": ""
    });
  } catch (error) {
    return Promise.reject("Catch " + JSON.stringify(error));
  }
};
import { MessagesRead } from "./services";
import React, { useContext } from "react";
import GeneralContext from "../../context/GeneralContext";

import arrowBack from "../../assets/img/svg/back.svg";
import imboxPhoto from "../../assets/img/png/inbox-foto.png";

const Detail = ({ setpage, objectInfo }) => {
  const { user } = useContext(GeneralContext);
  // Llamar al servicio después de 30 segundos
  MessagesRead(objectInfo._id, user.token)
    .then((resp) => {
      if (resp.status >= 200 && resp.status <= 299) {
      } else {
        alert("Hubo un error");
      }
    })
    .catch((err) => {
      //alert(`Ha ocurrido un error: ${err}`);
    });

  return (
    <div className="inbox">
      <div onClick={() => setpage("1")} className="back">
        <img style={{marginTop: "5px"}} src={arrowBack} alt="" />
        <span>{objectInfo.subject}</span>
      </div>
      <div className="inbox-body">
        <div className="detail-header">
          <div className="section">
            <img className="profile-img" src={imboxPhoto} alt="" />
            <div>
              <h6>{objectInfo.autor}</h6>
              <p>{objectInfo.autor}</p>
            </div>
          </div>
          <span style={{margin: 'auto 0px'}}>{objectInfo.createdAt}</span>
        </div>
        <div className="detail-text">
          <p>{objectInfo.msg}</p>
        </div>
      </div>
    </div>
  );
};
export default Detail;

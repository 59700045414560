import { useEffect, useState, useContext } from "react";
import {
  GetPortfolios,
  checkPendingContracts,
  createCustomPortfolio,
} from "../services";
import GeneralContext from "../../../context/GeneralContext";

export const usePortfolios = () => {
  const { user } = useContext(GeneralContext);
  const [portfolios, setPortfolios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getPortfolios = async () => {
    setIsLoading(true);
    try {
      const { data } = await GetPortfolios(user.token);
      setPortfolios(data);
    } catch (error) {}
    setIsLoading(false);
  };

  const checkPendingToSign = async () => {
    const { success, contract } = await checkPendingContracts(user.token);
    return {
      success,
      contract,
    };
  };

  const requestCustomPortfolio = async () => {
    const { success } = await createCustomPortfolio(user.token, {
      title: `El usuario ${user.fullname} solicita un contrato a su medida`,
      description: `El usuario ${user.fullname} con id No ${user.client_investor_number} solicita un contrato personalizado a su medida, por favor contactarlo lo mas pronto posible.`
    });
    return success;
  };

  useEffect(() => {
    getPortfolios();
  }, []);

  return {
    portfolios,
    isLoading,
    checkPendingToSign,
    requestCustomPortfolio
  };
};

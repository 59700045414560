import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "../../components/Modal";
import { guardarSesion, testPassword, validateEmail } from "../../utils/Utils";
import GeneralContext from "../../context/GeneralContext";
import { useSignIn } from "./hooks/useSignIn";

import CABInput from "../../components/CABInput";
import CABButton from "../../components/CABButton";
import OTPInput from "../../components/OTPInput";
import NotificationPopUp from "../../components/Notification/Notification";

import logoTitle from "../../assets/img/svg/title-logo.svg";
import eyeShow from "../../assets/img/png/ojo.png";
import eyeClose from "../../assets/img/svg/ojoClose.svg";
import arrowBack from "../../assets/img/png/flecha-atras.png";

//TODO organizar clases Flex-Box de forma genereal, optimizar clases reutilizadas
import "../../styles/auth.scss";
import "./SignIn.scss";

const SignIn = () => {
  const [t, _] = useTranslation("global");
  //Context
  const GC = useContext(GeneralContext);
  const { user, modificarUser } = GC;

  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const [popUp, setPopUp] = useState(null);

  const { initSession, resendOtpEmail } = useSignIn();

  //FIRST STEP
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [responseUser, setResponseUser] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [validateForm, setValidateForm] = useState(false);
  const [hasErrorForm, setHasErrorForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const testForm = () => {
    return (
      form.email !== "" &&
      form.password !== "" &&
      testPassword(form.password) &&
      validateEmail(form.email)
    );
  };

  useEffect(() => {
    const valid = testForm();
    setValidateForm(valid);
  }, [form]);

  //SECOND STEP
  const [open, setOpen] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [validatePin, setValidatePin] = useState(false);
  const [hasErrorPinValidate, setHasErrorPinValidate] = useState(false);

  useEffect(() => {
    setValidatePin(otpInput.length > 4);
  }, [otpInput]);

  ///Fin SECOND STEP

  const changeStep = async () => {
    if (step == 1) {
      const { success, user, msg } = await initSession(
        form,
        navigator.userAgent
      );
      console.log(msg);
      if (success && user) {
        setErrorMessage("");
        setResponseUser(user);
        setStep(2);
        return;
      }
      if (!success) {
        if (msg.includes("KYC")) {
          showNotification(
            t("invest.validate_contract.failed_notification_title"),
            t("login.wrong_form_kyc") + ". " + t("general.notification_failed"),
            "error"
          );
          setErrorMessage(t("login.wrong_form_kyc"));
        } else {
          setErrorMessage(msg);
          setErrorMessage(t("login.wrong_password"));
        }
      }
      setHasErrorForm(true);
    }
    if (step === 2) {
      if (otpInput === responseUser?.code) {
        let newUser = structuredClone(responseUser);
        newUser.codeEntered = otpInput;
        guardarSesion(newUser);
        modificarUser(newUser);
        navigate("/Home");
      }
      setHasErrorPinValidate(true);
    }
  };

  useEffect(() => {
    if (user) {
      user.codeEntered !== "" && navigate("/Home");
    }
  }, [user, navigate]);

  const showNotification = (message, description, type) => {
    setPopUp({ message, description, type });
  };

  const closeNotification = () => {
    setPopUp(null);
  };

  return (
    <div className="container vector-left">
      {popUp && (
        <NotificationPopUp
          message={popUp.message}
          type={popUp.type}
          description={popUp.description}
          onClose={closeNotification}
        />
      )}
      <div className="left-column-login">
        <div style={{ width: "500px", float: "right" }}>
        {step === 1 ? (
          <p className="titulo-1">{t("login.title")}</p>
        ) : (
          <div onClick={() => setStep(1)} className="back_title">
            <img src={arrowBack} className="arrow-back" alt="Arrow Back"></img>
            <p className="titulo-1">{t("login.title")}</p>
          </div>
        )}
          <p className="titulo-2">{t("login.description")}</p>
        </div>
      </div>
      <div className="right-column-login">
        <div className="container-form">
          <form className="form" onSubmit={(e) => e.preventDefault()}>
            <img src={logoTitle} className="logo-header" alt="titulo"></img>
            {step === 1 && (
              <>
                <p className="title-login">
                  {t("login.title_form_1")} {t("login.title_form_2")}
                </p>
                <CABInput
                  label={t("signup.general_information.email")}
                  type="text"
                  placeholder={t("signup.write_here")}
                  autoComplete="on"
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
                <CABInput
                  label={t("signup.password.password")}
                  type={showPassword ? "text" : "password"}
                  placeholder={t("signup.write_here")}
                  autoComplete="on"
                  error={hasErrorForm}
                  errorMessage={
                    errorMessage ? errorMessage : "Credenciales inválidas"
                  }
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  rightIcon={
                    <img
                      src={showPassword ? eyeShow : eyeClose}
                      alt="ojo"
                    ></img>
                  }
                  onRightIconClick={() => setShowPassword(!showPassword)}
                />

                <Link to="/Recuperar" className="link">
                  {t("login.forgot_password")}
                </Link>
                <CABButton
                  disabled={!validateForm}
                  label={t("invest.modal_pay.continue")}
                  onClick={() => changeStep()}
                  type="primary"
                />
                <div className="register-label">
                  <span>{t("login.havenot_account")}</span>
                  <span onClick={() => navigate("/SignUp")}>
                    {t("login.register_here")}
                  </span>
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <p className="titulo-2">
                  {t("login.write_code")} {form?.email}
                </p>

                <OTPInput
                  length={5}
                  validatePin={!hasErrorPinValidate}
                  onChange={(value) => {
                    let otpJoined = Object.values(value).join("");
                    setOtpInput(otpJoined);
                  }}
                />
                <CABButton
                  disabled={!validatePin}
                  label={t("invest.modal_pay.continue")}
                  onClick={() => changeStep()}
                  type="primary"
                />
                <CABButton
                  disabled={!validateForm}
                  label={t("login.resend_code")}
                  onClick={async () => {
                    const response = await resendOtpEmail(form);
                    response && setOpen(true);
                  }}
                />
              </>
            )}
          </form>
        </div>
        <Modal
          type="2"
          btnlabel={t("login.modal.btn_accept")}
          onConfirm={() => setOpen(false)}
          onOpen={setOpen}
          open={open}
          title={t("login.modal.title")}
          text={t("login.modal.description")}
        />
      </div>
    </div>
  );
};

export default SignIn;

import React, { useState } from "react";

import { formatDate, formatNumber } from "../../../utils/Utils";
import { useNavigate } from "react-router";
import { useMovements } from "../../Inversions/hooks/useMovements";
import { useTranslation } from "react-i18next";

import incomeIcon from "../../../assets/img/svg/Income.svg";
import substrackIcon from "../../../assets/img/svg/substract.svg";

import "./styles.scss";

const Table = ({ data }) => {
  const [t, _] = useTranslation("global");
  const [openRows, setOpenRows] = useState([]);
  const navigate = useNavigate();

  const { downloadBillById } = useMovements();

  const handleArrowClick = (index) => {
    setOpenRows((prevOpenRows) => {
      if (prevOpenRows.includes(index)) {
        return prevOpenRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevOpenRows, index];
      }
    });
  };

  const isRowOpen = (index) => {
    return openRows.includes(index);
  };

  const renderTableHead = (item, index) => {
    const isOpen = isRowOpen(index);
    return (
      <tr key={item._id}>
        <th colSpan="2" className="product-info">
          <span>
            {item?.bill_type?.trx_type === "WALLET_CHARGING" &&
              t("invoice.wallet_charging")}
            {item?.bill_type?.trx_type === "WALLET_DISCHARGING" &&
              t("invoice.wallet_discharging")}
          </span>
          <div style={{ display: "flex", gap: "10px" }}>
            {item?.bill_type?.trx_type === "WALLET_CHARGING" ? (
              <>
                <img src={incomeIcon} alt="" />
                <span>{t("movements.table.income")}</span>
              </>
            ) : (
              <>
                <img src={substrackIcon} alt="" />
                <span>{t("movements.table.discharge")}</span>
              </>
            )}
          </div>
          <div
            className={`arrow ${isOpen ? "expanded" : ""}`}
            onClick={() => handleArrowClick(index)}
          ></div>
        </th>
      </tr>
    );
  };

  const renderTableBody = (item, index) => {
    const isOpen = isRowOpen(index);
    return (
      <React.Fragment key={item._id}>
        {isOpen && (
          <tr>
            <td>
              <div className="details">
                <div className="detail-row">
                  <span className="title">{t("movements.table.date")}</span>
                  <span className="title">{t("movements.table.amount")}</span>
                  <span className="title"></span>
                </div>
                <div className="detail-row">
                  <span>{formatDate(item?.date)}</span>
                  <div>
                    <span>$ {formatNumber(item?.amount)}</span>
                    <span className="dolar">USD</span>
                  </div>
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      downloadBillById(item.document_url_location);
                    }}
                  >
                    <a href="" style={{ color: "#7FC5ED" }}>
                      {t("movements.table.see_invoice")}
                    </a>
                  </span>
                </div>
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="table-wrapper">
      <table className="responsive-table">
        {data.map((item, index) => {
          return (
            <>
              <thead>{renderTableHead(item, index)}</thead>
              <tbody>{renderTableBody(item, index)}</tbody>
            </>
          );
        })}
      </table>
      <table className="normal-table">
        <thead>
          <tr>
            <th>{t("invoice.transaction")}</th>
            <th>{t("movements.table.date")}</th>
            <th>{t("movements.table.amount")}</th>
            <th>{t("movements.table.status")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={item._id}>
                <td>
                  {item?.bill_type?.trx_type === "WALLET_CHARGING" &&
                    t("invoice.wallet_charging")}
                  {item?.bill_type?.trx_type === "WALLET_DISCHARGING" &&
                    t("invoice.wallet_discharging")}
                </td>
                <td>{formatDate(item?.date)}</td>
                <td>
                  $ {formatNumber(item?.amount)}{" "}
                  <span className="dolar">USD</span>
                </td>
                <td style={{ display: "flex", gap: "10px" }}>
                  {item?.bill_type?.trx_type === "WALLET_CHARGING" ? (
                    <>
                      <img src={incomeIcon} alt="" />
                      <span>{t("movements.table.income")}</span>
                    </>
                  ) : (
                    <>
                      <img src={substrackIcon} alt="" />
                      <span>{t("movements.table.discharge")}</span>
                    </>
                  )}
                </td>
                <td>
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      downloadBillById(item.document_url_location);
                    }}
                  >
                    <a href="" style={{ color: "#7FC5ED" }}>
                      {t("movements.table.see_invoice")}
                    </a>
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

import './styles.scss'
import { Term } from "../../pages/SignUp/TermsAndConditions";
import { useTranslation } from "react-i18next";

const TermsAndConditionContent = ({isWholeWidth=false}) => {
  const [t, _] = useTranslation("global");
    return(
        <div className="containerTerms" style={{width:isWholeWidth?'100%':''}}>
                <p className = "titulo-section"><span>{t("terms_and_conditions.title")}</span></p>
                <div className="terms">
                  <Term title={t("terms_and_conditions.term_1.title")} text={t("terms_and_conditions.term_1.content")}/>
                  <Term title={t("terms_and_conditions.term_2.title")} text={t("terms_and_conditions.term_2.content")}/>
                  <Term title={t("terms_and_conditions.term_3.title")} text={t("terms_and_conditions.term_3.content")} />
                  <Term title={t("terms_and_conditions.term_4.title")} text={t("terms_and_conditions.term_4.content")} />
                  <Term title={t("terms_and_conditions.term_5.title")} text={t("terms_and_conditions.term_5.content")}/>
                  <Term title={t("terms_and_conditions.term_6.title")} text={t("terms_and_conditions.term_6.content")}/>
                  <Term title={t("terms_and_conditions.term_7.title")} text={t("terms_and_conditions.term_7.content")} />
                  <Term title={t("terms_and_conditions.term_8.title")} text={t("terms_and_conditions.term_8.content")}/>
                  <Term title={t("terms_and_conditions.term_9.title")} text={t("terms_and_conditions.term_9.content")}/>
                  <Term title={t("terms_and_conditions.term_10.title")} text={t("terms_and_conditions.term_10.content")}/>
                  <Term title={t("terms_and_conditions.term_11.title")} text={t("terms_and_conditions.term_11.content")}/>
                  <Term title={t("terms_and_conditions.term_12.title")} text={t("terms_and_conditions.term_12.content")}/>
                </div>
        </div>
    )
}

export default TermsAndConditionContent;
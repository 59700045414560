import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { TipoAccount } from "../../utils/constants";
import InputSelect from "../SignUp/InputSelect";
import { useMovements } from "./hooks/useMovements";

import Loader from "../../components/loader/loader";
import Table from "./Table";
import { useTranslation } from "react-i18next";

import searchIcon from "../../assets/img/svg/searchIcon.svg";
import filtroIcon from "../../assets/img/svg/filtroIcon.svg";

const Movimientos = () => {
  const { getMovements } = useMovements();

  const [formulario, setFormulario] = useState({
    // State para informacion de usuario
    producto: "",
    transaccion: "",
    estado: "",
  });

  const [panelF, setpanelF] = useState(false);
  const [movements, setMovements] = useState([]);
  const [movementsOld, setMovementsOld] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [t, i18n] = useTranslation("global");

  const procesesMovements = React.useCallback(async () => {
    setIsLoading(true);
    const data = await getMovements();
    if (data && data.length > 0) {
      setMovements(data.reverse());
      setMovementsOld(data);
    }
    setIsLoading(false);
  }, [getMovements]);

  const handleSelectChange = async (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value,
    });
    const filter = movementsOld.filter((value) => value.type == e.target.value);
    setMovements(filter);
  };

  const searchOnTable = (word) => {
    if (word) {
      setMovements(
        movementsOld.filter((objeto) => {
          return Object.values(objeto).some((valor) => {
            if (valor)
              if (typeof valor == "object") {
                return Object.values(valor).some((objectValue) => {
                  return objectValue
                    .toString()
                    .toLowerCase()
                    .includes(word.toLowerCase());
                });
              } else {
                return valor
                  .toString()
                  .toLowerCase()
                  .includes(word.toLowerCase());
              }
          });
        })
      );
    } else {
      setMovements(movementsOld);
    }
  };

  useEffect(() => {
    procesesMovements();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="movimientos">
          <div className="search">
            <div className="inputSearch">
              <img src={searchIcon} alt="" />
              <input
                placeholder="Buscar"
                name="search"
                type="text"
                onChange={(e) => searchOnTable(e.target.value)}
              />
            </div>
            <div
              onClick={() => {
                setpanelF(!panelF);
                setMovements(movementsOld);
              }}
              className="btnFiltro"
            >
              <img src={filtroIcon} alt="" />
              <span>{t("general.filter")}</span>
            </div>
          </div>
          {panelF && (
            <div className="filtros">
              <div className="espacio-input">
                <InputSelect
                  form={formulario}
                  name="estado"
                  elements={[
                    {
                      option: "Out",
                      value: "Retiro",
                    },
                    {
                      option: "In",
                      value: "Ingreso",
                    },
                  ]}
                  onChange={handleSelectChange}
                />
              </div>
            </div>
          )}
          {movements.length > 0 && <Table data={movements} />}
        </div>
      )}
    </>
  );
};

export default Movimientos;

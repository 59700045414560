import { recoveryPass, resetPassword } from "../services";

export const usePasswordRecovery = () => {
  
  const recoverPassword = async (email) => {
    try {
      const response = await recoveryPass({ email });
      return response.status >= 200 && response.status <= 299;
    } catch (error) {
      console.log(`Ah ocurrido un error ${error.response.data.msg}`);
      return false;
    }
  };
  const changePassword = async (params, body) => {
    try {
      const response = await resetPassword(params, body);
      return response.status >= 200 && response.status <= 299;
    } catch (error) {
      console.log(`Ah ocurrido un error ${error.response.data.msg}`);
      return false;
    }
  };

  return {
    recoverPassword,
    changePassword
  };
};

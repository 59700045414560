import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CreateContract, getBalance } from "../services";
import GeneralContext from "../../../context/GeneralContext";
import { CURRENCY } from "../../../utils/constants";

export const useSign = () => {
  const navigate = useNavigate();

  const { user } = useContext(GeneralContext);

  const generateContract = async (amount, feeAdmin, templateNumber) => {
    const body = {
      investment: [
        {
          coin: CURRENCY,
          amount: amount,
        },
      ],
      sales_assigned: "0",
      userLoginId: user.email,
      template_number: templateNumber,
      feeAdmin,
    };

    try {
      const { success, contractId, contract } = await CreateContract(
        user.token,
        templateNumber,
        body
      );
      success && navigate("/uploadSign", { state: contract });
      return { success };
    } catch (error) {
      console.log(
        "🚀 ~ file: useSign.jsx:20 ~ generateContract ~ error:",
        error
      );
      return false;
    }
  };

  const getBalanceUser = async () => {
    try {
      const { success, wallet, code } = await getBalance(user.token);
      return { success, wallet, code };
    } catch (error) {
      return false;
    }
  };

  return { generateContract, getBalanceUser };
};

import axios from "axios";
import { URL_API } from "../../config/config";

const API_URL = `${URL_API}/api`;

export const uploadUserProfilePhoto = async (formData, token) => {
    return axios.post(`${API_URL}/user/profile-picture`, formData,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        })
}

export const createRequestPerfilModification = async (token, body) => {
    try {
        const { data } = await axios({
            url: `${API_URL}/request_edit_profile`,
            data: body,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
        });
        const { success } = data;
        return { success };
    } catch (error) {
        console.log("🚀 ~ file: services.js:27 ~ createRequestPerfilModification ~ error:", error)
        return { success: false };
    }
};

export const createRequestPerfilDelete = async (token, body) => {
    try {
        const { data } = await axios({
            url: `${API_URL}/request_delete_profile`,
            data: body,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
        });
        const { success } = data;
        return { success };
    } catch (error) {
        console.log("🚀 ~ file: services.js:43 ~ createRequestPerfilDelete ~ error:", error)
        return { success: false };
    }
};

import GeneralState from './context/GeneralState'
import Routes from './routes';

function App() {
  return (
    <GeneralState>
      <Routes />
    </GeneralState>
  );
}

export default App;

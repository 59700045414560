import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const CABDropDown = ({ label, options, value, onChange, type }) => {
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    onChange(e);
  };

  return (
    <div className={`${type == "phone" && "type-phone"}`}>
      <p className="titulo-2">{label}</p>
      <div className={`custom-dropdown`}>
        <select value={selectedOption} onChange={handleOptionChange}>
          {type != "phone" && (
            <option value="" disabled hidden>
              Select an option
            </option>
          )}
          {options.map((option, i) => (
            <option key={i} value={option.option}>
              {option.value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

CABDropDown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
};

export default CABDropDown;

import React, { useEffect, useRef, useState } from "react";
import { Metodo, Balance } from "../../utils/constants";
import InputSelect from "../SignUp/InputSelect";
import "../Perfil/styles.scss";
import { useNavigate, useLocation } from "react-router";
import CustomModal from "../../components/Modal/CustomModal";
import { useRetiro } from "./hooks/useRetiro";
import { useMyInversions } from "../Inversions/hooks/useMyInversions";
import { useContracts } from "../Contratos/hooks/useContracts";
import { formatNumber } from "../../utils/Utils";
import Loader from "../../components/loader/loader";
import Notification from "../../components/Notification/Notification";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal/index";
import { NumericFormat } from "react-number-format";

import arrowBack from "../../assets/img/svg/back.svg";
import alertIcon from "../../assets/img/svg/alert.svg";

const FormRetiro = () => {
  const [t, i18n] = useTranslation("global");
  const {
    sendRequest,
    getAccountsUser,
    searchChargeAccount,
    getBalanceService,
  } = useRetiro();
  const { getUtilities } = useContracts();
  const { getMyInversions } = useMyInversions();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [saldo, setSaldo] = useState(0);
  const [cantidad, setCantidad] = useState(0);
  const [contrato, setContrato] = useState("");
  const [metodo, setMetodo] = useState(0);
  const [categoria, setCategoria] = useState("");

  const [open, setOpen] = useState(false);
  const [openDiscountMessage, setOpenDiscountMessage] = useState(false);
  const [openFailedModal, setOpenFailedModal] = useState(false);
  const [boton, setBoton] = useState("boton-continuar-disabled"); // State para claseName de boton "Continuar"

  const [myInversions, setMyInversions] = useState([]);
  const [penalty_for_early_withdrawal, setPenalty_for_early_withdrawal] =
    useState(0);
  const [capital, setCapital] = useState(0);
  const [utilities, setUtilities] = useState(0);
  const [balanceUser, setBalanceUser] = useState(0);

  const [userBankAccount, setUserBankAccount] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [notification, setNotification] = useState(null);
  const [chargeAccount, setChargeAccount] = useState(null);
  const [isValidWithdrawal, setIsValidWithdrawal] = useState(false);

  const showNotification = (message, type) => {
    setNotification({ message, type });
  };

  const closeNotification = () => {
    setNotification(null);
  };

  const submit = async () => {
    if (!checkBusinessMonths()) {
      showNotification(
        "Lo sentimos, no puedes hacer retiros en los meses de Junio y Diciembre",
        "error"
      );
      return;
    }

    if (checkBusinessDays()) {
      setOpen(true);
      return;
    }

    showNotification(
      "Lo sentimos, solo puedes hacer retiros los primeros 5 días del mes",
      "error"
    );
  };

  //TODO Establecer comprobacion general
  const comprobarFormulario = React.useCallback(() => {
    return (
      contrato !== "0" &&
      categoria !== "0" &&
      cantidad !== "" &&
      saldo !== "" &&
      metodo !== 0 &&
      chargeAccount != null
    );
  }, [contrato, categoria, metodo, chargeAccount]);

  useEffect(() => {
    showNotification(t("withdrawals.notification_info"), "info");
    //Set type withdrawal
    switch (state) {
      case "1":
        setCategoria("PROFITS_WITHDRAWAL");
        break;
      case "2":
        setCategoria("ANTICIPATED_WITHDRAWAL");
        break;
      case "3":
        setCategoria("CONTRACT_ENDING_WITHDRAWAL");
        break;
    }

    processGetMyInversions();
    getAccount();
    getBalance();
  }, []);

  useEffect(() => {
    handleSelectChange()
  }, [metodo, chargeAccount]);

  const handleSelectChange = (e) => {
    if (comprobarFormulario()) {
      setBoton("boton-continuar-enabled");
    } else {
      setBoton("boton-continuar-disabled");
    }
  };

  const converToNumber = (cadena) => {
    if(cadena) {
      return parseFloat(cadena?.replace(/,/g, ''));
    }
  };

  const validate = () => {
    const saldoAmount = converToNumber(saldo);
    const cantidadAmount = converToNumber(cantidad);

    const isValidate = cantidadAmount <= saldoAmount;
    setIsValidWithdrawal(isValidate);
    if(isValidate && comprobarFormulario()) {
      setBoton("boton-continuar-enabled");
    } else {
      setBoton("boton-continuar-disabled");
    }
  };

  useEffect(() => {
    validate();
  }, [saldo, cantidad]);

  const selectChangePortfolio = (e) => {
    setContrato(e.target.value);
    if (e.target.value != "0") {
      processLookingForChargeAccount(e.target.value);
      setPenalty_for_early_withdrawal(
        myInversions.find((m) => m.option == e.target.value)?.penalty
      );
      setData(categoria, e.target.value);
    }
  };

  const setData = (category, numberContract) => {
    setUtilities("");
    if (category == "PROFITS_WITHDRAWAL") {
      // getProfits(e.target.value);
      const utility = getProfits(numberContract)
      setUtilities(utility);
      setSaldo(formatNumber(utility));
      setCantidad(formatNumber(utility));
    }
    if (category == "ANTICIPATED_WITHDRAWAL") {
      setSaldo(formatNumber(balanceUser));
      setCantidad(formatNumber(balanceUser));
    }
    if (category == "CONTRACT_ENDING_WITHDRAWAL") {
      const _capital = myInversions.find(
        (m) => m.option == numberContract
      )?.capital;
      setCapital(_capital);
      setSaldo(formatNumber(_capital));
      setCantidad(formatNumber(_capital));
    }
  };

  const processGetMyInversions = async () => {
    let inversions = await getMyInversions();
    inversions = inversions
      .filter((i) => i.intermediate_states === "Signed")
      .map((inversion) => ({
        option: `${inversion.contract_number}`,
        value: `Portafolio ${inversion.descriptionContract.type} #${inversion.contract_number}`,
        penalty: inversion.penalty_for_early_withdrawal,
        capital: inversion.net_investment[0]?.amount,
        utility: inversion.utility_amount,
      }));
    inversions.unshift({
      option: "0",
      value: "Seleccione",
    });
    inversions.length > 0 && setMyInversions(inversions);
  };

  const getProfits = async (contract_number) => {
    const total_profits = await getUtilities(contract_number);
    if (total_profits > 0) {
      setUtilities(total_profits);
      if (state == 1) {
        setCantidad(formatNumber(total_profits));
        setSaldo(formatNumber(total_profits));
      }
    } else {
      setUtilities(0);
    }
  };

  const getAccount = async () => {
    const resp = await getAccountsUser();
    resp.length > 0 && setUserBankAccount(resp[0]);
  };

  const processLookingForChargeAccount = async (contract_number) => {
    const { success, data } = await searchChargeAccount(
      contract_number,
      new Date()
    );
    if (!success) {
      setChargeAccount(null);
    } else {
      const { year, status, months, _id } = data.BillingStatement;

      const actualMonth = new Date().toLocaleString("en-US", {
        month: "long",
      });

      const containsMonth = months
        .toLowerCase()
        .includes(actualMonth.toLowerCase());

      if (status === "Signed" && containsMonth) {
        setChargeAccount(_id);
      }
    }
  };

  const checkBusinessDays = () => {
    const day = new Date().getDate();
    return day >= 1 && day <= 5;
  };

  const checkBusinessMonths = () => {
    const currentMonth = new Date().getMonth();
    return currentMonth != 6 && currentMonth != 11;
  };

  const discountFeeMessage = () => {
    if (
      categoria == "ANTICIPATED_WITHDRAWAL" ||
      categoria == "CONTRACT_ENDING_WITHDRAWAL"
    ) {
      return `Ten en cuenta que descontaremos el costo de la penalidad que corresponde al ${penalty_for_early_withdrawal} %. ¿Deseas continuar?`;
    } else {
      return `¿Estas seguro que deseas retirar $${formatNumber(
        utilities
      )} USD?`;
    }
  };

  const getBalance = async () => {
    //Get balance User
    const data = await getBalanceService();
    if (data) {
      const balance = data.wallet.balance;
      setBalanceUser(balance);
      if (state == 2) {
        setCantidad(formatNumber(balance));
        setSaldo(formatNumber(balance));
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {notification && (
            <Notification
              message={notification.message}
              type={notification.type}
              description={""}
              onClose={closeNotification}
            />
          )}
          <div className="perfil solicitud">
            <div onClick={() => navigate("/Retiros")} className="back">
              <img src={arrowBack} alt="" /> <span>{t("general.back")}</span>
            </div>
            <div className="title">
              <span>{t("withdrawals.request_withdrawal")}</span>
            </div>
            <div className="text">
              <span>{t("withdrawals.description_request_withdrawal")}</span>
            </div>
            <div className="formulario-registro">
              <div className="container-campos">
                <div className="part-space">
                  <div className="espacio-input">
                    <p className="titulo-2">
                      {t("withdrawals.select_product")}
                    </p>
                    <select
                      name="contrato"
                      value={contrato}
                      onChange={selectChangePortfolio}
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        paddingRight: "20px"
                      }}
                      className="input selectInput"
                    >
                      {myInversions.length > 0 &&
                        myInversions.map((e) => (
                          <option key={e.option} value={e.option}>
                            {e.value}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="espacio-input">
                    <p className="titulo-2">
                      {t("withdrawals.select_product_balance")}
                    </p>
                    <span className="dolar">
                      USD
                    </span>
                    <input
                      type="text"
                      className="input input-dolar"
                      placeholder="$"
                      name="saldo"
                      readOnly
                      value={saldo}
                    ></input>
                  </div>
                </div>
                <div className="part-space">
                  <div className="espacio-input">
                    <p className="titulo-2">
                      {t("withdrawals.select_category")}
                    </p>
                    <select
                      name="categoria"
                      value={categoria}
                      onChange={(e) => {
                        handleSelectChange(e);
                        setData(e.target.value, contrato);
                        setCategoria(e.target.value);
                      }}
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      className="input selectInput"
                    >
                      {Balance.length > 0 &&
                        Balance.map((e) => (
                          <option key={e.option} value={e.option}>
                            {e.value}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="espacio-input">
                    <p className="titulo-2">
                      {t("withdrawals.amount_withdrawal")}
                    </p>
                    <span className="dolar">
                      USD
                    </span>
                    {/* <input
                      type="text"
                      className="input input-dolar"
                      placeholder="$"
                      name="cant"
                      value={cantidad}
                      onChange={(e) => setCantidad(e.target.value.replace(/,/g, ""))}
                    ></input> */}
                    <NumericFormat
                      placeholder="$"
                      className="input input-dolar"
                      value={cantidad}
                      thousandSeparator=","
                      onChange={(e) => {
                        validate()
                        setCantidad(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="part-space">
                  <div className="espacio-input">
                    <p className="titulo-2">{t("withdrawals.select_method")}</p>
                    <InputSelect
                      form={metodo}
                      elements={Metodo}
                      name="metodo"
                      onChange={(e) => {
                        setMetodo(e.target.value);
                      }}
                    />
                  </div>
                  <div className="espacio-input">
                    <p className="titulo-2">
                      {t("withdrawals.attach_account_document")}
                    </p>
                    <div
                      className="btnFile"
                      style={{
                        backgroundColor: chargeAccount && "#F4D06F",
                        marginTop: '10px'
                      }}
                    >
                      <span
                        style={{
                          color: chargeAccount && "#0D2338",
                        }}
                      >
                        {!chargeAccount
                          ? "No se encontró una cuenta de cobro"
                          : `Cuenta de cobro ${chargeAccount}`}
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  className={boton}
                  onClick={() => {
                    if (comprobarFormulario() && isValidWithdrawal) {
                      setOpenDiscountMessage(true);
                    }
                  }}
                >
                  {t("withdrawals.btn_request_withdrawal")}
                </button>
              </div>
            </div>
            <CustomModal
              open={open}
              component={
                <div className="Mretiro">
                  <div className="header">
                    <img src={alertIcon} alt="" />
                    <span>{t("withdrawals.modal_withdrawal.title")}</span>
                  </div>
                  <p>{t("withdrawals.modal_withdrawal.description")}</p>
                  <div className="section">
                    <h6>{t("withdrawals.modal_withdrawal.bank")}</h6>
                    <span>{userBankAccount.bank_name}</span>
                  </div>
                  <div className="section">
                    <h6>{t("withdrawals.modal_withdrawal.type_account")}</h6>
                    <span>{userBankAccount.account_type}</span>
                  </div>
                  <div className="section">
                    <h6>{t("withdrawals.modal_withdrawal.account_number")}</h6>
                    <span>{userBankAccount.account_number}</span>
                  </div>
                  <div className="Mbtns">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpen(false);
                      }}
                    >
                      <span>{t("withdrawals.modal_withdrawal.cancel")}</span>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        if (comprobarFormulario()) {
                          setIsLoading(true);
                          const amount = cantidad;
                          const resp = await sendRequest({
                            amount,
                            contract_number: contrato,
                            withdrawal_type: categoria,
                          });
                          if (resp) {
                            showNotification(
                              "¡Tu solicitud se ha enviado con éxito! \n Nuestro equipo CAB CAPITAL esta revisando y validando la información. En 15 días tendrás una actualización de tu solicitud.",
                              "success"
                            );
                            setTimeout(() => {
                              navigate("/Retiros");
                            }, 3000);
                          } else {
                            setOpenFailedModal(true);
                          }
                          setOpen(false);
                          setIsLoading(false);
                        }
                      }}
                    >
                      <span>{t("withdrawals.modal_withdrawal.accept")}</span>
                    </div>
                  </div>
                </div>
              }
            />
            <Modal
              type="1"
              onConfirm={() => submit()}
              onOpen={setOpenDiscountMessage}
              open={openDiscountMessage}
              cancel
              title={"Realizaremos un retiro"}
              text={discountFeeMessage()}
            />
            <Modal
              type="3"
              onOpen={setOpenFailedModal}
              open={openFailedModal}
              title={"No es posible realizar esto"}
              text={
                "Lo sentimos en el momento no fue posible realizar esta operación, por favor inténtelo mas tarde."
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default FormRetiro;

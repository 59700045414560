import "./styles.scss";
import React from "react";
import { formatDate, formatNumber } from "../../../utils/Utils";
import { useTranslation } from "react-i18next";

export const MyInversionsItems = ({ inversion }) => {
  const [t, i18n] = useTranslation("global");
  const {
    portfolio,
    investment,
    status,
    days_for_activation,
    createdAt,
    payment_period_cost_effectiveness,
    descriptionContract,
    net_investment,
    cost_effectiveness,
  } = inversion;

  const typePortfolio = [
    { type: "Renta Fija", class: "card1" },
    { type: "Renta Fija premium", class: "card2" },
    { type: "Renta Variable", class: "card3" },
    { type: "Renta Cripto", class: "card4" },
    { type: "OTC", class: "card5" },
    { type: "hecho a tu medida", class: "card6" },
  ];

  return (
    <>
      <div className="card">
        <div
          className={`front ${
            typePortfolio.find((t) => t.type === descriptionContract.type)
              ?.class || descriptionContract.color_card
          } ${descriptionContract.icon_card}`}
        >
          <div style={{ marginTop: "20px" }}>
            <span className="title">
              {t("investments.inversion_item.portfolio")} #{portfolio}
            </span>
            <div style={{ marginBottom: "10px", marginTop: "10px" }}>
              <h6>{t("my_investment.portfolio_type")} </h6>
              <span>{descriptionContract.type}</span>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h6>{t("my_investment.investment_made")} </h6>
              <span>
                ${" "}
                {formatNumber(
                  net_investment.reduce(
                    (old, actual) => old.amount + actual.amount
                  ).amount
                )}{" "}
                USD
              </span>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h6>{t("my_investment.profitability_percentage")}</h6>
              <span>{cost_effectiveness}</span>
            </div>
            <div>
              <h6>{t("movements.table.status")}</h6>
              <div className="card-state">
                <div className="card-state-content">
                  <div
                    className="point-state"
                    style={{
                      backgroundColor:
                        status !== "Active" ? "#C28000" : "#26C485",
                    }}
                  ></div>
                  <span style={{ marginLeft: "10px" }}>{status}</span>
                </div>
                {status != "Active" && (
                  <span style={{ marginTop: "5px" }}>
                    {`
                    ${t("my_investment.message_activation_1")}

                    ${days_for_activation} ${t(
                      "my_investment.message_activation_2"
                    )}
                  `}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`back ${
            typePortfolio.find((t) => t.type === descriptionContract.type)
              ?.class || descriptionContract.color_card
          } ${descriptionContract.icon_card}`}
        >
          <div>
            <span className="title">
              {t("investments.inversion_item.portfolio")} #{portfolio}
            </span>
            <div style={{ marginBottom: "10px", marginTop: "10px" }}>
              <h6>{t("investments.contract_expiration")}</h6>
              <span>
                {Math.round(descriptionContract.minimum_expiration_time / 30)}{" "}
                {t("investments.months")}
              </span>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h6>{t("my_investment.date_purchase")}</h6>
              <span>{formatDate(createdAt)}</span>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h6>{t("investments.pay_profitability")}</h6>
              <span>{payment_period_cost_effectiveness}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

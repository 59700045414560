import { requestLogin, requestResendOtp } from "../services";

export const useSignIn = () => {
  const initSession = async (form, browser) => {
    try {
      const response = await requestLogin({
        email: form.email,
        password: form.password,
        browser: JSON.stringify(browser),
      });
      if (response.status >= 200 && response.status <= 299) {
        let newUser = structuredClone(response?.data?.user);
        newUser.token = response?.data?.token;
        newUser.code = response?.data?.code;
        newUser.codeEntered = "";
        return {
          success: true,
          user: newUser,
          msg: "ok",
        };
      }
      console.log(response);
      return {
        success: false,
        user: null,
        msg: response?.data?.msg,
      };
    } catch ({msg}) {
      return {
        success: false,
        user: null,
        msg: msg,
      };
    }
  };

  const resendOtpEmail = async (form) => {
    try {
      const response = await requestResendOtp({
        email: form.email,
      });
      return response.status >= 200 && response.status <= 299;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return {
    initSession,
    resendOtpEmail,
  };
};

import { useState, useEffect } from "react";
import Perfil from "../Perfil";
import Home from "./Home";
import Inversiones from "../Inversions/index";
import Notifications from "./Notifications";
import SideBar from "../../components/Sidebar/SideBar";
import TermsAndConditionContent from "../../components/Terms";
import Contratos from "../Contratos";
import Inbox from "../inbox";
import Onboarding from "../../components/Onboarding";
import { useLocation } from "react-router-dom";
import NotificationPopUp from "../../components/Notification/Notification";
import { useTranslation } from "react-i18next";

const Main = () => {
  const [t, _] = useTranslation("global");
  const { state } = useLocation();

  const [panel, setpanel] = useState(state ?? "1");
  const [openOnboarding, setOpenOnboarding] = useState(false);

  const [popUp, setPopUp] = useState(null);

  const showNotification = (message, description, type) => {
    setPopUp({ message, description, type });
  };

  const closeNotification = () => {
    setPopUp(null);
  };

  useEffect(() => {
    const flagOnboarding = localStorage.getItem("omboarding");
    if (!flagOnboarding) {
      setOpenOnboarding(true);
    } else {
      setOpenOnboarding(false);
    }
    // if (state === "firtstime") {
    //   showNotification(
    //     t("home.notification_title"),
    //     t("home.notification_description"),
    //     "success"
    //   );
    // }
  }, []);

  return (
    <>
      {openOnboarding ? (
        <Onboarding setOpen={setOpenOnboarding} />
      ) : (
        <>
          {popUp && (
            <NotificationPopUp
              message={popUp.message}
              type={popUp.type}
              description={popUp.description}
              onClose={closeNotification}
            />
          )}
          <div className="main">
            <SideBar panel={panel} setpanel={setpanel} />
            {panel === "1" ? (
              <Home />
            ) : panel === "0" ? (
              <Perfil openOnboarding={setOpenOnboarding} />
            ) : panel === "4" ? (
              <Notifications />
            ) : panel === "6" ? (
              <TermsAndConditionContent isWholeWidth={true} />
            ) : panel === "3" ? (
              <Contratos />
            ) : panel === "5" ? (
              <Inbox />
            ) : (
              <Inversiones />
            )}
          </div>
        </>
      )}
    </>
  );
};
export default Main;

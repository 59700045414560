import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import CustomModal from "../../components/Modal/CustomModal";
import { recoveryPass } from "../../pages/PasswordRecovery/services";
import GeneralContext from "../../context/GeneralContext";
import ProfilePicture from "./ProfilePicture";

import FormBank from "./FormBank";
import FormPerfil from "./FormPerfil";
import Info from "../../assets/img/svg/infoB.svg";

import puntos from "../../assets/img/svg/puntos.svg";
import retiro from "../../assets/img/svg/retiro.svg";
import edit from "../../assets/img/svg/edit.svg";
import deleteIcon from "../../assets/img/svg/deleteIcon.svg";
import play from "../../assets/img/svg/play.svg";
import lockIcon from "../../assets/img/svg/lockIcon.svg";
import close from "../../assets/img/svg/close.svg";

import "./styles.scss";
import {
  createRequestPerfilDelete,
  createRequestPerfilModification,
} from "./services";
import Modal from "../../components/Modal";

const Perfil = ({ openOnboarding }) => {
  const [t, _] = useTranslation("global");
  const GC = useContext(GeneralContext);
  const { user } = GC;

  const [panel, setpanel] = useState("1");
  const [options, setoptions] = useState(false);
  const [deleteA, setdeleteA] = useState(false);
  const [editA, seteditA] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [infoEdit, setInfoEdit] = useState("");
  const [whyEdit, setWhyEdit] = useState("");
  const [messageModal, setMessageModal] = useState({
    title: "",
    description: "",
    type: 0,
  });

  const [reasonDeleteAccount, setReasonDeleteAccount] = useState("");
  const [reasonOtherMessage, setReasonOtherMessage] = useState("");
  const [buttonEnableDelete, setButtonEnableDelete] = useState(false);

  const handleOpenModal = (flag) => {
    if (flag) {
      setMessageModal({
        title: t("general.success_title"),
        description: t("general.request_will_be_attend"),
        type: "2",
      });
    }
    if (!flag) {
      setMessageModal({
        title: t("general.title_error"),
        description: t("general.error_message"),
        type: "1",
      });
    }

    setOpenModal(true);
  };

  const navigate = useNavigate();
  //send code and redirect
  const changePass = () => {
    recoveryPass({ email: user?.email })
      .then((resp) => {
        if (resp.status >= 200 && resp.status <= 299) {
          navigate("/CambiarPassword");
        } else {
          alert("Hubo un Error");
        }
      })
      .catch((err) => {
        alert(`Ah ocurrido un error ${err.response.data.msg}`);
      });
  };

  const requestEditProfile = async () => {
    const { success } = await createRequestPerfilModification(user.token, {
      title: `El usuario ${user.fullname} solicitado una modificación en su perfil`,
      description: `El usuario ${user.fullname} con id No ${user.client_investor_number} solicita una modificación en los datos de su perfil con los siguientes datos: (${infoEdit}). Razones (${whyEdit}) , por favor contactarlo lo mas pronto posible.`,
    });
    seteditA(false);
    handleOpenModal(success);
  };

  const requestDeleteProfile = async () => {
    let reasons = "";
    switch (requestDeleteProfile) {
      case "option1":
        reasons = "Ya complete mi objetivo de inversión";
        break;
      case "option2":
        reasons = "No deseo invertir más por el momento";
        break;
      case "option3":
        reasons = "No estoy satisfecho con la plataforma";
        break;
      case "option4":
        reasons = `Otro: ${reasonOtherMessage}`;
        break;
    }
    const { success } = await createRequestPerfilDelete(user.token, {
      title: `El usuario ${user.fullname} a solicitado eliminar o desactivar su perfil`,
      description: `El usuario ${user.fullname} con id No ${user.client_investor_number} solicita eliminar o desactivar su perfil. Razones (${reasons}) , por favor contactarlo lo mas pronto posible.`,
    });
    setdeleteA(false);
    handleOpenModal(success);
  };

  useEffect(() => {
    if (reasonDeleteAccount) {
      setButtonEnableDelete(true);
      if (reasonDeleteAccount === "option4" && reasonOtherMessage === "") {
        setButtonEnableDelete(false);
      } else {
        setButtonEnableDelete(true);
      }
      return;
    }

    setButtonEnableDelete(false);
  }, [reasonDeleteAccount, reasonOtherMessage]);

  return (
    <>
      <div className="perfil w-100">
        <div className="header">
          <div>
            <ProfilePicture />
            <span>{user?.fullname}</span>
          </div>
          <div
            className="kyc"
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          >
            <img src={Info} alt="" />
            <span>KYC</span>
            <div className={open ? "panelKyc" : "hidden"}>
              <img src={Info} alt="" />
              <div>
                <p>
                  {t("profile.description_1")}
                  <br />
                  {t("profile.description_2")}
                  <br /> {t("profile.description_4")}
                </p>
                <span>{t("profile.description_4")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="subHeader">
          <div className="options">
            <div
              onClick={() => setpanel("1")}
              className={panel === "1" ? `itemI` : `item`}
            >
              <span>{t("signup.step_1")}</span>
            </div>
            <div
              onClick={() => setpanel("2")}
              className={panel === "2" ? `itemI` : `item`}
            >
              <span>{t("signup.step_2")}</span>
            </div>
          </div>
          <div className="btns">
            <div onClick={() => navigate("/Retiros")} className="btn1">
              <img src={retiro} alt="" />
              <span>{t("investments.request_withdrawal")}</span>
            </div>
            <div className="containerOptions">
              <div onClick={() => setoptions(!options)} className="btn2">
                <img src={puntos} alt="" />
              </div>
              {options && (
                <div className="options">
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() => {
                      seteditA(true);
                      setoptions(!options);
                    }}
                  >
                    <img src={edit} alt="" />
                    {t("profile.edit_information")}
                  </div>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() => {
                      setReasonDeleteAccount("");
                      setReasonOtherMessage("");
                      setdeleteA(true);
                      setoptions(!options);
                    }}
                  >
                    <img src={deleteIcon} alt="" />
                    {t("profile.delete_account")}
                  </div>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() => {
                      localStorage.removeItem("omboarding");
                      setoptions(!options);
                      openOnboarding(true);
                    }}
                  >
                    <img src={play} alt="" />
                    {t("profile.see_profile")}
                  </div>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() => {
                      changePass();
                      setoptions(!options);
                    }}
                  >
                    <img src={lockIcon} alt="" />
                    {t("password_change.title")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {panel === "1" ? <FormPerfil user={user} /> : <FormBank user={user} />}
        <CustomModal
          open={deleteA}
          component={
            <div className="deleteM">
              <div className="deleteHeader">
                <span>{t("profile.delete_account")}</span>
                <img onClick={() => setdeleteA(false)} src={close} alt="" />
              </div>
              <div className="deleteTitle">
                <span>{t("profile.why_delete_account")}</span>
                <img src="" alt="" />
              </div>
              <div className="deleteBody">
                <form>
                  <div
                    onClick={() => setReasonDeleteAccount("option1")}
                    style={{ cursor: "pointer" }}
                  >
                    <input
                      type="radio"
                      value="option1"
                      checked={reasonDeleteAccount === "option1"}
                    />
                    <span>{t("profile.done_target")}</span>
                  </div>
                  <div
                    onClick={() => setReasonDeleteAccount("option2")}
                    style={{ cursor: "pointer" }}
                  >
                    <input
                      type="radio"
                      value="option2"
                      checked={reasonDeleteAccount === "option2"}
                    />
                    <span>{t("profile.dont_invest")}</span>
                  </div>
                  <div
                    onClick={() => setReasonDeleteAccount("option3")}
                    style={{ cursor: "pointer" }}
                  >
                    <input
                      type="radio"
                      value="option3"
                      checked={reasonDeleteAccount === "option3"}
                    />
                    <span>{t("profile.dont_agree")}</span>
                  </div>
                  <div
                    onClick={() => setReasonDeleteAccount("option4")}
                    style={{ cursor: "pointer" }}
                  >
                    <input
                      type="radio"
                      value="option4"
                      checked={reasonDeleteAccount === "option4"}
                    />
                    <span>{t("profile.other")}</span>
                  </div>
                  {reasonDeleteAccount == "option4" && (
                    <div className="inputA">
                      <input
                        className="input"
                        type="text"
                        placeholder={t("signup.write_here")}
                        onChange={(e) => setReasonOtherMessage(e.target.value)}
                      />
                    </div>
                  )}
                </form>
              </div>
              <button
                onClick={() => buttonEnableDelete && requestDeleteProfile()}
                className={
                  buttonEnableDelete
                    ? "boton-continuar-enabled"
                    : "boton-continuar-disabled"
                }
              >
                <span>{t("profile.send_request")}</span>
              </button>
            </div>
          }
        />
        <CustomModal
          open={editA}
          component={
            <div className="deleteM">
              <div className="deleteHeader">
                <span>{t("profile.edit_information")}</span>
                <img onClick={() => seteditA(false)} src={close} alt="" />
              </div>
              <div className="deleteTitle">
                <span>{t("profile.why_edit")}</span>
                <img src="" alt="" />
              </div>
              <div className="deleteBody">
                <div className="inputA">
                  <span>{t("profile.what_info_edit")}</span>
                  <input
                    onChange={(e) => {
                      setInfoEdit(e.target.value);
                    }}
                    placeholder={t("signup.write_here")}
                    type="text"
                  />
                </div>
                <div className="inputA">
                  <span>{t("profile.why_edit")}</span>
                  <input
                    onChange={(e) => {
                      setWhyEdit(e.target.value);
                    }}
                    placeholder={t("signup.write_here")}
                    type="text"
                  />
                </div>
              </div>
              <button
                onClick={(e) =>
                  infoEdit && whyEdit ? requestEditProfile() : null
                }
                className={
                  infoEdit && whyEdit
                    ? "boton-continuar-enabled"
                    : "boton-continuar-disabled"
                }
              >
                <span>{t("profile.send_request")}</span>
              </button>
            </div>
          }
        />
        <Modal
          type={messageModal.type}
          btnlabel={t("general.accept")}
          onConfirm={() => {
            setOpenModal(!openModal);
          }}
          onOpen={setOpenModal}
          open={openModal}
          title={messageModal.title}
          text={messageModal.description}
        />
      </div>
    </>
  );
};

export default Perfil;

import { useContext, useEffect, useState } from "react";
import {
  GetAccountsUser,
  requestWithdrawal,
  GetWithdrawalsUser,
  DownloadBill,
  getChargeAccount,
  getBalanceUser,
} from "../services";
import GeneralContext from "../../../context/GeneralContext";

export const useRetiro = () => {
  const { user } = useContext(GeneralContext);
  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = async (withdrawal) => {
    setIsLoading(true);
    try {
      const resp = await requestWithdrawal(user.token, withdrawal);
      setIsLoading(false);
      return resp;
    } catch (error) {
      setIsLoading(false);
      return false;
    }
  };

  const getAccountsUser = async () => {
    try {
      const { data } = await GetAccountsUser(user.token);
      return data;
    } catch (error) {
      return { success: false };
    }
  };

  const getWithdrawals = async () => {
    setIsLoading(true);
    try {
      const { data } = await GetWithdrawalsUser(user.token);
      const { withdrawal } = data;
      setIsLoading(false);
      return withdrawal.length > 0 ? withdrawal.reverse() : [];
    } catch (error) {
      setIsLoading(false);
      return [];
    }
  };

  const downloadBill = async (bill_id) => {
    try {
      const { success } = await DownloadBill(user.token, bill_id);
      return success;
    } catch (error) {
      return false;
    }
  };

  const searchChargeAccount = async (contract_number, date) => {
    try {
      const { success, data } = await getChargeAccount(
        user.token,
        contract_number,
        date
      );
      return { success, data };
    } catch (error) {
      return { success: false };
    }
  };

  const getBalanceService = async () => {
    try {
      const { status, data } = await getBalanceUser(user.token);
      if (status >= 200 && status <= 299) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return {
    sendRequest,
    getAccountsUser,
    downloadBill,
    getWithdrawals,
    isLoading,
    searchChargeAccount,
    getBalanceService,
  };
};

import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const GlobalButton = ({
  onClick,
  disabled,
  label,
  type = "primary",
  className,
}) => {
  const buttonClassName = `button ${disabled ? "disabled" : ""} ${
    type === "primary" ? "primary" : "secondary"
  } ${className}`;
  return (
    <button className={buttonClassName} onClick={onClick} disabled={disabled}>
      {label}
    </button>
  );
};

GlobalButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

export default GlobalButton;

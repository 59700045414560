import { useState } from "react";
import Detail from "./Detail";
import Mensajes from "./Mensajes";

import "./styles.scss";

const Inbox = () => {
  const [page, setpage] = useState("1");
  const [objectInfo, setObjectInfo] = useState(null);

  const handleSetPage = (newPage, object) => {
    setpage(newPage);
    setObjectInfo(object);
  };

  return (
    <>
      {page === "1" ? (
        <Mensajes
          setpage={handleSetPage}
        />
      ) : (
        <Detail setpage={handleSetPage} objectInfo={objectInfo} />
      )}
    </>
  );
};
export default Inbox;

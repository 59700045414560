import { useRef, useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useContractUpload } from "./hooks/useContractUpload";

import Modal from "../Modal";
import Loader from "../loader/loader";
import GeneralContext from "../../context/GeneralContext";
import Notification from "../Notification/Notification";

import arrowBack from "../../assets/img/png/flecha-atras.png";
import infoBIcon from "../../assets/img/svg/infoB.svg";
import "./styles.scss";

const ContractUpload = () => {
  const [t, i18n] = useTranslation("global");
  const { user } = useContext(GeneralContext);
  const { state } = useLocation();
  const [FileD, setFile] = useState("");
  const [ClickFile, setClickFile] = useState("");
  const [FileO, setFileO] = useState("");
  const navigate = useNavigate();
  const fileInput = useRef(null);
  const fileInputO = useRef(null);
  const [open, setOpen] = useState(false);

  const { validateSign, isLoading } = useContractUpload();

  const handleClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };
  const handleFile = (file) => {
    setFile(file.target?.files[0]);
  };
  const handleClickO = () => {
    if (fileInputO.current) {
      fileInputO.current.click();
    }
  };
  const handleFileO = (file) => {
    setFileO(file.target?.files[0]);
  };
  function abrirDocumento() {
    if (FileD) {
      var url = URL.createObjectURL(FileD);
      window.open(url, "_blank");
    }
  }

  const [notification, setNotification] = useState(null);

  const showNotification = (message, description, type) => {
    setNotification({ message, description, type });
  };

  const processValidateSign = async () => {
    const { SignNowResponse } = await validateSign(state.signnowIdDocument);

    const status = SignNowResponse?.field_invites[0]?.status;
    if (status === "fulfilled") {
      showNotification(
        t("invest.validate_contract.success_notification_title"),
        t("invest.validate_contract.success_notification_description"),
        "success"
      );
      setTimeout(() => {
        navigate("/Home", { state: 2 });
      }, 2000);
    } else {
      showNotification(
        t("invest.validate_contract.failed_notification_title"),
        t("invest.validate_contract.failed_notification_description"),
        "error"
      );
    }
  };

  const closeNotification = () => {
    setNotification(null);
  };

  useEffect(() => {
    processValidateSign();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {notification && (
            <Notification
              message={notification?.message}
              type={notification?.type}
              description={notification?.description}
              onClose={closeNotification}
            />
          )}

          <div className="balance containerCon">
            <div
              onClick={() => navigate("/Home", { state: 6 })}
              className="back"
            >
              <img src={arrowBack} alt="" />
              <span>{t("general.back")}</span>
            </div>
            <div className="right">
              <div className="description">
                {state.intermediate_states === "Sign Pending" && (
                  <>
                    <span>
                      {t(
                        "invest.validate_contract.pending_sign_contract_message"
                      )}
                    </span>
                    <br />
                    <p>
                      {t("invest.validate_contract.contract_no")}{" "}
                      {state.contract_number}
                    </p>
                    <br />
                  </>
                )}
              </div>
              <div className="titleSeccion">
                <span>{t("invest.validate_contract.title_sign_contract")}</span>
              </div>
              <div className="panelInfo">
                <img src={infoBIcon} alt="" />
                <p>
                  {t("invest.validate_contract.message_email_1")}{" "}
                  <span>{user?.email}</span>{" "}
                  {t("invest.validate_contract.message_email_2")}
                </p>
              </div>
              <div
                onClick={() => {
                  //uploadDocumentSigned(FileD);
                  processValidateSign();
                }}
                className={`btnSend`}
              >
                <span>{t("invest.validate_contract.btn_message")}</span>
              </div>
            </div>
            <Modal
              type="1"
              onConfirm={() => {
                if (ClickFile === "1") {
                  setFile("");
                } else {
                  setFileO("");
                }
                setOpen(false);
              }}
              cancel
              onOpen={setOpen}
              open={open}
              title={"¿Estás seguro de eliminar el archivo?"}
              text={
                "Si eliminas el archivo, este no se recuperará y tendrás que adjuntar nuevamente un documento."
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default ContractUpload;

import { useContext, useState } from "react";
import GeneralContext from "../../../context/GeneralContext";
import { uploadReceipt, getDataCharts } from "../services";
import { useNavigate } from "react-router-dom";

export const useHome = () => {
  const navigate = useNavigate();
  const { user } = useContext(GeneralContext);

  const [isLoading, setIsLoading] = useState(false);

  const uploadDocument = async (FileD) => {
    setIsLoading(true);
    const success = await uploadReceipt(user.token, FileD);
    setIsLoading(false);
    success && navigate("/Home");
  };

  const getDataChartFinance = async () => {
    if(!user) return false
    const { data } = await getDataCharts(user?.token);
    return data.success && data.result;
  };

  return { uploadDocument, getDataChartFinance, isLoading };
};

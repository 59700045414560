import { useRef, useState } from "react";
import { useNavigate } from "react-router";

import { useHome } from "./hook/useHome";
import Loader from "../../components/loader/loader";
import { useTranslation } from "react-i18next";
import arrowBack from "../../assets/img/svg/back.svg";
import uploadIcon from "../../assets/img/svg/upload.svg";
import fileIcon from "../../assets/img/svg/file.svg";
import deleteIcon from "../../assets/img/svg/delete.svg";
import bankIcon from "../../assets/img/svg/bank.svg";
import bank1Icon from "../../assets/img/svg/bank1.svg";
import infoBIcon from "../../assets/img/svg/infoB.svg";

import "./styles.scss";

const Accounts = () => {
  const [t, _] = useTranslation("global");
  const [FileD, setFile] = useState("");
  const navigate = useNavigate();
  const fileInput = useRef(null);

  const { uploadDocument, isLoading } = useHome();

  const handleClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };
  const handleFile = (file) => {
    setFile(file.target?.files[0]);
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="balance" style={{paddingLeft: '5%'}}>
          <div onClick={() => navigate("/Home")} className="back">
            <img src={arrowBack} alt="" />
            <span style={{marginTop: '1px'}}>Volver</span>
          </div>
          <div className="container fix-scroll container-mobil" style={{height: '120vh', marginBottom: '30px'}}>
            <div className="left">
              <div className="title">
                <img src={bankIcon} alt="" />
                <span>Cuentas bancarias</span>
              </div>
              <div className="description">
                <span>Puedes consignar a una las siguientes cuentas:</span>
              </div>
              <div className="banks">
                <div className="bank" style={{width: '95%', height: 'auto'}}>
                  <div className="bankTitle">
                    <img src={bank1Icon} alt="" />
                    <span>{t("cardsBank.nameSantander")}</span>
                  </div>
                  <div className="bankD">
                    <span></span>
                  </div>
                  <div className="bankD">
                    <span>{t("cardsBank.cabGroup")}</span>
                  </div>
                  <div className="bankD">
                    <span>{t("cardsBank.accountNumber")}8947658596</span>
                  </div>
                  <div className="bankD">
                    <span>{t("cardsBank.ruteNumber")}231372691</span>
                  </div>
                  <div className="bankD">
                    <span>{t("cardsBank.swiftNumber")}SVRNUS33</span>
                  </div>
                  <div className="bankD">
                    <span>{t("cardsBank.address")}1401 Brickell Avenue Miami, FL 33131</span>
                  </div>
                </div>
                <div className="bank" style={{width: '95%', height: 'auto'}}>
                  <div className="bankTitle">
                    <img src={bank1Icon} alt="" />
                    <span>{t("cardsBank.nameCiti")}</span>
                  </div>
                  <div className="bankD">
                    <span></span>
                  </div>
                  <div className="bankD">
                    <span>{t("cardsBank.cabGroup")}</span>
                  </div>
                  <div className="bankD">
                    <span>{t("cardsBank.accountNumber")}9154176685</span>
                  </div>
                  <div className="bankD">
                    <span>{t("cardsBank.ruteNumber")}266086554</span>
                  </div>
                  <div className="bankD">
                    <span>{t("cardsBank.swiftNumber")}CITIUS33</span>
                  </div>
                  <div className="bankD">
                    <span>{t("cardsBank.address")}2750 Aventura Blvd Aventura, FL 33180</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="right" style={{marginRight: '5%'}}>
              <div className="panelInfo">
                <img src={infoBIcon} alt="" />
                <p>{`El equipo CAB realizará una validación de los documentos y certificaciones
                     enviadas. Este proceso puede tardar de 3-5 días.`}</p>
              </div>
              <div className="description">
                <span>
                  Adjunta aquí el comprobante de pago y los documentos emitidos
                  por la entidad bancaria que certifiquen la consignación
                </span>
              </div>
              {FileD === "" ? (
                <div onClick={handleClick} className="btn">
                  <span>Adjuntar documento</span>
                  <img src={uploadIcon} alt="" />
                  <input
                    accept=".pdf"
                    ref={fileInput}
                    type="file"
                    onChange={(e) => handleFile(e)}
                  />
                </div>
              ) : (
                <>
                  <div className="fileUpload">
                    <div>
                      <img src={fileIcon} alt="" />
                      <span>{FileD?.name}</span>
                    </div>
                    <img onClick={() => setFile("")} src={deleteIcon} alt="" />
                  </div>
                  <div
                    className="btnSend"
                    onClick={(e) => {
                      e.preventDefault();
                      uploadDocument(FileD);
                    }}
                  >
                    <span>Enviar</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Accounts;

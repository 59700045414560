import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArticleDetail } from "./services";
import GeneralContext from "../../context/GeneralContext";

import arrowBack from "../../assets/img/svg/back.svg";
import "./styles.scss";

const Article = () => {
  const { user } = useContext(GeneralContext);

  const navigate = useNavigate();

  const [articulo, setArticulo] = useState([]);

  const { state } = useLocation();

  const getDateFormat = (fechaString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const fecha = new Date(fechaString);
    const mesCapitalizado = fecha.toLocaleDateString('es-ES', options)
      .replace(/\b\w/g, l => l.toUpperCase())
      .replace(/(\d+) (\w+) (\d+)/, '$1 de $2 del $3');

    return mesCapitalizado;
  }

  useEffect(() => {
    ArticleDetail(state ?? "", user.token)
      .then((resp) => {
        if (resp.status >= 200 && resp.status <= 299) {
          setArticulo(resp.data);
        } else {
          alert("Hubo un error");
        }
      })
      .catch((err) => {
        //alert(`Ha ocurrido un error: ${err}`);
      });
  }, [navigate]);

  return (
    <div className="containerA">
      <div className="article">
        <div onClick={() => navigate("/Home")} className="back">
          <img src={arrowBack} alt="" />
          <span style={{marginTop: '1px'}}>Volver</span>
        </div>
        <div>
          <div className="headerA">
            <h2>{articulo.title}</h2>
            <p>{articulo.resume} </p>
          </div>
          <div className="bodyA">
            <div>
              <span>Por {articulo.autor}</span>
              <span>{articulo.publication_date ? articulo.publication_date : getDateFormat(articulo.createdAt) }</span>
            </div>
            <img
              src={articulo.location_image}
              alt=""
              style={{ height: "300px" }}
            />
            <p>{articulo.content} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;

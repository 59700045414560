import React from "react";

import logoCab from "../../assets/img/svg/Logo_CAB.svg"

import './style.scss'

const Loader = () => {
  return (
    <div className="loader">
      <img className="imgLogo" src={logoCab} alt="Logo" />
    </div>
  );
};

export default Loader;

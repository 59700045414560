import axios from "axios";
import { URL_API } from "../../config/config";

const API_URL = `${URL_API}/api`;

const requestLogin = async (body) => {
    try {
        return await axios.post(`${URL_API}/api/authentication/login`, body);
    } catch (error) {
        const { response } = error
        return Promise.reject({
            status: response.status,
            msg: response.data.msg
        });
    }
}

const requestResendOtp = async (body) => {
    try {
        return await axios.post(`${URL_API}/api/authentication/resend-otp`, body);
    } catch (error) {
        console.error(error);
        return Promise.reject('Catch ' + JSON.stringify(error));
    }
}

export { requestLogin, requestResendOtp }
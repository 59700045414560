import { TipoPersonal, Idiomas, Movimientos, Countries } from "../../utils/constants";
import React, { useEffect, useState } from "react";
import InputSelect from "../SignUp/InputSelect";
import "../Perfil/styles.scss";
import "./styles.scss";
import { useNavigate, useLocation } from "react-router";
import Modal from "../../components/Modal";
import { useContracts } from "./hooks/useContracts";
import Loader from "../../components/loader/loader";
import { useTranslation } from "react-i18next";
import { CountriesTraslation, TipoPersonalEnglish } from "../../utils/constants.js"

import arrowBack from "../../assets/img/svg/back.svg";

const SolicitudCertificado = ({ cambiarPaso, setForm }) => {
  const [t, _] = useTranslation("global");
  const navigate = useNavigate();
  const { state } = useLocation();
  const { sendAndDownloadCertificate } = useContracts();

  const [formulario, setFormulario] = useState({
    producto: "",
    country: "",
    type: "",
    city: "",
    title: "",
    idioma: "",
    by: "",
    movimientos: "0",
  });
  const [open, setOpen] = useState(false);
  const [boton, setBoton] = useState("boton-continuar-disabled"); // State para claseName de boton "Continuar"
  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    const data = {
      contract_number: state.contract_number,
      type_certificate: formulario.movimientos,
      country: formulario.idioma === "Inglés"? CountriesTraslation.find(c => c.spanish === formulario.country)?.english : formulario.country,
      city: formulario.city,
      personal_title: formulario.idioma === "Inglés"? TipoPersonalEnglish.find(t => t.spanish === formulario.title)?.english : formulario.title,
      language: formulario.idioma,
      directTo: formulario.by,
    };
    if (comprobarFormulario()) {
      setIsLoading(true);
      const success = await sendAndDownloadCertificate(data);
      if (success) {
        setOpen(true);
      }
      setIsLoading(false);
    }
  };

  //TODO Establecer comprobacion general
  const comprobarFormulario = React.useCallback(() => {
    return (
      formulario.producto !== "" &&
      formulario.country !== "0" &&
      formulario.city !== "" &&
      formulario.title !== "0" &&
      formulario.idioma !== "0" &&
      formulario.by !== "" &&
      formulario.movimientos !== "0"
    );
  }, [formulario]);

  useEffect(() => {
    setFormulario({ ...formulario, producto: state.contract_number });
  }, []);

  useEffect(() => {
    if (comprobarFormulario()) {
      setBoton("boton-continuar-enabled");
    } else {
      setBoton("boton-continuar-disabled");
    }
  }, [formulario, comprobarFormulario, setBoton]);

  const handleSelectChange = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="perfil solicitud">
          <div
            onClick={() =>
              navigate("/Portafolio/Detail", {
                state,
              })
            }
            className="back"
          >
            <img src={arrowBack} alt="" /> <span style={{marginTop: '5px'}}>{t("general.back")}</span>
          </div>
          <div className="title">
            <span>{t("certificate.title")}</span>
          </div>
          <div className="text">
            <span>{t("certificate.description")}</span>
          </div>
          <div className="formulario-registro">
            <div className="container-campos">
              <div className="part-space">
                <div className="espacio-input">
                  <p className="titulo-2">{t("certificate.product")}</p>
                  <input
                    name="producto"
                    type="text"
                    className="input"
                    placeholder={t("signup.write_here")}
                    readOnly
                    value={state.contract_number}
                  ></input>
                </div>
                <div className="espacio-input">
                  <p className="titulo-2">{t("certificate.country")}</p>
                  <InputSelect
                    form={formulario}
                    elements={Countries}
                    name="country"
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
              <div className="part-space">
                <div className="espacio-input">
                  <p className="titulo-2">{t("certificate.product_type")}</p>
                  <input
                    type="text"
                    className="input"
                    placeholder={t("signup.write_here")}
                    readOnly
                    value={state.descriptionContract.type}
                    onChange={(e) =>
                      setFormulario({ ...formulario, type: e.target.value })
                    }
                  ></input>
                </div>
                <div className="espacio-input-form">
                  <p className="titulo-2">{t("certificate.city")}</p>
                  <input
                    type="text"
                    className="input"
                    style={{width: '390px'}}
                    placeholder={t("signup.write_here")}
                    onChange={(e) =>
                      setFormulario({ ...formulario, city: e.target.value })
                    }
                  ></input>
                </div>
              </div>
              <div className="part-space">
                <div className="espacio-input-form" style={{marginRight: '0px'}}>
                  <p className="titulo-2">{t("certificate.personal_title")}</p>
                  <InputSelect
                    form={formulario}
                    elements={TipoPersonal}
                    name="title"
                    onChange={handleSelectChange}
                  />
                </div>
                <div className="espacio-input">
                  <p className="titulo-2">{t("certificate.language")}</p>
                  <InputSelect
                    form={formulario}
                    elements={Idiomas}
                    name="idioma"
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
              <div className="part-space">
                <div className="espacio-input">
                  <p className="titulo-2">{t("certificate.direct_to")}</p>
                  <input
                    type="text"
                    className="input"
                    placeholder={t("signup.write_here")}
                    onChange={(e) =>
                      setFormulario({ ...formulario, by: e.target.value })
                    }
                  ></input>
                </div>
                <div className="espacio-input">
                  <p className="titulo-2">{t("certificate.all_movements")}</p>
                  <InputSelect
                    form={formulario}
                    elements={Movimientos}
                    name="movimientos"
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
              <button
                disabled={!comprobarFormulario()}
                className={boton}
                onClick={() => submit()}
              >
                {t("certificate.request_certificate")}
              </button>
            </div>
          </div>
          <Modal
            type="2"
            onConfirm={() => {
              setOpen(false);
              navigate("/Portafolio/Detail", {
                state,
              });
            }}
            onOpen={setOpen}
            open={open}
            title={t("certificate.modal.title")}
          />
        </div>
      )}
    </>
  );
};

export default SolicitudCertificado;

import React, { useContext, useRef, useState } from 'react'
import GeneralContext from '../../context/GeneralContext';
import {  uploadUserProfilePhoto } from './services';
import { guardarSesion } from '../../utils/Utils';
import Loader from "../../components/loader/loader";

import noPicture from "../../assets/img/png/no-profile-picture.png"
import addPhoto from "../../assets/img/png/add-photo.png"

function ProfilePicture() {
  const profilePictureRef = useRef(null);
  const {user,modificarUser} = useContext(GeneralContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [profilePicture, setProfilePicture] = useState(user?.profile_picture);

  const handleImageClick = () => {
    if (profilePictureRef.current) {
      profilePictureRef.current.click();
    }
  };

  const handleFileChange = async(event) => {
      const file = event.target.files[0];
      if (!file) return;   
      setIsLoading(true)
      try{
      const formData = new FormData();
      formData.append('file', file);
      formData.append('id', user._id)      
      
      const updatedReponse = await uploadUserProfilePhoto(formData, user.token);
      const updatedUser = { ...user, profile_picture: updatedReponse.data.url};
      modificarUser(updatedUser)
      guardarSesion(updatedUser);  
            
     
      const reader = new FileReader();
      reader.onloadend = () => {
        // The result will be the Base64 encoded string
        const base64String = reader.result;
        setProfilePicture(base64String)         
      };

      reader.readAsDataURL(file); 
      setIsLoading(false)

      } catch (error) {
        alert("Error actualizando la foto de perfil")
        setIsLoading(false)
      }
      
  };

 
  return (
      <div className="imageContainer" 
               onMouseEnter={() => setIsHovered(true)}
               onMouseLeave={() => setIsHovered(false)}
               onClick={handleImageClick}
               >                
             <img className={`profileImage ${isHovered && 'opacity'} ${!profilePicture && "notProfileImage"}`} 
                  src={profilePicture || noPicture}
                  alt="profile"  />
             <img className={`profileImageHover ${!isHovered && 'hidden'}`} src={addPhoto} alt="profile hover"  />
             <input
                type="file"
                ref={profilePictureRef}
                style={{ display: 'none' }}
                accept=".png, .jpg, .jpeg"
                onChange={handleFileChange}
              />
          {isLoading && <Loader />}
          </div>
  )
}

export default ProfilePicture

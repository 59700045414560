import React, { useEffect } from "react";
import checkBlueIcon from "../../assets/img/svg/check-blue.svg";
import warningBlueIcon from "../../assets/img/svg/warning-blue.svg";
import infoIcon from "../../assets/img/svg/info.svg";

import "./styles.scss";

const Notification = ({ message, description, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  return (
    <div className={`notification ${type}`}>
      {type === "success" && <img src={checkBlueIcon} alt="check" />}
      {type === "error" && <img src={warningBlueIcon} alt="warning" />}
      {type === "info" && <img src={infoIcon} alt="info" />}
      <div>
        <span className="title">{message}</span>
        {type != "info" ? <p>{description}</p> : ""}
      </div>
    </div>
  );
};

export default Notification;

import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import closeIcon from "../../assets/img/svg/close.svg";
import utilityBIcon from "../../assets/img/svg/IconUtilidadesB.svg";
import iconBalanceB from "../../assets/img/svg/IconBalanceB.svg";
import iconInversionB from "../../assets/img/svg/IconInversionB.svg";

const ModalRetiro = ({ onOpen, open }) => {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const [select, setselect] = useState(null);
  return (
    <div className={open ? `modal` : `hidden`}>
      <div className="contentPay">
        <div className="title">
          <span>{t("withdrawals.modal.message_withdrawal")}</span>
          <img
            onClick={() => {
              onOpen(false);
              setselect(null);
            }}
            src={closeIcon}
            alt=""
          />
        </div>
        <div className="pays">
          <div
            onClick={() => setselect("1")}
            className={select === "1" ? "select" : ""}
          >
            <img src={utilityBIcon} alt="" />
            <span>{t("withdrawals.modal.withdrawal_utilities")}</span>
          </div>
          <div
            onClick={() => setselect("2")}
            className={select === "2" ? "select" : ""}
          >
            <img src={iconBalanceB} alt="" />
            <span>{t("withdrawals.modal.withdrawal_balance")}</span>
          </div>
          <div
            onClick={() => setselect("3")}
            className={select === "3" ? "select" : ""}
          >
            <img src={iconInversionB} alt="" />
            <span>{t("withdrawals.modal.withdrawal_investment_capital")}</span>
          </div>
        </div>
        <div
          className={select === null ? "btn-disable" : "btn"}
          onClick={() => {
            select !== null &&
              navigate("/Retiros/Solicitud", { state: select });
          }}
        >
          <span>{t("invest.modal_pay.continue")}</span>
        </div>
      </div>
    </div>
  );
};

export default ModalRetiro;

import React from "react";
import { MyInversionsItems } from "../MyInversions/MyInversionsItems";

export const MyInversions = ({ Inversions }) => {
  return (
    <>
      <div className="card-list">
        {Inversions &&
          Inversions.length > 0 &&
          Inversions.map((i) => <MyInversionsItems inversion={i} key={i.contract_number} />)}
      </div>
    </>
  );
};

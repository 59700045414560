import axios from "axios";
import { URL_API } from "../../config/config";

const API_URL = `${URL_API}/api`;

export const GetSettlementsUser = (token) => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return axios.get(`${API_URL}/settlements`);
  } catch (error) {
    console.log("🚀 ~ file: services.js:24 ~ GetAccountsUser ~ error:", error);
    return { success: false };
  }
};

import axios from "axios";
import { URL_API } from "../../config/config";

const API_URL = `${URL_API}/api`;

export const CreateContract = async (token, numberPortfolio, body) => {
  try {
    const { data, headers } = await axios({
      url: `${API_URL}/user/contract/${numberPortfolio}`,
      data: body,
      method: "POST",
      // responseType: "arraybuffer",
      headers: { Authorization: `Bearer ${token}` },
    });
    //const contractId = downloadBlob(data, headers);
    return {
      success: true, contractId: numberPortfolio, contract: {
        signnowIdDocument: data.idDocumentSignNow
      }
    };
  } catch (error) {
    return { success: false };
  }
};

const downloadBlob = (data, headers) => {
  const contentDescription = headers.get("X-File-Description");
  const description = JSON.parse(contentDescription);
  const fileName = description.contractNumber;
  const contractId = description.contractId;
  const nombreArchivo = fileName ? `${fileName}.docx` : `${contractId}.docx`;

  // Crear una URL del Blob
  const url = window.URL.createObjectURL(new Blob([data]));

  // Crear un enlace temporal y hacer clic en él para descargar el archivo
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", nombreArchivo);
  document.body.appendChild(link);
  link.click();

  // Limpiar la URL del objeto Blob
  window.URL.revokeObjectURL(url);
  return contractId;
};

export const uploadContract = async (token, contractId, file) => {
  const multipart = new FormData();
  multipart.append("contract", file);
  const { data } = await axios({
    url: `${API_URL}/user/contract/${contractId}`,
    method: "PUT",
    data: multipart,
    headers: { Authorization: `Bearer ${token}` },
  });  
  return data.success;
};

export const getBalance = async (token) => {
  try {
    const { data } = await axios({
      url: `${API_URL}/wallet`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const { success, wallet } = data;
    return { success, wallet };
  } catch (error) {
    const { code } = error.response.data;
    console.log(
      "🚀 ~ file: services.js:20 ~ CreateContract ~ error:",
      error.response.data.msg
    );
    return { success: false, code };
  }
};

export const validateSignContract = async (documentId, token) => {
  try {
    const { data, headers } = await axios({
      url: `${API_URL}/validate_document/${documentId}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const { SignNowResponse } = data;
    return { SignNowResponse };
  } catch (error) {
    const { code } = error.response.data;
    console.log(
      "🚀 ~ file: services.js:20 ~ CreateContract ~ error:",
      error.response.data.msg
    );
    return { success: false, code };
  }
};

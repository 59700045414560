import axios from "axios";
import { URL_API } from "../../config/config";

const API_URL = `${URL_API}/api`;

const recoveryPass = (body) => {
    try {
        return axios.post(`${API_URL}/authentication/password-reset`,body);
      } catch (error) {
        return Promise.reject('Catch ' + JSON.stringify(error));
      }
}

const resetPassword = (params,body) => {
    try {
        return axios.post(`${API_URL}/authentication/password-reset/${params.email}/${params.code}`, body);
      } catch (error) {
        return Promise.reject('Catch ' + JSON.stringify(error));
      }
}


export {recoveryPass, resetPassword};
import { useNavigate, useLocation } from "react-router";

import { formatNumber, formatLongDate } from "../../utils/Utils";
import { useContracts } from "./hooks/useContracts";
import { useEffect, useState } from "react";
import { useRetiro } from "../Retiros/hooks/useRetiro";
import Loader from "../../components/loader/loader";
import { useTranslation } from "react-i18next";
import NotificationPopUp from "../../components/Notification/Notification";
import Modal from "../../components/Modal";

import arrowBack from "../../assets/img/svg/back.svg";
import retiroIcon from "../../assets/img/svg/retiro.svg";
import dotsIcon from "../../assets/img/png/Dots.png";
import inversionIcon from "../../assets/img/svg/IconInversion.svg";
import utilitiesIcon from "../../assets/img/svg/iconUtilidades.svg";
import comercialIcon from "../../assets/img/svg/IconComercial.svg";

import "./styles.scss";

const Info = () => {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const { state } = useLocation();
  const { downloadChargeAccount, downloadContract, sendLiquidationBackOffice, getUtilities } =
    useContracts();
  const { searchChargeAccount } = useRetiro();

  const [isLoading, setIsLoading] = useState(false);
  const [btnMore, setBtnMore] = useState(false);
  const [popUp, setPopUp] = useState(null);
  const [chargeAccount, setChargeAccount] = useState(null);
  const [totalProfits, setTotalProfits] = useState(0)

  const [openModalLiquidationBackOffice, setOpenModalLiquidationBackOffice] =
    useState(false);

  useEffect(() => {
    processSearchChargeAccount();

    console.log('ESTADO:', state);
    getProfits(state.contract_number)
  }, []);

  const processChargingAccount = async () => {
    try {
      if (chargeAccount) {
        window.open(chargeAccount);
      } else {
        setIsLoading(true);
        const { success, idDocumentSignNow } = await downloadChargeAccount(
          state.contract_number
        );
        if (success) {
          navigate("/Contrato/SolicitudCobro", {
            state: {
              success,
              idDocumentSignNow,
            },
          });
        } else {
          showNotification(
            t("invest.validate_contract.failed_notification_title"),
            t("contracts.error_charge_account"),
            "error"
          );
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const processSearchChargeAccount = async () => {
    try {
      const { success, data } = await searchChargeAccount(
        
        state.contract_number,
        new Date()
      );

      if (
        data?.BillingStatement.status &&
        data.BillingStatement.status === "Pending sign"
      ) {
        navigate("/Contrato/SolicitudCobro", {
          state: {
            success,
            idDocumentSignNow: data.signnowIdDocument,
          },
        });
      }

      if (!success) {
        setChargeAccount(null);
      } else {
        const { year, status, months, document_url_location } =
          data.BillingStatement;
        const yearD = new Date().getFullYear().toString();

        const actualMonth = new Date().toLocaleString("default", {
          month: "long",
        });

        const containsMonth = months
          .toLowerCase()
          .includes(actualMonth.toLowerCase());
        if (status === "Signed" && containsMonth) {
          setChargeAccount(document_url_location);
        } else {
          setChargeAccount(null);
        }
      }
    } catch (error) {
      setChargeAccount(null);
    }
  };

  const processDownloadContract = async () => {
    setIsLoading(true);
    await downloadContract(state.signnowIdDocument);
    setIsLoading(false);
  };

  const typePortfolio = [
    { type: "Renta Fija", class: "card1" },
    { type: "Renta Fija premium", class: "card2" },
    { type: "Renta Variable", class: "card3" },
    { type: "Renta Cripto", class: "card4" },
    { type: "OTC", class: "card5" },
    { type: "hecho a tu medida", class: "card6" },
  ];

  const activateBtnMore = () => {
    setBtnMore(!btnMore);
  };

  const showNotification = (message, description, type) => {
    setPopUp({ message, description, type });
  };

  const closeNotification = () => {
    setPopUp(null);
  };

  const processSendLiquidationBackOffice = async () => {
    setIsLoading(true);
    const { success } = await sendLiquidationBackOffice(state.contract_number);
    success && setOpenModalLiquidationBackOffice(true);
    setIsLoading(false);
  };

  const getProfits = async (contract_number) => {
    setIsLoading(true);
    const total_profits = await getUtilities(contract_number);
    console.log('CONTRATO ', total_profits);
    setTotalProfits(total_profits)
    if (total_profits) {
      console.log('entraa!!!');
    }
    setIsLoading(false);
    return total_profits
  };

  return (
    <>
      {popUp && (
        <NotificationPopUp
          message={popUp.message}
          type={popUp.type}
          description={popUp.description}
          onClose={closeNotification}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="InfoC">
          <div
            onClick={() => navigate("/Home", { state: "3" })}
            className="back"
          >
            <img src={arrowBack} alt="" /> <span>{t("general.back")}</span>
          </div>
          <div className="header">
            <h1>
              {t("investments.briefcase")} # {state.portfolio}
            </h1>
            <div className="buttons-header">
              <div onClick={() => navigate("/Retiros")} className="btnR">
                <img src={retiroIcon} alt="" />
                <span>{t("investments.request_withdrawal")}</span>
              </div>
              <div style={{ position: "relative" }}>
                <div
                  className={`btnMore ${btnMore ? "active" : ""}`}
                  onClick={() => activateBtnMore()}
                >
                  <img src={dotsIcon} alt="" />
                </div>
                <ul className="dropdown-list">
                  <li
                    onClick={() =>
                      navigate("/Contrato/Facturas", {
                        state,
                      })
                    }
                  >
                    {t("movements.table.see_invoice")}
                  </li>
                  <li>
                    <a href="tu_link_aqui" download={state.contract_url_location}>
                      {t("contracts.see_contract")}
                    </a>
                  </li>
                  <li>{t("contracts.see_more")}</li>
                  <li
                    onClick={() =>
                      navigate("/SolicitudCertificado", {
                        state,
                      })
                    }
                  >
                    {t("contracts.request_certificate")}
                  </li>
                  <li onClick={() => processChargingAccount()}>
                    {chargeAccount
                      ? t("certificate.see_billing_statement")
                      : t("contracts.request_charge_account")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="body">
            <div
              className={`cardInfo ${
                typePortfolio.find((t) => t.type === state.descriptionContract.type)?.class
                || state.descriptionContract.color_card
              } ${state.descriptionContract.icon_card}`}
            >
              <h6>
                {t("investments.briefcase")} #{state.portfolio}
              </h6>
              <div>
                <span>{t("my_investment.portfolio_type")}</span>
                <span>
                  {t("investments.briefcase")} {state.descriptionContract.type}
                </span>
              </div>
              <div>
                <span>{t("my_investment.investment_made")}</span>
                <span>
                  $ {formatNumber(state.investment[0].amount - state.fee_admin)}{" "}
                  USD
                </span>
              </div>
              <div>
                <span>{t("investments.cost_effectiveness")}</span>
                <span>
                  {state.cost_effectiveness}{" "}
                  {state.payment_period_cost_effectiveness}
                </span>
              </div>
              <div>
                <span>{t("movements.table.status")}</span>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      alignSelf: "center",
                      marginTop: "5px",
                      marginRight: "10px",
                      backgroundColor:
                        state.status !== "Active" ? "#C28000" : "#26C485",
                    }}
                  ></div>
                  {state.status === "Active" ? (
                    <div style={{ marginTop: "5px" }}>
                      <span>
                        {t("contracts.active_from")}{" "}
                        {formatLongDate(state.active_date)}
                      </span>
                    </div>
                  ) : (
                    <span style={{ marginTop: "5px" }}>
                      {`
                    ${t("my_investment.message_activation_1")}

                    ${state.days_for_activation} ${t(
                        "my_investment.message_activation_2"
                      )}
                  `}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="cardsI">
              <div className="cardI">
                <div className="title">
                  <div>
                    <img src={inversionIcon} alt="" />
                    <span>{t("contracts.investment")}</span>
                  </div>
                  <span>USD</span>
                </div>
                <span className="money">
                  $ {formatNumber(state.investment[0].amount - state.fee_admin)}
                </span>
                <span>{formatLongDate(state.createdAt)}</span>
              </div>
              <div className="cardI cardIMid">
                <div className="title">
                  <div>
                    <img src={utilitiesIcon} alt="" />
                    <span>{t("home.utilities")}</span>
                  </div>
                  <span>USD</span>
                </div>
                <span className="money">
                  {/* $ {formatNumber(state.utility_amount)} */}
                  $ {formatNumber(totalProfits)}
                </span>
                <span>
                  {state.date_utility && formatLongDate(state.date_utility)}
                </span>
              </div>
              <div className="cardI">
                <div className="title">
                  <img src={comercialIcon} alt="" />
                  <span>{t("contracts.commercial_in_charge")}</span>
                </div>
                <span>{state.comercial_manager}</span>
              </div>
            </div>
            <div className="btnsI">
              <div className="btnI">
                <span
                  onClick={() =>
                    navigate("/Contrato/Facturas", {
                      state,
                    })
                  }
                >
                  {t("movements.table.see_invoice")}
                </span>
              </div>
              <div className="btnI" onClick={() => processDownloadContract()}>
                <span>{t("contracts.see_contract")}</span>
              </div>
              <div
                className="btnI"
                onClick={() => processSendLiquidationBackOffice()}
              >
                <span>{t("contracts.liquidate_contract")}</span>
              </div>
              <div
                onClick={() =>
                  navigate("/SolicitudCertificado", {
                    state,
                  })
                }
                className="btnI"
              >
                <span>{t("contracts.request_certificate")}</span>
              </div>
              <div onClick={() => processChargingAccount()} className="btnI">
                <span>
                  {chargeAccount
                    ? t("charge_account_request.see_charge_account")
                    : t("contracts.request_charge_account")}
                </span>
              </div>
            </div>
          </div>
          <Modal
            type="2"
            onConfirm={() => {
              setOpenModalLiquidationBackOffice(false);
            }}
            onOpen={setOpenModalLiquidationBackOffice}
            open={openModalLiquidationBackOffice}
            text="Tu solicitud está siendo procesada, esto tomará un tiempo. Espera a que te contacte un asesor"
            title="Solicitud enviada exitosamente"
          />
        </div>
      )}
    </>
  );
};

export default Info;

import React, { useEffect, useState } from "react";
import { Nationalitys, TipoDoc, TipoPersona } from "../../utils/constants";
import InputSelect from "../SignUp/InputSelect";
import { useTranslation } from "react-i18next";

import arrowDownIcon from "../../assets/img/svg/arrow-down.svg";

const FormPerfil = ({ user, cambiarPaso, setForm }) => {
  const [t, _] = useTranslation("global");
  const [formulario, setFormulario] = useState({
    firstName: "",
    lastName: "",
    numberDoc: "",
    birthdate: "",
    client_type: "",
    identification_type: "",
    email: "",
    phone: "",
    nationality: "",
    occupation: "",
    employer_name: "",
  });
  //const [boton, setBoton] = useState("boton-continuar-disabled"); // State para claseName de boton "Continuar"
  const [phone, setphone] = useState(false);
  const [phoneCode, setphoneCode] = useState("57");
  //const submit = () => {
  //  setForm(formulario);
  //  cambiarPaso();
  //};

  //TODO Establecer comprobacion general
  //const comprobarFormulario = React.useCallback(() => {
  //  return (
  //    formulario.lastName !== "" &&
  //    formulario.firstName !== "" &&
  //    formulario.numberDoc !== "" &&
  //    formulario.birthdate !== "" &&
  //    formulario.client_type !== "" &&
  //    formulario.identification_type !== "" &&
  //    formulario.email !== "" &&
  //    formulario.phone !== "" &&
  //    formulario.nationality !== ""
  //  );
  //}, [formulario]);

  useEffect(() => {
    console.table(user);
    setFormulario({
      firstName: user?.fullname,
      client_type: user?.client_type,
      identification_type: user?.type_document,
      numberDoc: user?.tax_id_number,
      birthdate: new Date(user?.date_of_birth).toLocaleDateString(),
      nationality: user?.nationality,
      email: user?.email,
      phone: user?.phone_number,
      occupation: user?.occupation,
      employer_name: user?.employer_name,
      legal_representative_name: user?.legal_representative_name,
      id_legal_representative_name: user?.id_legal_representative_name,
      company_name: user?.company_name,
      nit: user?.nit
    });
    //if (comprobarFormulario()) {
    //  setBoton("boton-continuar-enabled");
    //} else {
    //  setBoton("boton-continuar-disabled");
    //}
  }, []);

  const handleSelectChange = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="formulario-registro">
      <div className="container-campos">
        <div className="part-space">
          <div className="espacio-input">
            <p className="titulo-2">
              {t("signup.general_information.person_type")}
            </p>
            <input
              type="text"
              className="input only-read"
              value={
                TipoPersona.find((e) => e.option == formulario.client_type)
                  ?.value ?? ""
              }
              readOnly
            ></input>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">{t("signup.general_information.names")}</p>
            <input
              type="text"
              className="input only-read"
              placeholder={t("signup.write_here")}
              value={formulario.firstName}
              readOnly
            ></input>
          </div>
        </div>
        <div className="part-space">
          <div className="espacio-input">
            <p className="titulo-2">
              {t("signup.general_information.identification_type")}
            </p>
            <input
              type="text"
              className="input only-read"
              value={
                TipoDoc.find((e) => e.option == formulario.identification_type)
                  ?.value ?? ""
              }
              readOnly
            ></input>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">
              {t("signup.general_information.identification_number")}
            </p>
            <input
              type="text"
              className="input only-read"
              placeholder={t("signup.write_here")}
              value={formulario.numberDoc}
              readOnly
            ></input>
          </div>
        </div>
        <div className="part-space">
          <div className="espacio-input">
            <p className="titulo-2">
              {t("signup.general_information.birthday_date")}
            </p>
            <input
              type="text"
              className="input only-read"
              placeholder="16/11/1981"
              value={formulario.birthdate}
              readOnly
            ></input>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">
              {t("signup.general_information.nationality")}
            </p>
            <input
              type="text"
              className="input only-read"
              value={
                Nationalitys.find((e) => e.option == formulario.nationality)
                  ?.value ?? ""
              }
              readOnly
            ></input>
          </div>
        </div>
        <div className="part-space">
          <div className="espacio-input">
            <p className="titulo-2">{t("signup.general_information.email")}</p>
            <input
              type="text"
              className="input only-read"
              placeholder="email@email.com"
              value={formulario.email}
              readOnly
            ></input>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">{t("signup.general_information.phone")}</p>
            <input
              type="text"
              className="input only-read"
              placeholder="311 258 369"
              value={formulario.phone}
              readOnly
            ></input>
          </div>
        </div>
        <div className="part-space">
          <div className="espacio-input">
            <p className="titulo-2">
              {t("signup.general_information.occupation")}
            </p>
            <input
              type="text"
              className="input only-read"
              placeholder="Gerente general"
              value={formulario.occupation}
              readOnly
            ></input>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">
              {t("signup.general_information.employee")}
            </p>
            <input
              type="text"
              className="input only-read"
              placeholder={t("signup.write_here")}
              value={formulario.employer_name}
              readOnly
            ></input>
          </div>
        </div>
        {formulario.client_type === "COMPANY" &&
        <>
          <div className="part-space">
            <div className="espacio-input">
              <p className="titulo-2">
                {t("signup.general_information.id_legal_representative")}
              </p>
              <input
                type="text"
                className="input only-read"
                placeholder={t("signup.write_here")}
                value={formulario.id_legal_representative_name}
                readOnly
              ></input>
            </div>
            <div className="espacio-input">
              <p className="titulo-2">
                {t("signup.general_information.legal_representative")}
              </p>
              <input
                type="text"
                className="input only-read"
                placeholder={t("signup.write_here")}
                value={formulario.legal_representative_name}
                readOnly
              ></input>
            </div>
          </div>
          <div className="part-space">
            <div className="espacio-input">
              <p className="titulo-2">
                {t("signup.general_information.company_name")}
              </p>
              <input
                type="text"
                className="input only-read"
                placeholder={t("signup.write_here")}
                value={formulario.company_name}
                readOnly
              ></input>
            </div>
            <div className="espacio-input">
              <p className="titulo-2">
                {t("signup.general_information.nit")}
              </p>
              <input
                type="text"
                className="input only-read"
                placeholder={t("signup.write_here")}
                value={formulario.nit}
                readOnly
              ></input>
            </div>
          </div>
        </>}
        {/* <button className={boton} onClick={() => submit()}>
          Continuar
        </button> */}
      </div>
    </div>
  );
};

export default FormPerfil;

import React, { useEffect, useState } from "react";
//import { useNavigate } from "react-router-dom";
import { NotificationsList, NotificationsRead } from "./services";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import GeneralContext from "../../context/GeneralContext";

import eclipseIcon from "../../assets/img/svg/eclipse.svg"

const Notifications = () => {
  const { user } = useContext(GeneralContext);
  const [t, i18n] = useTranslation("global");
  //const navigate = useNavigate();
  const [notificaciones, setNotificaciones] = useState([]);

  useEffect(() => {
    NotificationsList(user?.client_investor_number)
      .then((resp) => {
        if (resp.status >= 200 && resp.status <= 299) {
          setNotificaciones(resp.data);
        } else {
          alert("Hubo un error");
        }
      })
      .catch((err) => {
        //alert(`Ha ocurrido un error: ${err}`);
      });

    setTimeout(() => {
      // Llamar al servicio después de 30 segundos
      NotificationsRead(user?.client_investor_number)
        .then((resp) => {
          if (resp.status >= 200 && resp.status <= 299) {
          } else {
            alert("Hubo un error");
          }
        })
        .catch((err) => {
          //alert(`Ha ocurrido un error: ${err}`);
        });
    }, 30000);
  }, []);

  return (
    <div className="notifacations">
      <div className="title">
        <span>{t("sidebar.notifications")}</span>
      </div>

      {notificaciones.map((item, index) => (
        <div className="notificationPage" key={index}>
          <div>
            {item.status === "unRead" ? (
              <img src={eclipseIcon} style={{marginRight: '10px'}} alt="Read" />
            ) : null}
            <span>CAB CAPITAL</span>
          </div>
          <p>{item.msg}</p>
          <span>{item.date_formatted}</span>
        </div>
      ))}
    </div>
  );
};

export default Notifications;

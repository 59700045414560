import React, { useEffect, useState } from 'react';
import { useContracts } from '../hooks/useContracts';

const ContractItem = ({ item, formatDate, formatNumber, t, navigate }) => {
  const { getUtilities } = useContracts();

  const [totalProfits, setTotalProfits] = useState(0)

  useEffect(() => {
    getProfits(item.contract_number)
  }, []);

  const getProfits = async (contract_number) => {
    const total_profits = await getUtilities(contract_number);
    setTotalProfits(total_profits)
    if (total_profits) {
    }
    return total_profits
  };

  return (
    <tr key={item._id}>
      <td># {item.contract_number}</td>
      <td>{item.descriptionContract.type}</td>
      <td>{formatDate(item.createdAt)}</td>
      <td>
        ${formatNumber(totalProfits)}
        {" "}
        <span className="dolar">USD</span>
      </td>
      <td>
        <span className={
          item.status === "Active"
            ? "active"
            : item.status === "Pending"
              ? "pending"
              : "liquidated"
        }>{item.status === 'Active' ? t("contracts.statusActive") : t("contracts.statusPending")}</span>
      </td>
      <td>
        <span>{item.intermediate_states === 'Signed' ? t("contracts.documentSigned") : t("contracts.documentUnsigned")}</span>
      </td>
      <td>
        ${formatNumber(item.investment[0]?.amount - item.fee_admin)}
        <span className="dolar">USD</span>
      </td>
      <td>
        <span
          onClick={(e) => {
            e.preventDefault();
            navigate("/Portafolio/Detail", { state: item });
          }}
        >
          <a href="" style={{ color: "#7FC5ED" }}>
            {t("contracts.see_more")}
          </a>
        </span>
      </td>
    </tr>
  );
};

export default ContractItem;

import React, { useState, useEffect } from "react";
import { MyInversions } from "./MyInversions/MyInversions";
import Inversions from "./Inversions";
import Movimientos from "./Movimientos";
//import "./styles.scss";
import { useMyInversions } from "./hooks/useMyInversions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import retiroIcon from "../../assets/img/svg/retiro.svg"

const Inversion = () => {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const { getMyInversions } = useMyInversions();

  const [panel, setpanel] = useState("1");
  const [myInversions, setMyInversions] = useState([]);

  const procesesGetMyInversions = React.useCallback(async () => {
    const data = await getMyInversions();
    data.length > 0 && setMyInversions(data);
  }, [getMyInversions]);

  useEffect(() => {
    procesesGetMyInversions();
  }, []);

  return (
    <div className="dash PanelInversions">
      <div className="right" style={{ width: "100%" }}>
        <div style={{ margin: "20px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>{t("sidebar.investments")}</h1>
            <div>
              <div className="btn1" onClick={() => navigate("/Retiros")}>
                <img src={retiroIcon} alt="" />
                <span>{t("investments.request_withdrawal")}</span>
              </div>
            </div>
          </div>

          <div className="inversions">
            <div style={{ display: "flex" }}>
              <div
                onClick={() => setpanel("1")}
                className={panel === "1" ? `itemI` : `item`}
              >
                <span>{t("investments.invest")}</span>
              </div>
              <div
                onClick={() => setpanel("2")}
                className={panel === "2" ? `itemI` : `item`}
              >
                <span>{t("investments.movements")}</span>
              </div>
              <div
                onClick={() => setpanel("3")}
                className={panel === "3" ? `itemI` : `item`}
              >
                <span>{t("investments.my_investments")}</span>
              </div>
            </div>
          </div>
        </div>

        {panel === "1" && <Inversions />}
        {panel === "2" && <Movimientos />}
        {panel === "3" && <MyInversions Inversions={myInversions} />}
      </div>
    </div>
  );
};

export default Inversion;

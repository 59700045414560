import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import titleLogoIcon from "../../assets/img/svg/title-logo.svg";
import onboarding1Icon from "../../assets/videos/onboarding1.mp4";
import onboarding2Icon from "../../assets/videos/onboarding2.mp4";
import onboarding3Icon from "../../assets/videos/onboarding3.mp4";
import onboarding4Icon from "../../assets/videos/onboarding4.mp4";
import onboarding5Icon from "../../assets/videos/onboarding5.mp4";
import onboarding6Icon from "../../assets/videos/onboarding6.mp4";
import arrowLeftInactiveIcon from "../../assets/img/svg/arrow-left-inactive.svg";
import arrowLeftActiveIcon from "../../assets/img/svg/arrow-left-active.svg";
import arrowRightActiveIcon from "../../assets/img/svg/arrow-right-active.svg";

import "./styles.scss";

const Onboarding = ({ open, setOpen }) => {
  const [t, _] = useTranslation("global");
  const [steps, setSteps] = useState(0);
  const [selectedOption, setSelectedOption] = useState("onboarding1");
  const videoRef = useRef(null);

  const videos = [
    { key: "onboarding1", value: onboarding1Icon },
    { key: "onboarding2", value: onboarding2Icon },
    { key: "onboarding3", value: onboarding3Icon },
    { key: "onboarding4", value: onboarding4Icon },
    { key: "onboarding5", value: onboarding5Icon },
  ];

  const handleButtonClick = (option) => {
    setSelectedOption(option);
  };

  const changeOptions = () => {
    switch (selectedOption) {
      case "onboarding1":
        handleButtonClick("onboarding2");
        break;
      case "onboarding2":
        handleButtonClick("onboarding3");
        break;
      case "onboarding3":
        handleButtonClick("onboarding4");
        break;
      case "onboarding4":
        handleButtonClick("onboarding5");
        break;
      case "onboarding5":
        handleButtonClick("onboarding6");
        break;
    }
  };

  const backOptions = () => {
    switch (selectedOption) {
      case "onboarding2":
        handleButtonClick("onboarding1");
        break;
      case "onboarding3":
        handleButtonClick("onboarding2");
        break;
      case "onboarding4":
        handleButtonClick("onboarding3");
        break;
      case "onboarding5":
        handleButtonClick("onboarding4");
        break;
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      console.log(videoRef);
      videoRef.current.playbackRate = 0.5;
    }
  }, [selectedOption]);

  return (
    <div>
      {steps === 0 && (
        <div className="onboarding">
          <div className="container-step-1">
            <p>
              <a
                style={{
                  color: "#7FC5ED",
                  textDecoration: "underline",
                  fontSize: "16px",
                  cursor: "pointer",
                  display: "absolute",
                }}
                onClick={() => setOpen(false)}
              >
                {t("onboarding.skip")}
              </a>
            </p>
            <div className="first-step">
              <h1>{t("onboarding.title")}</h1>
              <p>{t("onboarding.description")}</p>
            </div>
            <button
              className="continue"
              style={{ float: "right" }}
              onClick={() => setSteps(1)}
            >
              {t("onboarding.init_onboarding")}
            </button>
          </div>
        </div>
      )}

      {steps === 1 && (
        <div className="second-step">
          <div className="left">
            <div className="logo">
              <img src={titleLogoIcon} alt="Cab Capital" />
            </div>
            <div className="options">
              <button
                style={{ cursor: "pointer" }}
                className={`${
                  selectedOption === "onboarding1" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("onboarding1")}
              >
                <span
                  className={`index ${
                    selectedOption === "onboarding1" ? "active" : ""
                  }`}
                >
                  1
                </span>
                {t("onboarding.we_adapt")}
              </button>
              <button
                style={{ cursor: "pointer" }}
                className={`${
                  selectedOption === "onboarding2" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("onboarding2")}
              >
                <span
                  className={`index ${
                    selectedOption === "onboarding2" ? "active" : ""
                  }`}
                >
                  2
                </span>
                {t("onboarding.one_place")}
              </button>
              <button
                style={{ cursor: "pointer" }}
                className={`${
                  selectedOption === "onboarding3" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("onboarding3")}
              >
                <span
                  className={`index ${
                    selectedOption === "onboarding3" ? "active" : ""
                  }`}
                >
                  3
                </span>
                {t("onboarding.all_documents")}
              </button>
              <button
                style={{ cursor: "pointer" }}
                className={`${
                  selectedOption === "onboarding4" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("onboarding4")}
              >
                <span
                  className={`index ${
                    selectedOption === "onboarding4" ? "active" : ""
                  }`}
                >
                  4
                </span>
                {t("onboarding.costumer_services")}
              </button>
              <button
                style={{ cursor: "pointer" }}
                className={`${
                  selectedOption === "onboarding5" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("onboarding5")}
              >
                <span
                  className={`index ${
                    selectedOption === "onboarding5" ? "active" : ""
                  }`}
                >
                  5
                </span>
                {t("onboarding.investment_world")}
              </button>
            </div>
          </div>
          <div className="right">
            <p style={{ width: "100%", marginRight: "20%" }}>
              <a
                style={{
                  float: "right",
                  color: "#7FC5ED",
                  textDecoration: "underline",
                  fontSize: "16px",
                  cursor: "pointer",
                  display: "absolute",
                }}
                onClick={() => setOpen(false)}
              >
                {t("onboarding.skip")}
              </a>
            </p>
            <div className="image">
              {selectedOption && (
                <video
                  ref={videoRef}
                  src={videos.find((e) => e.key == selectedOption).value}
                  alt={selectedOption}
                  width="640"
                  height="480"
                  autoPlay
                  muted
                  loop
                />
              )}
              {selectedOption === "onboarding1" && (
                <>
                  <h3 className="title">{t("onboarding.we_adapt")}</h3>
                  <p>{t("onboarding.we_adapt_description")}</p>
                </>
              )}
              {selectedOption === "onboarding2" && (
                <>
                  <h3 className="title">{t("onboarding.one_place")}</h3>
                  <p>{t("onboarding.one_place_description")}</p>
                </>
              )}
              {selectedOption === "onboarding3" && (
                <>
                  <h3 className="title">{t("onboarding.all_documents")}</h3>
                  <p>{t("onboarding.all_documents_description")}</p>
                </>
              )}
              {selectedOption === "onboarding4" && (
                <>
                  <h3 className="title">{t("onboarding.costumer_services")}</h3>
                  <p>{t("onboarding.costumer_services_description")}</p>
                </>
              )}
              {selectedOption === "onboarding5" && (
                <>
                  <h3 className="title">{t("onboarding.investment_world")}</h3>
                  <p>{t("onboarding.investment_world_description")}</p>
                </>
              )}
            </div>

            <div style={{ width: "100%", marginRight: "20%" }}>
              <button
                className="continue"
                onClick={() => {
                  changeOptions();
                  selectedOption === "onboarding5" && setSteps(2);
                }}
              >
                {selectedOption === "onboarding5"
                  ? t("onboarding.finish_onboarding")
                  : t("onboarding.continue")}
              </button>
            </div>
          </div>
          <div className="mobile">
            <div className="top">
              <p style={{ float: "right", marginRight: "40px" }}>
                {selectedOption != "onboarding6" ? (
                  <a
                    style={{
                      color: "#7FC5ED",
                      textDecoration: "underline",
                      fontSize: "26px",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpen(false)}
                  >
                    {t("onboarding.skip")}
                  </a>
                ) : (
                  <a
                    style={{
                      float: "right",
                      color: "#7FC5ED",
                      textDecoration: "underline",
                      fontSize: "22px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleButtonClick("onboarding1");
                      setSteps(0);
                    }}
                  >
                    {t("onboarding.back_onboarding")}
                  </a>
                )}
              </p>
              <div className="image">
                {selectedOption == "onboarding6" ? (
                  <video
                    style={{ width: "100%" }}
                    src={onboarding6Icon}
                    autoPlay
                    muted
                    loop
                  />
                ) : (
                  <video
                    style={{ width: "100%" }}
                    src={videos.find((e) => e.key == selectedOption).value}
                    alt={selectedOption}
                    autoPlay
                    muted
                    loop
                  />
                )}
              </div>
            </div>
            <div className="bottom">
              <div>
                {selectedOption === "onboarding1" && (
                  <>
                    <h1 className="title">{t("onboarding.we_adapt")}</h1>
                    <p>{t("onboarding.we_adapt_description")}</p>
                  </>
                )}
                {selectedOption === "onboarding2" && (
                  <>
                    <h1 className="title">{t("onboarding.one_place")}</h1>
                    <p>{t("onboarding.one_place_description")}</p>
                  </>
                )}
                {selectedOption === "onboarding3" && (
                  <>
                    <h1 className="title">{t("onboarding.all_documents")}</h1>
                    <p>{t("onboarding.all_documents_description")}</p>
                  </>
                )}
                {selectedOption === "onboarding4" && (
                  <>
                    <h1 className="title">
                      {t("onboarding.costumer_services")}
                    </h1>
                    <p>{t("onboarding.costumer_services_description")}</p>
                  </>
                )}
                {selectedOption === "onboarding5" && (
                  <>
                    <h1 className="title">
                      {t("onboarding.investment_world")}
                    </h1>
                    <p>{t("onboarding.investment_world_description")}</p>
                  </>
                )}
                {selectedOption === "onboarding6" && (
                  <>
                    <h1 className="title">{t("onboarding.title_2")}</h1>
                    <p>{t("onboarding.description_2")}</p>
                  </>
                )}
              </div>
              {selectedOption != "onboarding6" ? (
                <div className="nav-buttons">
                  {selectedOption === "onboarding1" ? (
                    <div>
                      <img
                        style={{ width: "34px" }}
                        src={arrowLeftInactiveIcon}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div onClick={backOptions}>
                      <img
                        style={{ width: "34px" }}
                        src={arrowLeftActiveIcon}
                        alt=""
                      />
                    </div>
                  )}
                  <div className="nav-dots">
                    <div
                      className={`dot ${
                        selectedOption == "onboarding1" && "active"
                      }`}
                    ></div>
                    <div
                      className={`dot ${
                        selectedOption == "onboarding2" && "active"
                      }`}
                    ></div>
                    <div
                      className={`dot ${
                        selectedOption == "onboarding3" && "active"
                      }`}
                    ></div>
                    <div
                      className={`dot ${
                        selectedOption == "onboarding4" && "active"
                      }`}
                    ></div>
                    <div
                      className={`dot ${
                        selectedOption == "onboarding5" && "active"
                      }`}
                    ></div>
                  </div>
                  <div onClick={changeOptions}>
                    <img
                      style={{ width: "34px" }}
                      src={arrowRightActiveIcon}
                      alt=""
                    />
                  </div>
                </div>
              ) : (
                <button
                  className="continue"
                  onClick={() => {
                    localStorage.setItem("omboarding", true);
                    setOpen(false);
                  }}
                >
                  {t("onboarding.continue_2")}
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {steps === 2 && (
        <div className="onboarding">
          <div style={{ display: "block" }}>
            <p>
              <a
                style={{
                  float: "right",
                  color: "#7FC5ED",
                  textDecoration: "underline",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleButtonClick("onboarding1");
                  setSteps(0);
                }}
              >
                {t("onboarding.back_onboarding")}
              </a>
            </p>
            <div>
              <h1>{t("onboarding.title_2")}</h1>
              <p>{t("onboarding.description_2")}</p>
              <video
                className="image"
                src={onboarding6Icon}
                width="640"
                height="480"
                autoPlay
                muted
                loop
              />
            </div>
            <button
              className="continue"
              style={{ float: "right", marginTop: "10px" }}
              onClick={() => {
                localStorage.setItem("omboarding", true);
                setOpen(false);
              }}
            >
              {t("onboarding.continue_2")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Onboarding;

import "../Inversions/MyInversions/styles.scss";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { formatNumber } from "../../utils/Utils";
import { usePortfolios } from "./hooks/usePortfolios";
import { usePortfolioLabels } from "./hooks/usePortfolioLabels";
import { useTranslation } from "react-i18next";
import GeneralContext from "../../context/GeneralContext";

const InversionItem = ({ Portfolio, handleModal }) => {
  const navigate = useNavigate();
  const { checkPendingToSign, requestCustomPortfolio } = usePortfolios();
  const { user } = useContext(GeneralContext);
  const { portFoliosLabels, isLoading } = usePortfolioLabels([]);
  const [translationList, setTranslationList] = useState();
  const [t, i18n] = useTranslation("global");

  const {
    type,
    minimun_amount_investment = 0,
    cost_effectiveness = "",
    risk = "",
    minimum_expiration_time = "",
    maximum_amount_investment = 0,
    payment_period_cost_effectiveness = "",
    color_card,
    icon_card,
    status,
    extra_values
  } = Portfolio;

  const typePortfolio = [
    { type: "Renta Fija", class: "card1" },
    { type: "Renta Fija premium", class: "card2" },
    { type: "Renta Variable", class: "card3" },
    { type: "Renta Cripto", class: "card4" },
    { type: "OTC", class: "card5" },
    { type: "Hecho a tu medida", class: "card6" },
  ];

  const proccessCheckPedingSign = async () => {
    const { success, contract } = await checkPendingToSign();
    if (success) {
      navigate("/uploadSign", { state: contract });
    } else {
      navigate("/Invertir", { state: Portfolio });
    }
  };

  const processCreateCustomPortfolio = async () => {
    const response = await requestCustomPortfolio();
    handleModal(response);
  };

  const shortText = (str) => {
    if (str?.length > 40) {
      return str?.slice(0, 37) + '...';
    }
    return str;
  }

  useEffect(() => {
    if (!isLoading && portFoliosLabels?.length > 0) {
      const lowerCaseExtraValues = Object.fromEntries(
        Object.entries(extra_values).map(([key, value]) => [
          key.toLowerCase().trim(),
          value
        ])
      );
  
      const tempList = portFoliosLabels.reduce((acc, curr) => {
        const lowerCaseAlias = curr.alias.toLowerCase().trim();
        if (lowerCaseExtraValues[lowerCaseAlias]) {
          acc.push({
            es_label: curr.es,
            en_label: curr.en,
            ...lowerCaseExtraValues[lowerCaseAlias]
          });
        }
        return acc;
      }, []);
      setTranslationList(tempList);
    }
  }, [extra_values, isLoading, portFoliosLabels]);

  return (
    <div className="card">
      {type === "Hecho a tu medida" ? (
        <div className="cardCustom card6">
          <span
            className="title"
            style={{ color: "#0D2338", fontSize: "20px" }}
          >
            {t("investments.portfolio_made_for_you.title")}
          </span>
          <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            <span className="text-custom-card">{t("investments.portfolio_made_for_you.description")}</span>
          </div>
          <div onClick={() => processCreateCustomPortfolio()} className="btn">
            {t("investments.portfolio_made_for_you.request")}
          </div>
        </div>
      ) : (
        <>
          <div
            className={`front ${
              typePortfolio.find((t) => t.type === type)?.class || Portfolio.color_card
            } ${Portfolio.icon_card}`}
          >
            <div style={{ marginTop: "20px" }}>
              <span className="title">
                {t("investments.inversion_item.portfolio")} 
                {
                  i18n.language === 'en' ? " " +Portfolio.type_en : " " +Portfolio.type
                  || Portfolio.type
                }
              </span>
              <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                <h6>{t("investments.investment_minimum")}</h6>
                <span>$ {formatNumber(minimun_amount_investment)} USD</span>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <h6>{t("investments.cost_effectiveness")}</h6>
                <span>{cost_effectiveness}</span>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <h6>{t("investments.risk")}</h6>
                <span>{risk}</span>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <h6>{t("investments.pay_profitability")}</h6>
                <span>{payment_period_cost_effectiveness}</span>
              </div>
            </div>
          </div>
          <div
            className={`back ${
              typePortfolio.find((t) => t.type === type)?.class || Portfolio.color_card
            } ${Portfolio.icon_card}`}
          >
            <div className="grid-container">
              <div className="colum-left">
                <div>
                  <div style={{ marginBottom: "10px" }}>
                    <h6>{t("investments.contract_expiration")}</h6>
                    <span>
                      {Math.round(minimum_expiration_time / 30)}{" "}
                      {t("investments.months")}
                    </span>
                  </div>
                  {translationList && translationList.map((label, index) => {
                    if (index < translationList?.length / 2) {
                      return (
                        <React.Fragment key={index}>
                          <h6 style={{ marginTop: "10px" }}>{i18n.language === 'es' ? shortText(label.es_label) : shortText(label.en_label)}</h6>
                          <span style={{ marginBottom: "10px" }}>{i18n.language === 'es' ? shortText(label.es) : shortText(label.en)}</span>
                        </React.Fragment>
                      );
                    }
                  })}
                </div>
              </div>
              <div className="colum-right">
                <div>
                  <div >
                    <h6>{t("investments.investment_maximum")}</h6>
                    <span>${formatNumber(maximum_amount_investment)} USD</span>
                  </div>
                  {translationList && translationList?.map((label, index) => {
                    if (index >= translationList?.length / 2) {
                      return (
                        <React.Fragment key={index}>
                          <h6 style={{ marginTop: "10px" }}>{i18n.language === 'es' ? shortText(label.es_label) : shortText(label.en_label)}</h6>
                          <span style={{ marginBottom: "10px" }}>{i18n.language === 'es' ? shortText(label.es) : shortText(label.en)}</span>
                        </React.Fragment>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <div className="btn-container">
              <div onClick={() => proccessCheckPedingSign()} className="btn">
                {t("investments.i_want_to_invest")}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InversionItem;

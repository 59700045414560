import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

import CABInput from "../../components/CABInput";
import CABButton from "../../components/CABButton";

import information from "../../assets/img/png/informacion.png";
import Realizado from "../../assets/img/svg/Realizado.svg";

import "./SignUp.scss";

const BusinessInformation = ({ changeStep, form, setForm }) => {
  const [t, _] = useTranslation("global");

  const [validForm, setValidForm] = useState(false);
  const [isValidAmount, setIsValidAmount] = useState(false);

  const submit = () => {
    if (testForm()) {
      changeStep(3);
    }
  };

  const testForm = React.useCallback(() => {
    if (form.anticipated_deposit_amount) {
      const newValueValidate = form.anticipated_deposit_amount.replace(/,/g, '');
      if (newValueValidate >= 20000) {
        setIsValidAmount(true);
      } else {
        setIsValidAmount(false);
      }
    }
    return (
      form.reason_for_user_CAB &&
      form.anticipated_deposit_amount &&
      form.estimated_net_worth &&
      form.source_of_funds_and_wealth &&
      form.estimated_annual_gross_income &&
      isValidAmount
    );
  }, [form, isValidAmount]);

  useEffect(() => {
    setValidForm(testForm());
  }, [form, isValidAmount]);

  const handleAnticipatedDepositAmountChange = (e) => {
    const newValue = e.target.value;
    const newValueValidate = e.target.value.replace(/,/g, '');
    if (newValueValidate >= 20000) {
      setIsValidAmount(true);
    } else {
      setIsValidAmount(false);
    }
    setForm((prevForm) => ({
      ...prevForm,
      anticipated_deposit_amount: newValue,
    }));
  };

  return (
    <div className="formulario-registro">
      <div style={{ display: "flex" }}>
        <img className="check" src={Realizado} alt="" />
        <p className="titulo-section">{t("signup.financial_information")}</p>
      </div>
      <div>
        <div className="container-business">
          <div>
            <CABInput
              label={t("signup.why")}
              value={form.reason_for_user_CAB}
              type="text"
              placeholder={t("signup.write_here")}
              onChange={(e) =>
                setForm({
                  ...form,
                  reason_for_user_CAB: e.target.value,
                })
              }
            />
          </div>
          <div>
            <div className="label-information">
              <p className="titulo-2">{t("signup.how_much")}</p>
              <img
                src={information}
                className="tooltip"
                id="tip-inversion"
              ></img>
            </div>
            <CABInput
              leftIcon={<span className="dolar">USD</span>}
              type="number"
              value={form.anticipated_deposit_amount}
              placeholder="$ 0000000"
              onChange={handleAnticipatedDepositAmountChange}
            />
          </div>
          <div>
            <CABInput
              leftIcon={<span className="dolar">USD</span>}
              label={t("signup.what")}
              type="number"
              placeholder="$ 0000000"
              value={form.estimated_net_worth}
              onChange={(e) =>
                setForm({
                  ...form,
                  estimated_net_worth: e.target.value,
                })
              }
            />
          </div>
          <div>
            <div className="label-information">
              <p className="titulo-2">{t("signup.source")}</p>
              <img src={information} className="tooltip" id="tip-fuente"></img>
            </div>
            <CABInput
              type="text"
              placeholder={t("signup.write_here")}
              value={form.source_of_funds_and_wealth}
              onChange={(e) =>
                setForm({
                  ...form,
                  source_of_funds_and_wealth: e.target.value,
                })
              }
            />
          </div>
          <div>
            <CABInput
              label={t("signup.income")}
              leftIcon={<span className="dolar">USD</span>}
              type="number"
              value={form.estimated_annual_gross_income}
              placeholder="$ 0000000"
              onChange={(e) =>
                setForm({
                  ...form,
                  estimated_annual_gross_income: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px", justifyContent: "space-between" }}>
          <CABButton
            type="secondary"
            label={t("general.back")}
            onClick={() => changeStep(1)}
          />
          <CABButton
            label={t("general.next")}
            disabled={!validForm}
            onClick={() => submit()}
          />
        </div>
      </div>
      <ReactTooltip
        anchorId="tip-inversion"
        place="right"
        content={t("signup.tooltip_1")}
      />
      <ReactTooltip
        anchorId="tip-fuente"
        place="bottom"
        content={t("signup.tooltip_2")}
      />
    </div>
  );
};

export default BusinessInformation;

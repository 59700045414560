import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import GeneralContext from "../../context/GeneralContext";
import { guardarSesion } from "../../utils/Utils";
import { URL_API } from "../../config/config";
import Modal from "../../components/Modal";
import CABInput from "../../components/CABInput";
import CABButton from "../../components/CABButton";

import eyeShow from "../../assets/img/png/ojo.png";
import eyeClose from "../../assets/img/svg/ojoClose.svg";
import Realizado from "../../assets/img/svg/Realizado.svg";

import "./SignUp.scss";

const CreatePassword = ({ changeStep, form, setForm, setPasswordMirror }) => {
  const [t, _] = useTranslation("global");
  // Context
  const GC = useContext(GeneralContext);
  const { modificarUser } = GC;

  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

  const [validForm, setValidForm] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [openErrorMessage, setOpenErrorMessage] = useState(false);
  const [openModalPassword, setOpenModalPassword] = useState(false);

  useEffect(() => {
    setValidForm(validatePassword() && validateConfirmPassword());
  }, [form]);

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setForm({ ...form, password });
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    setForm({ ...form, confirmPassword });
  };

  const validatePassword = () => {
    if (form.password === "") {
      setPasswordError("");
      return false;
    } else if (!/(?=.*[a-z])/.test(form.password)) {
      setPasswordError(t("signup.password.requirement_2"));
      return false;
    } else if (!/(?=.*[A-Z])/.test(form.password)) {
      setPasswordError(t("signup.password.requirement_3"));
      return false;
    } else if (!/(?=.*\d)/.test(form.password)) {
      setPasswordError(t("signup.password.requirement_4"));
      return false;
    } else if (!/(?=.*[!@#$%^&*()])/.test(form.password)) {
      setPasswordError(t("signup.password.requirement_5"));
      return false;
    } else if (form.password.length < 8) {
      setPasswordError(t("signup.password.requirement_6"));
      return false;
    } else {
      setPasswordError("");
    }
    return true;
  };

  const validateConfirmPassword = () => {
    if (form.confirmPassword === "") {
      setConfirmPasswordError(t("signup.password.requirement_7"));
      return false;
    } else if (form.password !== form.confirmPassword) {
      setConfirmPasswordError(t("signup.password.requirement_8"));
      return false;
    } else {
      setConfirmPasswordError("");
    }
    return true;
  };

  const submit = async () => {
    setPasswordMirror(form.password);
    if (form.password === form.confirmPassword) {
      const { firstName, lastName, email, ...rest } = form;
      const body = {
        ...rest,
        anticipated_deposit_amount: rest.anticipated_deposit_amount.replaceAll(
          ",",
          ""
        ),
        estimated_annual_gross_income:
          rest.estimated_annual_gross_income.replaceAll(",", ""),
        estimated_net_worth: rest.estimated_net_worth.replaceAll(",", ""),
        identification_number: rest.identification_number.replaceAll(",", ""),
        birthdate: rest.birthday,
        email,
        fullname: firstName + " " + lastName,
        password: form.password,
        confirmPassword: form.confirmPassword,
        recover_email: email,
      };

      try {
        const response = await axios.post(
          `${URL_API}/api/authentication/register/`,
          body
        );
        if (response.status >= 200 && response.status <= 299) {
          let newUser = structuredClone(response?.data?.user);
          newUser.token = response?.data?.token;
          newUser.codeEntered = "";
          guardarSesion(newUser);
          modificarUser(newUser);
          changeStep(4);
        } else {
          setErrorMessage(
            "We are sorry, an error occurred while processing your request. Try again later"
          );
          setOpenErrorMessage(!openErrorMessage);
        }
      } catch (error) {
        let msg = "";
        const reasons = error?.response?.data?.errors;
        const mesg = reasons ?? error.response.data.msg;
        if (reasons) {
          reasons.length > 0 &&
            reasons.forEach((element) => {
              msg += element.msg + "\n ";
            });
          if (msg != "") {
            setErrorMessage(msg);
            setOpenErrorMessage(!openErrorMessage);
          }
        } else {
          if (mesg != "") {
            setErrorMessage(mesg);
            setOpenErrorMessage(!openErrorMessage);
          }
        }
      }
    }
  };

  return (
    <div className="formulario-registro formularioPass">
      <div style={{ display: "flex" }}>
        <img className="check" src={Realizado} alt="done" />
        <p className="titulo-section">{t("signup.password.title")}</p>
      </div>
      <p className="text-section">
        {t("signup.password.description_1")}
        <br /> {t("signup.password.description_2")}
      </p>
      <div className="container-campos">
        <div className="espacio-input">
          <CABInput
            label={t("signup.password.password")}
            type={showPassword ? "text" : "password"}
            placeholder={t("signup.write_here")}
            error={passwordError != ""}
            errorMessage={passwordError}
            rightIcon={
              <img src={showPassword ? eyeShow : eyeClose} alt="ojo"></img>
            }
            onRightIconClick={() => setShowPassword(!showPassword)}
            onChange={(e) => handlePasswordChange(e)}
          />
        </div>
        <div className="espacio-input">
          <CABInput
            label={t("signup.password.password_confirm")}
            type={showConfirmPassword ? "text" : "password"}
            placeholder={t("signup.write_here")}
            error={confirmPasswordError != ""}
            errorMessage={confirmPasswordError}
            rightIcon={
              <img
                src={showConfirmPassword ? eyeShow : eyeClose}
                alt="ojo"
              ></img>
            }
            onRightIconClick={() =>
              setShowConfirmPassword(!showConfirmPassword)
            }
            onChange={(e) => handleConfirmPasswordChange(e)}
          />
        </div>
      </div>
      <div>
        <div style={{ display: "flex", gap: "10px", justifyContent: "space-between" }}>
          <CABButton
            type="secondary"
            label={t("general.back")}
            onClick={() => changeStep(2)}
          />
          <CABButton
            disabled={!validForm}
            label={t("general.next")}
            onClick={() => setOpenModalPassword(true)}
          />
        </div>
      </div>
      <Modal
          type="1"
          btnlabel={t("login.modal.btn_accept")}
          onConfirm={() => submit()}
          onOpen={setOpenModalPassword}
          open={openModalPassword}
          title={t("signup.titleModal")}
          text={t("signup.descModal")}
        />
      {/* //// MODAL ERROR HANDLED */}
      <Modal
        onOpen={setOpenErrorMessage}
        open={openErrorMessage}
        title={"¡Lo sentimos! no pudimos realizar su solicitud"}
        text={`Por favor corrija los siguientes errores.\n ${errorMessage} `}
      />
    </div>
  );
};

export default CreatePassword;

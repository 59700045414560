import "./styles.scss";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Modal from "../Modal";
import { resetPassword } from "../../pages/PasswordRecovery/services";
import GeneralContext from "../../context/GeneralContext";

import backIcon from "../../assets/img/svg/back.svg";
import ojoIcon from "../../assets/img/png/ojo.png";
import ojoCloseIcon from "../../assets/img/svg/ojoClose.svg";

const CambiarPassword = () => {
  const [t, _] = useTranslation("global");
  const navigate = useNavigate();
  const [input] = useState("input");
  const [pass2, setPass2] = useState(true);
  const [pass3, setPass3] = useState(true);
  const [open, setOpen] = useState(false);
  const [botonPass, setBotonPass] = useState("boton-continuar-disabled");
  const [formularioPass, setformularioPass] = useState({
    // State para informacion de usuario
    code: "",
    password: "",
    confirm: "",
  });
  const GC = useContext(GeneralContext);
  const { user } = GC;
  const comprobarFormulario = React.useCallback(() => {
    return (
      formularioPass.code !== "" &&
      formularioPass.password !== "" &&
      formularioPass.confirm !== "" &&
      formularioPass.password === formularioPass.confirm
    );
  }, [formularioPass]);
  //TODO INTERNACIONALIZACIÓN
  useEffect(() => {
    if (comprobarFormulario()) {
      setBotonPass("boton-continuar-enabled");
    } else {
      setBotonPass("boton-continuar-disabled");
    }
  }, [formularioPass, comprobarFormulario, setBotonPass]);

  const [isValid, setIsValid] = useState(false);
  const [isValid2, setIsValid2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const verify = (e, confirm) => {
    const tieneMayuscula = /[A-Z]/.test(e);
    const tieneMinimoCaracteres = e.length >= 8;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(e);
    const sonIguales = formularioPass.password === e;
    if (confirm) {
      if (tieneMayuscula && sonIguales && tieneMinimoCaracteres) {
        setIsValid2(false);
        setErrorMessage("");
      } else if (!tieneMayuscula) {
        setIsValid2(true);
        setErrorMessage(t("password_change.requirement_1"));
      } else if (!tieneMinimoCaracteres) {
        setIsValid2(true);
        setErrorMessage(t("password_change.requirement_3"));
      } else if (!hasSpecialChar) {
        setIsValid2(true);
        setErrorMessage(t("password_change.requirement_4"));
      } else {
        setIsValid2(true);
        setErrorMessage(t("password_change.requirement_2"));
      }
    } else {
      if (!tieneMayuscula) {
        setIsValid(true);
        setErrorMessage(t("password_change.requirement_1"));
      } else if (!tieneMinimoCaracteres) {
        setIsValid(true);
        setErrorMessage(t("password_change.requirement_3"));
      } else if (!hasSpecialChar) {
        setIsValid(true);
        setErrorMessage(t("password_change.requirement_4"));
      } else {
        setIsValid2(false);
        setErrorMessage("");
      }
    }
  };
  const submit = () => {
    if (
      formularioPass.code !== "" ||
      formularioPass.password !== "" ||
      formularioPass.confirm !== ""
    ) {
      const params = {
        email: user?.email,
        code: formularioPass.code,
      };
      const body = {
        password: formularioPass.password,
        confirmPassword: formularioPass.confirm,
      };
      resetPassword(params, body)
        .then((resp) => {
          if (resp.status >= 200 && resp.status <= 299) {
            setOpen(true);
          } else {
            alert("Hubo un Error");
          }
        })
        .catch((err) => {
          alert(`Ah ocurrido un error ${err.response.data.msg}`);
        });
    }
  };
  return (
    <div className="cambiarPass">
      <div onClick={() => navigate("/Home", { state: "0" })} className="back">
        <img src={backIcon} alt="" /> <span style={{marginTop: '5px'}}>{t("general.back")}</span>
      </div>
      <div className="bodyPass">
        <div className="containerPass">
          <img src="/img/logo.svg" alt="" />
          <h1>{t("password_change.title")}</h1>
          <p>{t("password_change.description_1")}</p>
          <p>{t("password_change.description_2")}</p>
          <div className="formulario-inicio formularioPass">
            <div className="espacio-input">
              <p className={input === "input" ? "titulo-2" : "titulo-2-error"}>
                {t("password_change.verification_code")}
              </p>
              <input
                type="text"
                className={input}
                placeholder={t("signup.write_here")}
                autoComplete="new-password"
                onChange={(e) =>
                  setformularioPass({
                    ...formularioPass,
                    code: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="espacio-input">
              <p className={input === "input" ? "titulo-2" : "titulo-2-error"}>
                {t("password_change.new_password")}
              </p>
              <input
                type={!pass2 ? "text" : "password"}
                className={input}
                placeholder={t("signup.write_here")}
                autoComplete="new-password"
                onChange={(e) => {
                  setformularioPass({
                    ...formularioPass,
                    password: e.target.value,
                  });
                  verify(e.target.value, false);
                }}
              ></input>
              <img
                src={pass2 ? ojoIcon : ojoCloseIcon}
                className={input === "input" ? "ojo" : "ojo-error"}
                onClick={() => setPass2(!pass2)}
                alt="ojo"
              ></img>
              {isValid && <p className="texto-error">{errorMessage}</p>}
            </div>
            <div className="espacio-input">
              <p className={input === "input" ? "titulo-2" : "titulo-2-error"}>
                {t("password_change.confirm_password")}
              </p>
              <input
                type={!pass3 ? "text" : "password"}
                className={input}
                placeholder={t("signup.write_here")}
                onChange={(e) => {
                  setformularioPass({
                    ...formularioPass,
                    confirm: e.target.value,
                  });
                  verify(e.target.value, true);
                }}
              ></input>
              {isValid2 && <p className="texto-error">{errorMessage}</p>}
              <img
                src={pass3 ? ojoIcon : ojoCloseIcon}
                className={input === "input" ? "ojo" : "ojo-error"}
                onClick={() => setPass3(!pass3)}
                alt="ojo"
              ></img>
            </div>
            <button
              onClick={() => {
                botonPass === "boton-continuar-enabled" && submit();
              }}
              className={botonPass}
            >
              {t("password_change.title")}
            </button>
          </div>
        </div>
      </div>
      <Modal
        type="2"
        btnlabel={t("password_change.btn_modal")}
        onConfirm={() => {
          setOpen(false);
          navigate("/Home", { state: "0" });
        }}
        onOpen={setOpen}
        open={open}
        title={t("password_change.title_modal")}
        text={t("password_change.description_modal")}
      />
    </div>
  );
};
export default CambiarPassword;

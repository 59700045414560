import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";

import axios from "axios";
import { useTranslation } from "react-i18next";

import { eliminarSesion } from "../../utils/Utils";
import GeneralContext from "../../context/GeneralContext";
import { URL_API } from "../../config/config";

import logoTitle from "../../assets/img/svg/title-logo.svg";
import profile from "../../assets/img/png/no-profile-picture.png";
import home from "../../assets/img/svg/home.svg";
import homeActive from "../../assets/img/svg/homeS.svg";
import inversion from "../../assets/img/svg/inversion.svg";
import inversionActive from "../../assets/img/svg/inversionS.svg";
import contratos from "../../assets/img/svg/contratos.svg";
import contratosActive from "../../assets/img/svg/contratosS.svg";
import notificacion from "../../assets/img/svg/notificaciones.svg";
import notificacionActive from "../../assets/img/svg/notificationesS.svg";
import mensaje from "../../assets/img/svg/mensajes.svg";
import mensajeActive from "../../assets/img/svg/mensajesS.svg";
import tc from "../../assets/img/svg/tc.svg";
import tcActive from "../../assets/img/svg/tcS.svg";
import flagEnglish from "../../assets/img/svg/english.svg";
import flagSpain from "../../assets/img/svg/spain.svg";
import exit from "../../assets/img/svg/exit.svg";

import closeWhiteIcon from "../../assets/img/svg/close-white.svg"

import "../Sidebar/styles.scss";

const SideBar = ({ panel, setpanel }) => {
  const [t, i18n] = useTranslation("global");
  //Context
  const GC = useContext(GeneralContext);
  const { user, modificarUser } = GC;

  const navigate = useNavigate();
  const submit = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      const response = await axios.get(`${URL_API}/api/authentication/logout`);
      if (response.status >= 200 && response.status <= 299) {
        eliminarSesion();
        modificarUser(null);
        navigate("/");
      } else alert("Hubo un Error");
    } catch (error) {
      console.log(error);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [language, setLanguage] = useState(localStorage.getItem('language') === 'en');

  const handleChangeLanguage = () => {
    const newLanguage = !language;
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage ? 'en' : 'es');
    i18n.changeLanguage(newLanguage ? 'en' : 'es');
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setLanguage(storedLanguage === 'en');
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  return (
    <>
      <div className="top-bar">
        <div className="menu-icon" onClick={toggleSidebar}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logoTitle} alt="" />
      </div>

      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="header">
          <img className="logo" src={logoTitle} alt="" />
          <div
            onClick={() => {
              setpanel("0");
              toggleSidebar();
            }}
            className={panel === "0" ? `profileSelect` : `itemProfile`}
            style={{ cursor: "pointer", display: "flex" }}
          >
            <img
              src={user?.profile_picture || profile}
              alt="profile picture"
              style={{
                width: "45px",
                height: "45px",
                objectFit: "contain",
                marginRight: "10px",
                borderRadius: "50%",
              }}
            />

            <span
              style={{
                fontWeight: "700",
                width: "180px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                alignSelf: "center"
              }}
            >
              {user?.fullname}
            </span>
          </div>
          <img
            className="close"
            src={closeWhiteIcon}
            width={18}
            height={18}
            alt=""
            onClick={toggleSidebar}
          />
        </div>
        <nav>
          <div className="list">
            <div
              onClick={() => {
                setpanel("1");
                toggleSidebar();
              }}
              className={panel === "1" ? "itemSelect" : "item"}
            >
              <img src={panel === "1" ? homeActive : home} alt="" />
              <span>{t("sidebar.home")}</span>
            </div>
            <div
              onClick={() => {
                setpanel("2");
                toggleSidebar();
              }}
              className={panel === "2" ? "itemSelect" : "item"}
            >
              <img src={panel === "2" ? inversionActive : inversion} alt="" />
              <span>{t("sidebar.investments")}</span>
            </div>
            <div
              onClick={() => {
                setpanel("3");
                toggleSidebar();
              }}
              className={panel === "3" ? "itemSelect" : "item"}
            >
              <img src={panel === "3" ? contratosActive : contratos} alt="" />
              <span>{t("sidebar.contracts")}</span>
            </div>
            <div
              onClick={() => {
                setpanel("4");
                toggleSidebar();
              }}
              className={panel === "4" ? "itemSelect" : "item"}
            >
              <img
                src={panel === "4" ? notificacionActive : notificacion}
                alt=""
              />
              <span>{t("sidebar.notifications")}</span>
            </div>
            <div
              onClick={() => {
                setpanel("5");
                toggleSidebar();
              }}
              className={panel === "5" ? "itemSelect" : "item"}
            >
              <img src={panel === "5" ? mensajeActive : mensaje} alt="" />
              <span>{t("sidebar.messages")}</span>
            </div>
            <div
              onClick={() => {
                setpanel("6");
                toggleSidebar();
              }}
              className={panel === "6" ? "itemSelect" : "item"}
            >
              <img src={panel === "6" ? tcActive : tc} alt="" />
              <span>{"T&C"}</span>
            </div>
            <div onClick={handleChangeLanguage} className="item">
              <img src={language ? flagEnglish : flagSpain} alt="" />
              <span>{language ? "English" : "Español"} </span>
            </div>
            <div onClick={() => submit()} className="item">
              <img src={exit} alt="" />
              <span>{t("sidebar.logout")}</span>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default SideBar;

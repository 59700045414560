import './styles.scss';
const CustomModal=({open,component})=> {
    return(
        <div className={open ? `modal` : `hidden`}>
            <div className="content">
            {component}
            </div>
        </div>
    )
}

export default CustomModal;
import { Routes, Route } from "react-router-dom";

import SignUp from "../pages/SignUp/SignUp";
import SignIn from "../pages/SignIn/SignIn";
import PasswordRecovery from "../pages/PasswordRecovery/PasswordRecovery";
import Home from "../pages/Home";
import Article from "../pages/Home/Article";
import Accounts from "../pages/Home/Accounts";
import Firmar from "../components/Firma";
import ContractUpload from "../components/Firma/ContractUpload";
import Pagination from "../pages/Home/Pagination";
import Info from "../pages/Contratos/Info";
import FacturasContract from "../pages/Contratos/FacturasContract";
import SolicitudCertificado from "../pages/Contratos/SolicitudCertificado";
import FileCobro from "../pages/Contratos/FileCobro";
import Retiros from "../pages/Retiros";
import Utilidades from "../components/Utilidades";
import FormRetiro from "../pages/Retiros/FormRetiro";
import CambiarPassword from "../components/CambiarPassword";
export default function routes() {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/SignUp" element={<SignUp />} />
      <Route path="/Home" element={<Home />} />
      <Route path="/load-balance" element={<Accounts />} />
      <Route path="/article" element={<Article />} />
      <Route path="/Recuperar" element={<PasswordRecovery />} />
      <Route path="/Invertir" element={<Firmar />} />
      <Route path="/uploadSign" element={<ContractUpload />} />
      <Route path="/Pagination" element={<Pagination />} />
      <Route path="/Retiros" element={<Retiros />} />
      <Route path="/Utilidades" element={<Utilidades />} />
      <Route path="/Retiros/Solicitud" element={<FormRetiro />} />
      <Route path="/Portafolio/Detail" element={<Info />} />
      <Route path="/Contrato/Facturas" element={<FacturasContract />} />
      <Route path="/SolicitudCertificado" element={<SolicitudCertificado />} />
      <Route path="/Contrato/SolicitudCobro" element={<FileCobro />} />
      <Route path="/CambiarPassword" element={<CambiarPassword />} />
    </Routes>
  );
}

import "./SignUp.scss";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Modal from "../../components/Modal";
import CryptoJS from "crypto-js";
import GeneralContext from "../../context/GeneralContext";

import arrowTopIcon from "../../assets/img/svg/arrow-top-white.svg"
import arrowDownIcon from "../../assets/img/svg/arrow-down-white.svg"

import {
  ENCRIPTION_KEY,
  ENCRIPTION_KEY_2,
  URL_API,
  URL_CABRISK,
} from "../../config/config";
import { encriptarDatos } from "../../utils/Utils";
import TermsAndConditionContent from "../../components/Terms";

const API_URL = `${URL_API}/api`;

const TermsAndCondition = ({ form, email, password, cambiarPaso }) => {
  const [t, _] = useTranslation("global");
  //Context
  const GC = useContext(GeneralContext);
  const { user, modificarUser } = GC;

  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [terminos, setTerminos] = useState([]);
  const [terminosYCondiciones, setTerminosYCondiciones] = useState({
    terminos: false,
    tratamiento: false,
  });

  const [boton, setBoton] = useState("boton-continuar-disabled"); // State para claseName de boton "Continuar"
  const [backBoton, setBackBoton] = useState("boton-continuar-disabled"); // State para claseName de boton "Volver"
  const comprobarFormulario = React.useCallback(() => {
    return terminosYCondiciones.terminos && terminosYCondiciones.tratamiento;
  }, [terminosYCondiciones]);

  /**Arrow para dinamismo de t&c */
  const [expandedItems, setExpandedItems] = useState([]);

  const handleArrowClick = (index) => {
    const updatedExpandedItems = [...expandedItems];
    updatedExpandedItems[index] = !updatedExpandedItems[index];
    setExpandedItems(updatedExpandedItems);
  };

  const redirectToCabRisk = () => {
    ///// ENCRIPT DATA
    const encrytData1 = encriptarDatos({
      fullName: `${form.firstName} ${form.lastName}`,
      email,
      password,
    });

    const encryptData2 = CryptoJS.AES.encrypt(
      encrytData1,
      ENCRIPTION_KEY_2
    ).toString();

    const encryptBase64 = btoa(encryptData2);

    /// When extracting the parameters from the browser url, special characters like + and / are not interpreted for that reason they are replaced
    const replacesCharacter = encryptBase64
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");

    //// OPEN BROWSER
    window.open(`${URL_CABRISK}Registro?token=${replacesCharacter}`);
    modificarUser(null);
    navigate("/");
  };

  const submit = async () => {
    if (
      terminosYCondiciones.terminos &&
      terminosYCondiciones.tratamiento &&
      user
    ) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
        const response = await axios.put(
          `${API_URL}/authentication/accept-terms-and-conditions`
        );
        if (response.status >= 200 && response.status <= 299) {
          setOpen(true);
        }
        // navigate("/Home", { state: "firtstime" });
        // else alert("Hubo un Error");
      } catch (error) {
        alert(`Ah ocurrido un error ${error.response.data.msg}`);
      }
    }
  };

  const termsAndConditions = React.useCallback(async () => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;
      const response = await axios.get(
        `${API_URL}/conf_data/terms_and_conditions`
      );
      if (response.status >= 200 && response.status <= 299) {
        setTerminos(response.data.terms_and_conditions);
      } else {
        alert("Hubo un error");
      }
    } catch (error) {
      return console.log("Catch " + error);
    }
  }, [user]);

  useEffect(() => {
    if (terminos?.length === 0) termsAndConditions();
    if (comprobarFormulario()) setBoton("boton-continuar-enabled");
    else setBoton("boton-continuar-disabled");
  }, [terminos, termsAndConditions, comprobarFormulario, setBoton]);

  return (
    <div>
      <div className="formulario-registro">
        <div className="terms container-terms">
          <TermsAndConditionContent />
          <div className="aceptar-terms">
            <div className="checks">
              <div className="linea-check">
                <input
                  type="checkbox"
                  className="check"
                  onChange={(e) => {
                    setTerminosYCondiciones({
                      ...terminosYCondiciones,
                      terminos: e.target.checked,
                    });
                  }}
                />
                <span className="check-mark"></span>
                <p className="texto">{t("terms_and_conditions.i_have_read")}</p>
              </div>
              <div className="linea-check">
                <input
                  type="checkbox"
                  className="check"
                  onChange={(e) =>
                    setTerminosYCondiciones({
                      ...terminosYCondiciones,
                      tratamiento: e.target.checked,
                    })
                  }
                />
                <span className="check-mark"></span>

                <p className="texto">{t("terms_and_conditions.i_authorize")}</p>
              </div>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              {/* <button className={backBoton} disabled onClick={() => cambiarPaso(3)}>
                {t("general.back")}
              </button> */}
              <div></div>
              <button className={boton} onClick={() => submit()}>
                {t("general.next")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* SUCESS MODAL REGISTER */}
      <Modal
        type="2"
        btnlabel={t("signup.btn_kyc")}
        onConfirm={() => {
          redirectToCabRisk();
        }}
        onOpen={setOpen}
        open={open}
        title={t("signup.title_modal_kyc")}
        text={t("signup.description_modal_kyc")}
      />
    </div>
  );
};

export const Term = ({ title, text }) => {
  const [t, _] = useTranslation("global");
  const [arrow, setarrow] = useState(false);
  return (
    <div>
      <div className="term" onClick={() => setarrow(!arrow)}>
        <p style={{textAlign: 'justify'}}>{title}</p>
        <img src={arrow ? arrowTopIcon : arrowDownIcon} alt="" />
      </div>
      {arrow &&
        text?.split("\n").map((line, index) => <p className="justify-text" key={index}>{line}</p>)}
    </div>
  );
};

export default TermsAndCondition;

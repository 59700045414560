import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { uploadContract, validateSignContract } from "../services";
import GeneralContext from "../../../context/GeneralContext";

export const useContractUpload = () => {
  const navigate = useNavigate();
  const { user } = useContext(GeneralContext);
  const [isLoading, setIsLoading] = useState(false);

  const validateSign = async (documentId) => {
    try {
      setIsLoading(true);
      const response = await validateSignContract(documentId, user.token);
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      return null;
    }
  };

  return { validateSign, isLoading };
};
